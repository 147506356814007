import { render, staticRenderFns } from "./InfoSelfEmployed.vue?vue&type=template&id=854605da&"
import script from "./InfoSelfEmployed.vue?vue&type=script&lang=js&"
export * from "./InfoSelfEmployed.vue?vue&type=script&lang=js&"
import style0 from "./InfoSelfEmployed.vue?vue&type=style&index=0&lang=scss&module=true&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

/* custom blocks */
import block0 from "@/locales/info-self-employed.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fhome%2Frunner%2Fwork%2Facces-impot%2Facces-impot%2Fpackages%2Facces-impot-front%2Fcomponents%2FAccount%2FReports%2FReport%2FReportRelevantQuestions%2FInfoSelfEmployed.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports