// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".heading_1csE7{line-height:1.1;color:#242b59;font-weight:700}.margins_1Q5NR{margin:.35em 0 .5em}.h1_MuAA9,.h2_3WO06{font-size:32px;font-family:\"NeulisAlt\",\"Urbanist\",\"helvetica now\",helvetica,system-ui,-apple-system,Segoe UI,Roboto,Ubuntu,Cantarell,Noto Sans,sans-serif,segoe ui,roboto,ubuntu,cantarell,noto sans}@media only screen and (min-width:600px){.h1_MuAA9,.h2_3WO06{font-size:40px}}@media only screen and (min-width:1150px){.h1_MuAA9,.h2_3WO06{font-size:45px}}.h3_2ET7d{font-size:20px;letter-spacing:.1em;line-height:1.4;text-transform:uppercase}@media only screen and (min-width:600px){.h3_2ET7d{font-size:21px}}@media only screen and (min-width:1150px){.h3_2ET7d{font-size:22px}}.h4_2CSdw{font-size:15px;font-weight:700;letter-spacing:.1rem;text-transform:uppercase}@media only screen and (min-width:600px){.h4_2CSdw{font-size:17px}}@media only screen and (min-width:1150px){.h4_2CSdw{font-size:18px}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"heading": "heading_1csE7",
	"margins": "margins_1Q5NR",
	"h1": "h1_MuAA9",
	"h2": "h2_3WO06",
	"h3": "h3_2ET7d",
	"h4": "h4_2CSdw"
};
module.exports = ___CSS_LOADER_EXPORT___;
