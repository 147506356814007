import { MEDIA_BREAKPOINTS } from '@/store/viewport/viewport'

let scrollbarWidth = null

function getCurrentScrollbarWidth() {
  return window.innerWidth - document.documentElement.clientWidth
}

function getVisibleScrollbarWidth() {
  if (!process.browser) return 0

  if (scrollbarWidth === null) {
    document.body.style.height = `${window.innerHeight + 1}px`
    scrollbarWidth = getCurrentScrollbarWidth()
    document.body.style.height = ''
  }
  return scrollbarWidth
}

export default {
  namespaced: true,
  state: () => ({
    country: 'ca',
    isVisible: false,
    isFooterVisible: true,
    pageViewCount: 0,
    pageBeforeEnterCount: 0,
    isAccountSection: false,
    scrollbarWidth: 0,
    siteContainerMaxWidth: 'initial',
  }),

  mutations: {
    setIsVisible(state, isVisible) {
      state.isVisible = isVisible
    },

    setIsFooterVisible(state, isVisible) {
      state.isFooterVisible = isVisible
    },

    resetPageViewCount(state) {
      state.pageViewCount = 0
    },

    incrementPageViewCount(state) {
      if (process.browser) state.pageViewCount++
    },

    incrementPageBeforeEnterCount(state) {
      if (process.browser) state.pageBeforeEnterCount++
    },

    setIsAccountSection(state, payload) {
      state.isAccountSection = payload
    },

    setScrollbarWidth(state) {
      state.scrollbarWidth = getVisibleScrollbarWidth()
    },

    setSiteContainerMaxWidth(state) {
      state.siteContainerMaxWidth =
        process.browser && window.innerWidth >= MEDIA_BREAKPOINTS.width.large
          ? `${window.innerWidth - state.scrollbarWidth}px`
          : 'initial'
    },
  },

  actions: {
    show({ commit }) {
      commit('setIsVisible', true)
    },

    hide({ commit }) {
      commit('setIsVisible', false)
    },

    updatePageProperties({ dispatch }) {
      dispatch('updateSiteContainerMaxWidth')
    },

    updateSiteContainerMaxWidth({ commit }) {
      commit('setScrollbarWidth')
      commit('setSiteContainerMaxWidth')
    },
  },

  getters: {
    language: (_state, _getters, rootState) => rootState.i18n.locale,
    country: state => state.country,
    locale: (state, getters) => `${getters.language}-${state.country}`,
    dateLocale: (state, getters) => `${getters.language}-${state.country.toUpperCase()}`,
    isAccountSection: state => state.isAccountSection,
  },
}
