const { ADMIN_ROLES } = require('acces-impot-utils/lib/user')

module.exports = {
  ADMIN_ROLES,
  confirmationTokenUrlParam: 'confirmationToken',
  resetPasswordTokenUrlParam: 'resetPasswordToken',

  // Need to validate before storing in database because of the encoding on User.beforeCreate
  getInvalidPasswordError(password) {
    return [/[a-z]/, /[A-Z]/, /[0-9]/].some(regex => !regex.test(password))
      ? 'errors.passwordInvalid'
      : null
  },
}
