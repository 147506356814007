var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._g({class:[
    'question-mark-cta',
    {
      'question-mark-cta--inline': _vm.isInline,
      'question-mark-cta--floating': !_vm.isInline,
      'question-mark-cta--fake-padding': _vm.hasFakePadding,
    } ]},_vm.$listeners),[_c('div',{class:[
      'question-mark-cta__inner',
      {
        'question-mark-cta__inner--inline': _vm.isInline,
        'question-mark-cta__inner--no-bg': _vm.hasNoBackground,
      } ]},[_c('IconStepQuestion',{staticClass:"question-mark-cta__icon"})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }