class Province {
  constructor({ code }) {
    if (!/^[a-z]{2}$/.test(code)) {
      throw new Error('[Province] - "code" must be 2 lowercase letters.')
    }
    this.code = code
  }
}

module.exports = Province
