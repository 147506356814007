const Country = require('../models/country')
const Province = require('../models/province')
const PostalCode = require('../models/postal-code')
const Phone = require('../models/phone')

const PROVINCES = ['ab', 'bc', 'mb', 'nb', 'nl', 'ns', 'nt', 'nu', 'on', 'pe', 'qc', 'sk', 'yt']

const country = new Country({
  code: 'ca',
  currency: 'cad',
  provinces: PROVINCES.map(province => new Province({ code: province })),
  postalCode: new PostalCode({
    isInvalid: value => !/^\s{0,3}[a-z]\d[a-z]\s{0,3}\d[a-z]\d\s{0,3}$/i.test(value),
  }),
  phone: new Phone({
    isInvalid(value) {
      const digitCount = (value.match(/\d/g) || []).length
      return digitCount < 10 || digitCount > 11 // country code (+1) can be included or not
    },
  }),
})

module.exports = country
