<template>
  <div
    :class="[
      $style.button,
      {
        [$style.iconBefore]: !!$slots.iconBefore,
        [$style.iconAfter]: !!$slots.iconAfter,
      },
    ]"
    v-on="$listeners"
  >
    <slot v-if="$slots.iconBefore" name="iconBefore" />
    <span :class="$style.text"><slot /></span>
    <slot v-if="$slots.iconAfter" name="iconAfter" />
  </div>
</template>

<script>
export default {
  name: 'IconButton',
}
</script>

<style lang="scss" module>
.button {
  display: inline-block;
  white-space: nowrap;
  border: 1px solid $color-light-grey-2;
  border-radius: $border-radius;
  padding: 10px 16px 10px 16px;
  cursor: pointer;

  &:hover {
    border-color: $color-grey-5;
  }
}

.iconBefore {
  padding-right: 20px;
}

.iconAfter {
  padding-left: 20px;
}

.text {
  display: inline-block;

  .iconBefore & {
    padding-left: 10px;
  }

  .iconAfter & {
    padding-right: 10px;
  }
}
</style>
