import modelSettings from 'acces-impot-settings-report'
import { PERSON_TYPES } from 'acces-impot-utils/lib/report'
import { personalizeTranslation, getTranslationKeyForAdmin } from '@/helpers/i18n'
import Input from '@/components/Form/Input.vue'
import OverlayInfoQuestionCta from '@/components/Overlay/OverlayInfoQuestion/OverlayInfoQuestionCta.vue'
import TypeAndAmountFields from './TypeAndAmountFields.vue'

export const AMOUNT_FORM_INDEX = 0
export const DOCUMENT_FORM_INDEX = 1
export const DOCUMENT_SUMMARY_INDEX = 2

export const SELECT_OPTION_KEYS = {
  familyMembers: 'familyMembers',
  supportTypes: 'supportTypes',
}

export function getDefaultFormData(personType) {
  return {
    personType,
    telecommutingNumberOfDays: '',
    rrspReimbursementAmount: '',
    medicalFeesAmount: '',
    donationsAmount: '',
    movingExpensesAmount: '',
    incomeOutsideCanadaAmount: '',
  }
}
let latestAmountFields

function getInfoCtaSettings(settings, personType, personGender) {
  const infoCta = {
    component: OverlayInfoQuestionCta,
    props: {
      topBarKey: settings.label,
      personType,
      personGender,
    },
  }
  const key = 'reportFieldDetails.documents'
  if (settings.details) infoCta.props.moreDetailsKey = `${key}.${settings.details}.details`
  if (settings.where) infoCta.props.whereToFindKey = `${key}.${settings.where}.where`
  if (settings.options) infoCta.props.translationOptions = settings.options

  return infoCta
}

export function getAmountFields({ reportYear: currentReportYear, isAdmin } = {}) {
  const { personType, personGender, familyMembersCount } = this
  if (!this.$t) this.$t = () => {}
  if (!this.$tc) this.$tc = () => {}
  const twoYearBefore = currentReportYear ? Number(currentReportYear) - 2 : 2017
  const textPersonType =
    isAdmin && personType === PERSON_TYPES.self ? PERSON_TYPES.partner : personType

  const getInfoCta = settings => getInfoCtaSettings(settings, personType, personGender)

  const translate = (key, options, fn = '$t') => {
    return this[fn](isAdmin ? getTranslationKeyForAdmin.call(this, key) : key, options)
  }

  const personalizeText = (key, options) => {
    return personalizeTranslation.call(this, {
      key: isAdmin ? getTranslationKeyForAdmin.call(this, key) : key,
      personType: textPersonType,
      personGender,
      options,
    })
  }

  latestAmountFields = {
    incomeOutsideCanadaAmount: {
      label: personalizeText('incomeOutsideCanadaAmount.heading', { year: currentReportYear }),
      if: {
        personalDetailsKey: 'nonCanadianIncomeOutsideCanada',
        isVisible: value => Number(value) === 1,
      },
      components: [
        {
          is: Input,
          props: {
            id: 'incomeOutsideCanadaAmount',
            label: translate('incomeOutsideCanadaAmount.label'),
            formatter: formatAmount,
          },
          listeners: { keypress: onAmountFieldKeyPress },
          infoCta: {
            component: OverlayInfoQuestionCta,
            props: {
              topBarKey: 'incomeOutsideCanadaAmount.heading',
              moreDetailsKey: 'incomeOutsideCanadaAmount.infoCta.details',
              whereToFindKey: 'incomeOutsideCanadaAmount.infoCta.where',
              translationOptions: { year: currentReportYear },
              translationInstance: this,
            },
          },
        },
      ],
    },
    telecommutingNumberOfDays: {
      label: translate('account.report.specifyAmounts.telecommuting', {
        year: currentReportYear,
        days: Number(currentReportYear) === 2020 ? '200' : '250',
      }),
      if: {
        questionKey: 'telecommuting',
        isVisible: value => Number(value) === 1,
      },
      components: [
        {
          is: Input,
          props: {
            id: 'telecommutingNumberOfDays',
            label: translate('account.report.specifyAmounts.numberOfDays'),
            formatter: formatAmount,
          },
          listeners: { keypress: onAmountFieldKeyPress },
        },
      ],
    },
    rrspReimbursementAmount: {
      label: translate('account.report.specifyAmounts.withdrawUnderHbpLlp', {
        year: currentReportYear,
      }),
      if: {
        questionKey: 'rrspWithdrawUnderHbpLlp',
        isVisible: value => value !== modelSettings.withdrawUnderHbpLlp.no,
      },
      components: [
        {
          is: Input,
          props: {
            id: 'rrspReimbursementAmount',
            label: translate('account.report.specifyAmounts.reimbursementAmount'),
            formatter: formatAmount,
          },
          listeners: { keypress: onAmountFieldKeyPress },
          infoCta: getInfoCta({
            label: 'account.report.specifyAmounts.withdrawUnderHbpLlp',
            details: 'rrspReimbursementAmount',
            where: 'rrspReimbursementAmount',
            options: { year: currentReportYear, twoYearBefore },
          }),
        },
      ],
    },
    medicalFeesAmount: {
      label: `${translate(
        'account.report.specifyAmounts.medicalFees',
        familyMembersCount,
        '$tc'
      )} ${translate('account.report.specifyAmounts.noStatementNeededMedicalFees')}`,
      if: {
        questionKey: 'medicalFees',
        isVisible: value => Number(value) === 1,
      },
      components: [
        {
          is: TypeAndAmountFields,
          selectOptionKey: SELECT_OPTION_KEYS.familyMembers,
          props: {
            id: 'medicalFeesAmount',
            typeLabel: translate('account.report.specifyAmounts.familyMember'),
            amountLabel: translate('account.report.specifyAmounts.totalFeesPaid'),
            isEditable: !isAdmin,
          },
          infoCta: getInfoCta({
            label: translate(
              'account.report.specifyAmounts.medicalFees',
              familyMembersCount,
              '$tc'
            ),
            details: 'medicalFeesAmount',
            where: 'medicalFeesAmount',
          }),
        },
      ],
    },
    donationsAmount: {
      label: `${translate(
        'account.report.specifyAmounts.donations',
        familyMembersCount,
        '$tc'
      )} ${translate('account.report.specifyAmounts.noStatementNeeded')}`,
      if: {
        questionKey: 'donations',
        isVisible: value => Number(value) === 1,
      },
      components: [
        {
          is: TypeAndAmountFields,
          selectOptionKey: SELECT_OPTION_KEYS.familyMembers,
          props: {
            id: 'donationsAmount',
            typeLabel: translate('account.report.specifyAmounts.familyMember'),
            amountLabel: translate('account.report.specifyAmounts.totalDonations'),
            isEditable: !isAdmin,
          },
          infoCta: getInfoCta({
            label: translate('account.report.specifyAmounts.donations', familyMembersCount, '$tc'),
            details: 'donationsAmount',
            where: 'donationsAmount',
          }),
        },
      ],
    },
    movingExpensesAmount: {
      label: translate('account.report.specifyAmounts.movingExpenses'),
      if: {
        questionKey: 'taxCreditsAskForOne',
        isVisible: value => value?.split(',').includes(modelSettings.taxCredits.movingExpenses),
      },
      components: [
        {
          is: Input,
          props: {
            id: 'movingExpensesAmount',
            label: translate('account.report.specifyAmounts.totalMovingExpenses'),
            formatter: formatAmount,
          },
          listeners: { keypress: onAmountFieldKeyPress },
          infoCta: getInfoCta({
            label: 'account.report.specifyAmounts.movingExpenses',
            where: 'movingExpensesAmount',
          }),
        },
      ],
    },
  }
  return latestAmountFields
}

export function getDocumentTypeAndIdFromKey(documentKey) {
  const idMatch = documentKey.match(/__(\d+)$/)
  if (idMatch) {
    return {
      documentType: documentKey.substring(0, idMatch.index),
      documentTypeId: Number(idMatch[1]),
    }
  } else {
    return { documentType: '', documentTypeId: -1 }
  }
}

export function formatAmount(modelValue) {
  return modelValue ? modelValue.replace(/[^\d\s,.$]+/g, '') : modelValue
}

export function onAmountFieldKeyPress(event) {
  const key = event.keyCode || event.which
  const isNumber = key >= 48 && key <= 57
  const isBackspace = key === 8
  const isTab = key === 9
  const isEnter = key === 13
  const isSpace = key === 32
  const isDot = key === 46
  const isComma = key === 44
  const isDollar = key === 36

  if (isNumber || isBackspace || isTab || isEnter || isSpace || isDot || isComma || isDollar) {
    return true
  } else {
    event.preventDefault()
    return false
  }
}

export function getFirstIncompleteDocumentsFormIndex(reportState, personKey) {
  const amountFields = latestAmountFields || getAmountFields.call({})
  const personalDetails = reportState.reportData.personalDetails[personKey] || {}
  const relevantQuestions = reportState.reportData.relevantQuestions[personKey] || {}
  const visibleAmountFieldIds = []

  for (const key in amountFields) {
    const fieldSettings = amountFields[key]
    const questionValue = fieldSettings.if.personalDetailsKey
      ? personalDetails[fieldSettings.if.personalDetailsKey]
      : relevantQuestions[fieldSettings.if.questionKey]

    fieldSettings.components.forEach(component => {
      const fieldId = component.props.id
      if (fieldSettings.if.isVisible(questionValue)) visibleAmountFieldIds.push(fieldId)
    })
  }
  const documentData = reportState.reportData.documents[personKey] || {}
  if (visibleAmountFieldIds.some(fieldId => !documentData[fieldId])) return AMOUNT_FORM_INDEX

  if (reportState.hasSubmittedWithMissingDocs[personKey]) return -1

  const documentTypesToUpload = reportState.documentTypesToUpload[personKey]

  // If no document has to be provided, we still want to show the document form in case the person
  // wants to provide additional documents. If the form is submitted without providing documents,
  // documentTypesToUpload will be equal to an empty object.
  if (typeof documentTypesToUpload === 'undefined') return DOCUMENT_FORM_INDEX

  const remainingMandatoryDocuments = new Set()
  const providedDocuments = reportState.providedDocuments[personKey] || {}

  Object.values(documentTypesToUpload || {}).forEach(docToUpload => {
    docToUpload.keys.forEach(docKey => {
      if (!docToUpload.isOptional) remainingMandatoryDocuments.add(docKey)
    })
  })
  for (const documentKey in providedDocuments) {
    if (remainingMandatoryDocuments.size === 0) break
    const { documentType } = getDocumentTypeAndIdFromKey(documentKey)
    remainingMandatoryDocuments.delete(documentType)
  }
  return remainingMandatoryDocuments.size > 0 ? DOCUMENT_FORM_INDEX : -1
}
