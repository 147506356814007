import { getApiClient, FETCH_POLICY } from '@/api/client'
import { getUtcTime } from '@/helpers/time'
import { toRetry } from '@/helpers/request'
import REPORT_POST_DOCUMENTS_GQL from '@/api/queries/report-post-documents.gql'
import DOCUMENT_TO_SIGN_GQL from '@/api/queries/document-to-sign.gql'
import SEND_APPROVAL_QUESTION_GQL from '@/api/mutations/send-approval-question.gql'

const cacheExpirations = {
  postDocuments: {},
}

const isFetching = {
  postDocuments: {},
}

function isNetworkOnly(cacheKey) {
  const expirationCache = cacheExpirations.postDocuments[cacheKey]
  return expirationCache && expirationCache - getUtcTime() <= 0
}

/** @type import('./types').ReportService */
const ReportService = {
  async fetchPostDocuments({ client = getApiClient(), year, locale, isForcing = false }) {
    const cacheKey = [year, locale].join('__')
    if (isFetching.postDocuments[cacheKey]) await isFetching.postDocuments[cacheKey]

    const fetchPolicy =
      isForcing || isNetworkOnly(cacheKey) ? FETCH_POLICY.networkOnly : FETCH_POLICY.cacheFirst

    isFetching.postDocuments[cacheKey] = toRetry(() =>
      client.query({
        query: REPORT_POST_DOCUMENTS_GQL,
        variables: { year, locale },
        fetchPolicy,
      })
    )
    const [, result] = await isFetching.postDocuments[cacheKey]
    const postDocuments = result?.data?.me?.user?.reports?.[0]?.formattedReportPostDocuments || []
    if (postDocuments.length) {
      const expiration = new Date(postDocuments[0].urlExpiresAt).getTime()
      cacheExpirations.postDocuments[cacheKey] = expiration
    }
    return postDocuments
  },

  async getDocumentToSign({ client = getApiClient(), year, locale, postDocumentId }) {
    const input = { where: { year, postDocumentId }, data: { locale } }
    const [, result] = await toRetry(() =>
      client.query({
        query: DOCUMENT_TO_SIGN_GQL,
        variables: { input },
      })
    )
    const documentToSign = result?.data?.agreementUrl
    // TODO: handle error -> show flash message
    return documentToSign
  },

  async sendApprovalQuestion({ client = getApiClient(), message, locale, postDocumentId }) {
    const input = { where: { postDocumentId }, data: { locale, message } }
    const [, result] = await toRetry(() =>
      client.mutate({
        mutation: SEND_APPROVAL_QUESTION_GQL,
        variables: { input },
      })
    )
    // TODO: handle error -> show flash message
    return !!result?.data?.sendApprovalQuestion?.success
  },
}

export default ReportService
