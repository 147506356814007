<template>
  <FieldWrapper class="input" :class="{ 'input--error': hasError }">
    <!-- eslint-disable vue/multiline-html-element-content-newline -->
    <!-- prettier-ignore -->
    <component
      :is="fieldTag"
      :id="id"
      ref="field"
      class="input__field input__field--text"
      :class="{
        'input__field--textarea': isTextarea,
        'input__field--no-label': !label,
        'input__field--filled': model,
        'input__field--fake': isComputedFakeInput,
        'input__field--disabled': isComputedDisabled,
        'input__field--not-editable': !isEditable,
        'input__field--error': hasError || hasRedBorder,
        'input__field--dark': hasDarkColor,
      }"
      v-bind="inputComponentProps"
      v-on="{ ...eventHandlers, ...$listeners }"
      @input="updateModel"
      @animationstart="onAnimationStart"
    >{{ isNoInputTag ? model : '' }}</component>
    <!-- eslint-enable vue/multiline-html-element-content-newline -->
    <label
      v-if="label"
      class="input__label"
      :class="{
        'input__label--is-placeholder': hasPlaceholder,
        'input__label--textarea': isTextarea,
        'input__label--disabled': isComputedDisabled,
        'input__label--not-editable': !isEditable,
        'input__label--fake': isComputedFakeInput,
        'input__label--dark': hasDarkColor,
      }"
      :for="id"
      >{{ label }}</label
    >
    <slot />
    <FieldErrorMessage :errors="visibleErrors" />
  </FieldWrapper>
</template>

<script>
import FieldWrapper from './FieldWrapper.vue'
import FieldErrorMessage from './FieldErrorMessage.vue'
import { inputProps, getData, computed, inputWatch, methods } from './field-settings'

export const INPUT_FIELD_CLASS = 'input__field'

export default {
  name: 'Input',
  components: {
    FieldWrapper,
    FieldErrorMessage,
  },

  props: inputProps,

  data() {
    return {
      ...getData(),
    }
  },

  computed: {
    ...computed,

    fieldTag() {
      return this.isComputedFakeInput ? 'div' : this.isTextarea ? 'textarea' : 'input'
    },

    isNoInputTag() {
      return this.isComputedFakeInput || this.isTextarea
    },

    inputComponentProps() {
      if (this.isNoInputTag) return {}

      return {
        value: this.model,
        placeholder: this.placeholder,
        autocomplete: this.autocomplete,
        autocorrect: this.autocorrect,
        autocapitalize: this.autocapitalize,
        type: this.type || 'text',
        disabled: this.isComputedDisabled,
      }
    },
  },

  watch: {
    ...inputWatch,
  },

  methods: {
    ...methods,

    getInputElement() {
      return this.$refs.field
    },
  },
}
</script>

<style scoped lang="scss">
@import './field-settings';

@include autofill-keyframes;

.input {
  &__label {
    @include label-style;
    cursor: text;

    &--textarea {
      white-space: normal;
    }

    .input__field--fake-focused ~ & {
      @include no-placeholder-style;
    }

    .input__field--filled:not(.input__field--fake-focused) ~ &--fake {
      @include no-placeholder-style;
    }

    .input__field--filled:not(.input__field--fake-focused) ~ &--not-editable {
      @include not-editable-no-placeholder-style;
    }
  }

  &__field {
    @include field-style;
    min-height: $input-height-with-border;
    cursor: text;

    &--textarea {
      min-width: 100%;
      max-width: 100%;
      min-height: $textarea-min-height;
      height: $textarea-min-height * 3;
      max-height: $textarea-min-height * 6;
    }

    &--fake {
      height: auto;
      max-height: none;
    }
  }
}
</style>
