import { onCookieConsent } from 'acces-impot-utils/lib/cookie/consent'
import { getDocumentCookie } from 'acces-impot-utils/lib/cookie'
import { waitFor } from '@/helpers/time'
import {
  OVERLAY_COOKIE_KEY,
  OVERLAY_REMINDER_COOKIE_VALUE,
} from '@/components/Overlay/OverlayEmailForReminder/constants'

export default context => {
  if (typeof window === 'undefined') return

  const { store } = context
  const hasClosedOverlay = getDocumentCookie(OVERLAY_COOKIE_KEY) === OVERLAY_REMINDER_COOKIE_VALUE

  if (!hasClosedOverlay) {
    onCookieConsent(() => {
      store.dispatch('overlay/preloadOverlayEmailForReminder')

      waitFor(store, state => state.page.isVisible).then(() => {
        setTimeout(() => {
          waitFor(store, state => state.account.isCurrentUserFetched).then(() => {
            if (!store.getters['account/isUserLoggedIn']) {
              store.dispatch('overlay/showOverlayEmailForReminder')
            }
          })
        }, 4000)
      })
    })
  }
}
