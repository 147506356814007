<template>
  <div v-if="isVisible" class="mobile-menu">
    <Hamburger :isActive="isMenuDisplayed" @click="onBurgerClick" />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { onLoad } from '@/helpers/events'
import Hamburger from './Hamburger.vue'

export default {
  name: 'MobileMenu',
  components: {
    Hamburger,
  },

  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      isMenuDisplayed: false,
      isShowingAccountInitially: false,
    }
  },

  computed: {
    ...mapState({
      mobileSafeDimensions: state => state.viewport.mobileSafeDimensions,
    }),
  },

  watch: {
    isMenuDisplayed() {
      if (this.isMenuDisplayed) {
        this.showOverlayMobileMenu({
          isShowingAccountInitially: this.isShowingAccountInitially,
          onClose: () => (this.isMenuDisplayed = false),
        })
        this.isShowingAccountInitially = false
      }
    },

    $route: 'hideMenuIfVisible',
    mobileSafeDimensions: 'hideMenuIfVisible',
  },

  mounted() {
    onLoad().then(this.preloadOverlayMobileMenu)
  },

  methods: {
    ...mapActions({
      preloadOverlayMobileMenu: 'overlay/preloadOverlayMobileMenu',
      showOverlayMobileMenu: 'overlay/showOverlayMobileMenu',
      hideOverlayMobileMenu: 'overlay/hideOverlayMobileMenu',
    }),

    /**
     * Public method
     */
    showAccountMenu() {
      this.isShowingAccountInitially = true
      this.isMenuDisplayed = true
    },

    hideMenuIfVisible() {
      if (this.isMenuDisplayed) {
        this.isMenuDisplayed = false
        this.hideOverlayMobileMenu()
      }
    },

    onBurgerClick() {
      this.isMenuDisplayed = !this.isMenuDisplayed
      if (!this.isMenuDisplayed) this.hideOverlayMobileMenu()
    },
  },
}
</script>

<style lang="scss" scoped>
.mobile-menu {
  display: inline-block;
  color: $color-brand-navy;
  font-size: 0;
}
</style>
