<template>
  <transition name="slide-and-appear" @after-leave="afterLeave">
    <div
      v-if="isVisible"
      :id="identifier"
      ref="container"
      class="flash__message"
      :class="[`flash__message--${type}`, { 'flash__message--visible': isVisible }]"
    >
      <i class="flash__icon" :class="`flash__icon--${type}`">
        <component
          :is="ICON_COMPONENTS[type]"
          v-if="ICON_COMPONENTS[type]"
          class="flash__svg-icon"
          :class="`flash__svg-icon--${type}`"
        />
      </i>
      <span class="flash__text" v-html="text.replace(/\n/g, '<br>')" />
      <IconClose
        v-if="!isAutoHidingInternal"
        :class="`flash__close flash__close--${type}`"
        @click="closeMessage"
      />
    </div>
  </transition>
</template>

<script>
import { MESSAGE_TYPES } from '@/store/flash/flash'
import IconClose from '@/components/_icons/IconClose.svg'

const ICON_COMPONENTS = {
  [MESSAGE_TYPES.success]: () => {
    return import(/* webpackChunkName: "icon-check" */ '@/components/_icons/IconCheck.svg')
  },
  [MESSAGE_TYPES.warn]: () => {
    return import(/* webpackChunkName: "icon-warning" */ '@/components/_icons/IconWarning.svg')
  },
}
ICON_COMPONENTS[MESSAGE_TYPES.neutral] = ICON_COMPONENTS[MESSAGE_TYPES.success]

export default {
  name: 'FlashMessage',
  components: {
    IconClose,
  },

  props: {
    id: {
      type: Number,
      required: true,
    },

    identifier: {
      type: String,
      required: true,
    },

    text: {
      type: String,
      required: true,
    },

    type: {
      type: String,
      required: true,
      validator: value => Object.values(MESSAGE_TYPES).includes(value),
    },

    isAutoHiding: {
      type: Boolean,
    },

    autoHideAfter: {
      type: Number,
      default: 5000,
    },

    removeMessage: {
      type: Function,
      required: true,
    },
  },

  data() {
    return {
      ICON_COMPONENTS,
      isVisible: false,
    }
  },

  computed: {
    isAutoHidingInternal() {
      return typeof this.isAutoHiding === 'boolean'
        ? this.isAutoHiding
        : this.type !== MESSAGE_TYPES.error
    },
  },

  mounted() {
    this.$nextTick(() => this.openMessage())
  },

  methods: {
    openMessage() {
      this.isVisible = true

      if (this.isAutoHidingInternal) {
        this.autoHideTimeout = setTimeout(() => this.closeMessage(), this.autoHideAfter)
      }
    },

    closeMessage() {
      clearTimeout(this.autoHideTimeout)
      this.isVisible = false

      if (this.isFollowedByAnotherMessage()) {
        this.$el.style.transform = 'translateY(0)'
        this.$el.style.marginTop = `${this.$refs.container.offsetHeight * -1}px`
      }
    },

    afterLeave() {
      this.removeMessage(this.id)
    },

    isFollowedByAnotherMessage() {
      // Makes sense since we use flex-direction: column-reverse on parent.
      return this.$el.matches
        ? this.$el.matches('.flash__message--visible ~ .flash__message')
        : false
    },
  },
}
</script>

<style lang="scss" scoped>
$message-padding-y: 20px;
$message-padding-x: 40px;
$message-padding-x-small: 30px;
$message-font-size: $font-size-small;
$message-line-height-px: round($message-font-size * $line-height-normal);
$icon-circle-size: 21px;
$icon-circle-margin-top: round(($message-line-height-px - $icon-circle-size) / 2) + 1;
$icon-check-height-success: 10px;
$icon-check-height-error: 14px;
$icon-warn-size: 28px;
$close-icon-size: 14px;
$close-icon-padding: 10px;

.flash {
  &__message {
    position: relative;
    display: flex;
    align-items: center;
    background: transparentize($color-grey-blueish, 0.05);
    padding: $message-padding-y 20px;
    font-size: $message-font-size;
    color: $color-light-grey-7;
    line-height: $message-line-height-px;
    cursor: default;

    @include min-screen($mq-small) {
      padding: $message-padding-y $message-padding-x-small;
    }

    @include min-screen($mq-medium) {
      padding: $message-padding-y $message-padding-x;
    }

    &--success,
    &--error {
      border-bottom: 0;
      color: $color-white;
    }

    &--success {
      background: darken($color-green, 5);
    }

    &--error {
      background: transparentize($color-red-bg, 0.05);
    }

    &--warn {
      background: $color-brand-yellow-light-bg;
      color: $color-grey-4;
    }
  }

  &__icon {
    position: relative;
    text-align: center;
    margin-right: 15px;
    border: 1px solid $color-white;
    border-radius: 50%;
    width: $icon-circle-size;
    height: $icon-circle-size;

    &:before {
      display: inline-block;
      font-size: $icon-check-height-success;
      line-height: 1;
    }

    &--success,
    &--error {
      &:before {
        color: $color-white;
      }
    }

    &--error {
      &:before {
        content: '!';
        font-size: $icon-check-height-error;
        font-style: normal;
      }
    }

    &--warn {
      width: $icon-warn-size;
      height: $icon-warn-size;
      border-width: 0;
    }
  }

  &__svg-icon {
    position: absolute;
    top: calc(50% + 1px);
    left: 50%;
    width: 12px;
    height: 9px;
    stroke: currentColor;
    stroke-width: 3;
    transform: translate(-50%, -50%);

    &--warn {
      width: $icon-warn-size;
      height: $icon-warn-size;
      stroke: none;
      stroke-width: 0;
      color: $color-brand-yellow;
    }
  }

  &__text {
    display: inline-block;
    flex: 1;
    padding-right: $close-icon-size + 25;

    @include min-screen($mq-medium) {
      padding-right: $close-icon-size + 30;
    }
  }

  &__close {
    position: absolute;
    top: 50%;
    right: 15px;
    width: $close-icon-size + $close-icon-padding * 2;
    height: $close-icon-size + $close-icon-padding * 2;
    padding: $close-icon-padding;
    stroke: $color-light-grey-3;
    line-height: $message-line-height-px;
    transform: translateY(-50%);
    cursor: pointer;

    @include min-screen($mq-small) {
      right: 25px;
    }

    @include min-screen($mq-medium) {
      right: $message-padding-x - $close-icon-padding;
    }

    &:hover {
      stroke: $color-white;
    }

    &--success,
    &--error {
      stroke: $color-white;

      &:hover {
        stroke: $color-white;
        opacity: 0.7;
      }
    }

    &--warn {
      stroke: $color-grey-4;

      &:hover {
        stroke: $color-grey-1;
      }
    }
  }
}

.slide-and-appear-enter-active {
  @include transition((transform, margin-top, opacity), 0.6s ease-in-out);
}

.slide-and-appear-leave-active {
  @include transition((transform, margin-top, opacity), 0.8s ease-in-out);
}

.slide-and-appear-enter,
.slide-and-appear-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}
</style>
