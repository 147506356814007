export function isStringEmpty(value) {
  return !value || !/\S/.test(value)
}

export function isExplicitlyFalse(value) {
  return typeof value !== 'undefined' && value === false
}

export function executeIfFunction(variable) {
  return typeof variable === 'function' ? variable() : variable
}

export function isValidDate(dateString) {
  if (!/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
    return false
  }
  const dateSplit = dateString.split('-').map(d => parseFloat(d))
  const month = dateSplit[1] - 1
  const date = new Date(dateSplit[0], month, dateSplit[2])

  return (
    date.getFullYear() == dateSplit[0] && date.getMonth() == month && date.getDate() == dateSplit[2]
  )
}
