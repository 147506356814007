import Vue from 'vue'
import cloneDeep from 'lodash.clonedeep'
import {
  formatProvidedDocuments,
  formatMissingDocumentsAfterPurchase,
  formatProvidedMissingInfos,
  getOpenMissingDocsRequestKey,
} from 'acces-impot-utils/lib/report'
import { getCurrentTaxYear } from '@/helpers/time'
import { getAllDocumentsToUploadAndReasons } from '@/components/Account/Reports/Report/ReportDocuments/document-to-upload-helpers'
import { getFirstIncompleteDocumentsFormIndex } from '@/components/Account/Reports/Report/ReportDocuments/report-documents-settings'
import { getDocumentTypeAndIdFromKey } from '@/components/Account/Reports/Report/ReportDocuments/report-documents-settings'
import {
  getInitialState,
  getInitialReportDataState,
  getComponentCountSettings,
  getComponentKey,
  getStepKey,
  getPersonKey,
  getIndexFromPersonKey,
  getTypeFromPersonKey,
  getNumberOfChildren,
  getParentNames,
  getFamilyMembersWithReport,
  getFirstIncompleteComponentIndex,
  formatReportData,
  formatPersonalDetailsFromApi,
  isTheOnlyParentOfSomeChild,
} from './report-helpers'
import {
  REPORT_STATUSES,
  STEP_KEYS,
  STEP_INDEXES,
  STEP_DEFAULT_COUNT,
  STEP_DATA,
  PERSON_TYPES,
} from './report-settings'
import {
  getReports,
  formatReportDataForApi,
  storeReportData,
  updateCacheOfPaymentCompletedAt,
} from './report-api'

export default {
  namespaced: true,
  state: () => getInitialState(),

  mutations: {
    setInitialState(state) {
      const initialState = getInitialState()
      for (const key in initialState) state[key] = initialState[key]
    },

    resetReportDataState(state) {
      const initialState = getInitialReportDataState()
      for (const key in initialState) state[key] = initialState[key]
    },

    setReports(state, payload) {
      state.reports = payload
    },

    setReportById(state, payload) {
      const index = state.reports.findIndex(r => String(r.id) === String(payload.id))
      if (index >= 0) Vue.set(state.reports, index, { ...state.reports[index], ...payload })
    },

    pushToVisitedReportYears(state, year) {
      if (!/^\d{4}$/.test(year)) return
      const numberYear = Number(year)
      if (!state.visitedReportYears.includes(numberYear)) state.visitedReportYears.push(numberYear)
    },

    setCurrentReportYear(state, year) {
      if (!/^\d{4}$/.test(year)) return
      const numberYear = Number(year)
      state.currentReportYear = numberYear
      if (!state.visitedReportYears.includes(numberYear)) state.visitedReportYears.push(numberYear)
    },

    setIsCurrentReportSet(state, payload) {
      state.isCurrentReportSet = payload
    },

    setIsReportDataUpdating(state, payload) {
      state.isReportDataUpdating = payload
    },

    setEditedComponentIndex(state, index) {
      state.editedComponentIndex = index
    },

    setComponentSettings(state, { stepKey, stepChildIndex }) {
      const countSettings = state.componentCountSettings[stepKey]
      const personKey = countSettings.personKeys[stepChildIndex]
      const personType = getTypeFromPersonKey(personKey)
      const personIndex = getIndexFromPersonKey(personKey)
      const componentKey = getComponentKey(stepKey, personKey)

      if (!state.componentSettings[componentKey]) {
        Vue.set(state.componentSettings, componentKey, {
          name: STEP_DATA[stepKey].name,
          personType,
          personKey,
          personIndex,
          componentKey,
          stepKey,
          stepChildIndex,
        })
      }
    },

    deleteComponentSettings(state, { stepKey, personKey }) {
      const componentKey = getComponentKey(stepKey, personKey)
      Vue.delete(state.componentSettings, componentKey)
    },

    setHighestComponentIndexReached(state, index) {
      state.highestComponentIndexReached = index
    },

    setReportData(state, { stepKey, personKey, data, isFormatted }) {
      if (!stepKey || !personKey || !data) return

      if (
        stepKey === STEP_KEYS.missingDocumentsAfterPurchase &&
        typeof data.providedMissingInfos === 'string'
      ) {
        const { providedMissingInfos: currentProvidedMissingInfos } =
          state.reportData[stepKey][personKey] || {}

        const fullProvidedMissingInfos = { ...currentProvidedMissingInfos }
        const newProvidedMissingInfos = formatProvidedMissingInfos(data.providedMissingInfos)
        const submittedAt = data.missingInfosDocsSubmittedAt || new Date().getTime()

        fullProvidedMissingInfos[submittedAt] = newProvidedMissingInfos
        data.providedMissingInfos = fullProvidedMissingInfos
        data.missingInfosDocsSubmittedAt = submittedAt
      }

      const reportData = {
        ...state.reportData[stepKey],
        [personKey]: {
          ...state.reportData[stepKey][personKey],
          ...(isFormatted ? data : formatReportData(stepKey, data)),
        },
      }
      Vue.set(state.reportData, stepKey, reportData)
    },

    deleteReportData(state, { stepKey, personKey }) {
      const cachedReportData = {
        ...state.cachedReportData[stepKey],
        [personKey]: { ...state.reportData[stepKey][personKey] },
      }
      Vue.set(state.cachedReportData, stepKey, cachedReportData)

      const reportData = { ...state.reportData[stepKey] }
      delete reportData[personKey]

      Vue.set(state.reportData, stepKey, reportData)
    },

    setFamilyMembers(state) {
      const details = state.reportData[STEP_KEYS.personalDetails]
      const self = details[getPersonKey(PERSON_TYPES.self)]
      const numberOfChildren = getNumberOfChildren(state.reportData)

      const counts = {
        [PERSON_TYPES.self]: STEP_DEFAULT_COUNT,
        [PERSON_TYPES.partner]: self?.hasPartner ? 1 : 0,
        [PERSON_TYPES.child]: numberOfChildren,
      }
      Object.values(PERSON_TYPES).forEach(personType => {
        Vue.set(state.familyMembers, personType, counts[personType])
      })
    },

    setFamilyMembersWithReport(state) {
      state.familyMembersWithReport = getFamilyMembersWithReport(
        state.reportData[STEP_KEYS.personalDetails]
      )
    },

    setComponentCountSettings(state) {
      const settings = getComponentCountSettings(
        state.familyMembers,
        state.familyMembersWithReport,
        state.missingDocumentsAfterPurchase
      )
      Object.keys(settings).forEach(stepKey => {
        Vue.set(state.componentCountSettings, stepKey, settings[stepKey])
      })
    },

    setParentsNames(state) {
      state.parentNames = getParentNames(state.reportData[STEP_KEYS.personalDetails] || {})
    },

    setCompletedQuestionCategories(state, { personKey, categoryId, isCompleted }) {
      const completedQuestionCategories = {
        ...state.completedQuestionCategories[personKey],
        [categoryId]: isCompleted,
      }
      Vue.set(state.completedQuestionCategories, personKey, completedQuestionCategories)
    },

    setDocumentTypesToUpload(state, { documentTypesToUpload, personKey }) {
      Vue.set(state.documentTypesToUpload, personKey, documentTypesToUpload)
    },

    setDocumentReasonsToAsk(state, { documentReasonsToAsk, personKey }) {
      Vue.set(state.documentReasonsToAsk, personKey, documentReasonsToAsk)
    },

    setProvidedDocuments(state, { personKey, providedDocuments }) {
      const { providedBeforePayment, providedAfterPayment } =
        formatProvidedDocuments(providedDocuments)

      Vue.set(state.providedDocuments, personKey, {
        ...state.providedDocuments[personKey],
        ...providedBeforePayment,
      })
      if (providedAfterPayment) {
        Vue.set(state.providedMissingDocumentsAfterPurchase, personKey, {
          ...state.providedMissingDocumentsAfterPurchase[personKey],
          ...providedAfterPayment,
        })
      }
    },

    resetProvidedDocuments(state, { personKey, providedDocuments }) {
      const { providedBeforePayment, providedAfterPayment } =
        formatProvidedDocuments(providedDocuments)

      Vue.set(state.providedDocuments, personKey, providedBeforePayment)
      Vue.set(state.providedMissingDocumentsAfterPurchase, personKey, providedAfterPayment || {})
    },

    setMissingDocumentsAfterPurchase(
      state,
      { personKey, missingDocuments, missingInfosDocsSubmittedAt }
    ) {
      const formatted = formatMissingDocumentsAfterPurchase(missingDocuments)
      const openMissingDocsRequestKey = getOpenMissingDocsRequestKey(
        missingInfosDocsSubmittedAt,
        formatted
      )

      Vue.set(state.missingDocumentsAfterPurchase, personKey, formatted)
      Vue.set(state.openMissingDocsTimestamps, personKey, openMissingDocsRequestKey)
    },

    setHasMissingDocumentsAfterPurchase(state) {
      state.hasMissingDocumentsAfterPurchase = Object.values(
        state.missingDocumentsAfterPurchase
      ).some(missingDocsRequest => {
        return Object.values(missingDocsRequest).some(missingDocs => {
          const { docs, infos } = missingDocs || {}
          return [docs, infos].some(entries => entries && Array.isArray(entries) && entries.length)
        })
      })
    },

    setHasSubmittedWithMissingDocs(state, { personKey, value }) {
      Vue.set(state.hasSubmittedWithMissingDocs, personKey, !!value)
    },

    setHasSubmittedWithMissingDocsAfterPurchase(state, { personKey, value }) {
      Vue.set(state.hasSubmittedWithMissingDocsAfterPurchase, personKey, !!value)
    },

    setFirstIncompleteDocumentsFormIndex(state, { personKey, index }) {
      if (personKey) Vue.set(state.firstIncompleteDocumentsFormIndex, personKey, index)
    },

    setPaymentCompletedAt(state, payload) {
      state.paymentCompletedAt = payload
    },

    setPaymentCompletedAtInReports(state, { year, datetime }) {
      const report = state.reports.find(report => report.year === year)
      if (report?.reportOrders?.[0]) {
        report.reportOrders[0].paymentCompletedAt = datetime
      }
    },
  },

  actions: {
    async updateReports({ commit }) {
      if (!process.browser) return

      const reports = await getReports()
      commit('setReports', reports)
    },

    async updateCurrentReportYear({ commit, dispatch }, year) {
      await dispatch('updateReports')
      commit('setCurrentReportYear', year)
      commit('setHighestComponentIndexReached', 0)
      await dispatch('updateCurrentReport')
      commit('setIsCurrentReportSet', true)
    },

    async updateCurrentReport({ state, commit, dispatch }) {
      commit('resetReportDataState')
      const currentReport = state.reports.find(report => report.year === state.currentReportYear)
      if (!currentReport) return

      const { reportPeople, reportAnswers, reportDocuments, reportOrders } =
        cloneDeep(currentReport)
      const componentCounts = await Promise.all([
        dispatch('updateCurrentPersonalDetails', reportPeople),
        dispatch('updateCurrentRelevantQuestions', reportAnswers),
        dispatch('updateCurrentReportDocuments', reportDocuments),
        dispatch('updateCurrentReportOrders', reportOrders),
      ])
      const componentIndex = componentCounts.reduce((sum, count) => sum + count)
      dispatch('updateEditedComponent', { index: componentIndex, isSubmittingChanges: true })
    },

    async updateCurrentPersonalDetails({ dispatch }, reportPeople) {
      let count = 0

      await Promise.all(
        reportPeople.map(async data => {
          const formattedData = formatPersonalDetailsFromApi(data)
          const isUpdated = await dispatch('updateCurrentReportState', {
            reportData: formattedData,
            stepKey: STEP_KEYS.personalDetails,
          })
          if (isUpdated) count++
        })
      )
      await dispatch('onChangeOfPersonalDetailsData')
      return count
    },

    async updateCurrentRelevantQuestions({ commit, dispatch }, reportAnswers) {
      let count = 0

      await Promise.all(
        reportAnswers.map(async data => {
          const definedData = {}
          for (const key in data) {
            if (data[key] !== null) definedData[key] = data[key]
          }
          const completedCategories = (data.completedQuestionCategories || '').split(',')
          completedCategories.forEach(categoryId => {
            commit('setCompletedQuestionCategories', {
              personKey: data.personKey,
              categoryId,
              isCompleted: true,
            })
          })
          const isUpdated = await dispatch('updateCurrentReportState', {
            reportData: definedData,
            stepKey: STEP_KEYS.relevantQuestions,
          })
          if (isUpdated) count++
        })
      )
      return count
    },

    async updateCurrentReportDocuments({ state, commit, dispatch }, reportDocuments) {
      let count = 0

      await Promise.all(
        reportDocuments.map(async data => {
          let providedDocuments = null
          try {
            providedDocuments = JSON.parse(data.providedDocuments)
          } catch (err) {}

          if (providedDocuments) {
            commit('setProvidedDocuments', { personKey: data.personKey, providedDocuments })
          }
          delete data.providedDocuments

          commit('setMissingDocumentsAfterPurchase', {
            personKey: data.personKey,
            missingDocuments: data.missingDocuments,
            missingInfosDocsSubmittedAt: data.missingInfosDocsSubmittedAt,
          })
          delete data.missingDocuments

          commit('setHasSubmittedWithMissingDocs', {
            personKey: data.personKey,
            value: data.hasSubmittedWithMissingDocs,
          })
          delete data.hasSubmittedWithMissingDocs

          commit('setHasSubmittedWithMissingDocsAfterPurchase', {
            personKey: data.personKey,
            value: data.hasSubmittedWithMissingDocsAfterPurchase,
          })
          delete data.hasSubmittedWithMissingDocsAfterPurchase

          const providedMissingInfos = formatProvidedMissingInfos(data.providedMissingInfos)
          let missingDocsData

          // Info for missingDocumentsAfterPurchase
          if (
            state.providedMissingDocumentsAfterPurchase ||
            Object.keys(providedMissingInfos).length
          ) {
            const { personKey, personType, missingInfosDocsSubmittedAt } = data
            missingDocsData = {
              personKey,
              personType,
              missingInfosDocsSubmittedAt,
              providedMissingInfos,
            }
          }
          delete data.providedMissingInfos
          delete data.missingInfosDocsSubmittedAt

          const updates = [
            dispatch('updateCurrentReportState', {
              reportData: data,
              stepKey: STEP_KEYS.documents,
            }),
          ]
          if (missingDocsData) {
            updates.push(
              dispatch('updateCurrentReportState', {
                reportData: missingDocsData,
                stepKey: STEP_KEYS.missingDocumentsAfterPurchase,
              })
            )
          }
          const [isUpdated, isMissingDocsUpdated] = await Promise.all(updates)
          if (isUpdated) count++
          if (isMissingDocsUpdated) count++
        })
      )
      commit('setHasMissingDocumentsAfterPurchase')

      return count
    },

    async updateCurrentReportOrders({ commit, dispatch }, reportOrders) {
      let count = 0

      await Promise.all(
        reportOrders.map(async data => {
          commit('setPaymentCompletedAt', data.paymentCompletedAt)
          delete data.paymentCompletedAt

          const isUpdated = await dispatch('updateCurrentReportState', {
            reportData: { ...data, personKey: getPersonKey(PERSON_TYPES.self) },
            stepKey: STEP_KEYS.payment,
          })
          if (isUpdated) count++
        })
      )
      return count
    },

    async updateCurrentReportState({ state, commit, dispatch }, { reportData, stepKey }) {
      const { personKey } = reportData
      delete reportData.personKey

      if (stepKey !== STEP_KEYS.personalDetails) {
        const personData = state.reportData[STEP_KEYS.personalDetails][personKey] || {}
        if (personData.personType !== PERSON_TYPES.self && !personData.hasTaxReport) return
      }
      await commit('setReportData', {
        stepKey,
        personKey,
        data: reportData,
        isFormatted: true,
      })
      await dispatch('onChangeOfSpecificReportData', { stepKey, personKey })

      return true
    },

    async updateReportData({ state, commit, dispatch }, payload) {
      commit('setIsReportDataUpdating', true)
      const { stepKey, personKey, data } = payload
      const isMissingDocsStep = stepKey === STEP_KEYS.missingDocumentsAfterPurchase
      let isLastPersonToProvideMissingInfos = false

      if (isMissingDocsStep && payload.data.isLastPersonToProvideMissingInfos) {
        isLastPersonToProvideMissingInfos = !!payload.data.isLastPersonToProvideMissingInfos
        delete payload.data.isLastPersonToProvideMissingInfos
      }

      const originalData = { ...data }
      payload.data = formatReportData(stepKey, data)
      payload.isFormatted = true
      await commit('setReportData', payload)

      const apiData = isMissingDocsStep ? originalData : payload.data
      const formattedData = formatReportDataForApi({ data: apiData, stepKey, personKey })

      const where = { year: state.currentReportYear }
      if (isLastPersonToProvideMissingInfos) where.isLastPersonToProvideMissingInfos = true

      const mutationInput = { where, data: formattedData }
      await storeReportData(stepKey, mutationInput, ({ data }) => {
        const { report, reportDocument } = data || {}
        if (report) commit('setReportById', report)

        if (reportDocument) {
          commit('setMissingDocumentsAfterPurchase', {
            personKey: reportDocument.personKey,
            missingDocuments: reportDocument.missingDocuments,
            missingInfosDocsSubmittedAt: reportDocument.missingInfosDocsSubmittedAt,
          })
        }
      })

      if (stepKey === STEP_KEYS.personalDetails) dispatch('onChangeOfPersonalDetailsData')
      dispatch('onChangeOfSpecificReportData', { stepKey, personKey })
      commit('setIsReportDataUpdating', false)
    },

    async onChangeOfPersonalDetailsData({ commit, dispatch }) {
      await Promise.all([
        commit('setFamilyMembers'),
        commit('setFamilyMembersWithReport'),
        commit('setParentsNames'),
        commit('setComponentCountSettings'),
        dispatch('cleanComponentSettings'),
        dispatch('cleanPersonalDetailsData'),
        dispatch('cleanReportData'),
      ])
    },

    onChangeOfSpecificReportData({ dispatch }, { stepKey, personKey }) {
      if (stepKey === STEP_KEYS.relevantQuestions) {
        dispatch('updateDocumentToUploadAndReason', personKey)
      }
      if (
        stepKey === STEP_KEYS.personalDetails ||
        stepKey === STEP_KEYS.relevantQuestions ||
        stepKey === STEP_KEYS.documents
      ) {
        dispatch('updateFirstIncompleteDocumentsFormIndex', personKey)
      }
    },

    updateDocumentToUploadAndReason({ state, commit }, personKey) {
      const { documentTypesToUpload, documentReasonsToAsk } = getAllDocumentsToUploadAndReasons(
        state.reportData,
        personKey
      )
      commit('setDocumentTypesToUpload', { documentTypesToUpload, personKey })
      commit('setDocumentReasonsToAsk', { documentReasonsToAsk, personKey })
    },

    updateProvidedDocuments({ commit }, payload) {
      commit('setProvidedDocuments', payload)
    },

    updateHasSubmittedWithMissingDocs({ state, commit }, payload) {
      const { isAfterPurchase } = payload
      const filteredPayload = { ...payload }
      delete filteredPayload.isAfterPurchase

      isAfterPurchase
        ? commit('setHasSubmittedWithMissingDocsAfterPurchase', payload)
        : commit('setHasSubmittedWithMissingDocs', payload)

      const { personKey } = payload
      const stateKey = isAfterPurchase
        ? 'hasSubmittedWithMissingDocsAfterPurchase'
        : 'hasSubmittedWithMissingDocs'
      const hasMissingDocs = state[stateKey][personKey]

      return { [stateKey]: hasMissingDocs }
    },

    updateFirstIncompleteDocumentsFormIndex({ state, commit }, personKey) {
      const index = getFirstIncompleteDocumentsFormIndex(state, personKey)
      commit('setFirstIncompleteDocumentsFormIndex', { personKey, index })
    },

    updateEditedComponent(context, { index, isSubmittingChanges }) {
      const { dispatch } = context
      dispatch('updateEditedComponentIndex', index)
      dispatch('updateComponentSettings')

      if (isSubmittingChanges) {
        // Check which one is the next incomplete form after updating the component settings in case
        // the user has changed a question that affects the number of forms to fill
        // (i.e. number of children, has partner or not)
        const firstIncompleteComponentIndex = getFirstIncompleteComponentIndex(context)

        if (firstIncompleteComponentIndex >= 0 && index !== firstIncompleteComponentIndex) {
          dispatch('updateEditedComponentIndex', firstIncompleteComponentIndex)
        }
      }
    },

    updateEditedComponentIndex({ state, commit }, index) {
      commit('setEditedComponentIndex', index)

      if (index > state.highestComponentIndexReached) {
        commit('setHighestComponentIndexReached', index)
      }
    },

    updateComponentSettings({ state, getters, commit }) {
      Object.values(STEP_KEYS)
        .slice(0, STEP_INDEXES[getters.highestStepKeyReached] + 1)
        .forEach(stepKey => {
          const countSettings = state.componentCountSettings[stepKey]

          for (let stepChildIndex = 0; stepChildIndex < countSettings.count; stepChildIndex++) {
            commit('setComponentSettings', { stepKey, stepChildIndex })
          }
        })
    },

    cleanComponentSettings({ state, commit }) {
      for (const componentKey in state.componentSettings) {
        const { stepKey, personKey, personType, personIndex } =
          state.componentSettings[componentKey]
        const getDetails = personKey => state.reportData[STEP_KEYS.personalDetails][personKey]
        const isInvalidStepData = () =>
          !getDetails(personKey) ||
          (personType !== PERSON_TYPES.self && !getDetails(personKey).hasTaxReport)

        if (
          personIndex >= state.familyMembers[personType] ||
          (stepKey !== STEP_KEYS.personalDetails && isInvalidStepData())
        ) {
          commit('deleteComponentSettings', { stepKey, personKey })
        }
      }
    },

    cleanPersonalDetailsData({ state, commit }) {
      const personalDetailsData = state.reportData[STEP_KEYS.personalDetails] || {}

      for (const personKey in personalDetailsData) {
        const parents = personalDetailsData[personKey].parents || ''
        const personTypes = parents.split(',')
        const updatedParents = personTypes
          .filter(personType => state.parentNames[personType])
          .join(',')

        if (parents !== updatedParents) {
          commit('setReportData', {
            stepKey: STEP_KEYS.personalDetails,
            personKey,
            data: {
              ...personalDetailsData[personKey],
              parents: updatedParents,
            },
          })
        }
      }
    },

    cleanReportData({ state, commit }) {
      for (const stepKey in state.reportData) {
        for (const personKey in state.reportData[stepKey]) {
          const person = state.reportData[stepKey][personKey]
          const personIndex = getIndexFromPersonKey(personKey)

          if (personIndex >= state.familyMembers[person.personType]) {
            commit('deleteReportData', { stepKey, personKey })
          }
        }
      }
    },

    forcePaymentComplete({ commit }, { year, datetime }) {
      commit('setPaymentCompletedAt', datetime)
      updateCacheOfPaymentCompletedAt(year, datetime)
      commit('setPaymentCompletedAtInReports', { year, datetime })
    },
  },

  getters: {
    listOfReportYears: state => {
      const years = state.reports.map(report => report.year).filter(y => y)
      years.push(...state.visitedReportYears, getCurrentTaxYear())
      return [...new Set(years.map(Number))].sort()
    },
    currentReportStatus: (state, { getReportStatus }) => {
      return getReportStatus(state.currentReportYear)
    },
    getReportStatus: state => {
      return year => {
        const report = state.reports.find(report => report.year === year)
        if (!report) return REPORT_STATUSES.notStarted
        if (report.status) return report.status

        return report.reportOrders?.some(order => order.paymentCompletedAt)
          ? REPORT_STATUSES.paymentReceived
          : report.reportPeople?.length
            ? REPORT_STATUSES.inProgress
            : REPORT_STATUSES.notStarted
      }
    },
    getReportLastUpdateTime: state => {
      return year => {
        const report = state.reports.find(report => report.year === year)
        if (!report) return

        const arrayOfUpdateTimes = []
        Object.values(report).forEach(entries => {
          if (Array.isArray(entries)) {
            const sortedUpdates = entries.map(v => new Date(v.updated_at)).sort((a, b) => b - a)
            arrayOfUpdateTimes.push(sortedUpdates[0])
          }
        })
        const latestUpdate = arrayOfUpdateTimes.sort((a, b) => b - a)[0]
        return latestUpdate ? new Date(latestUpdate) : undefined
      }
    },
    isOnlyParentOfSomeChild: (_state, getters) => {
      return personType => isTheOnlyParentOfSomeChild(personType, getters.personalDetailsData)
    },
    personalDetailsData: state => state.reportData[STEP_KEYS.personalDetails] || {},
    selfReportData: (_state, getters) =>
      getters.personalDetailsData[getPersonKey(PERSON_TYPES.self)] || {},
    currentOrder: state => state.reportData[STEP_KEYS.payment]?.[getPersonKey(PERSON_TYPES.self)],
    mainAddress: (_state, getters) => {
      return {
        address1: getters.selfReportData.address1 || '',
        address2: getters.selfReportData.address2 || '',
        city: getters.selfReportData.city || '',
        province: getters.selfReportData.province || '',
        postalCode: getters.selfReportData.postalCode || '',
      }
    },
    familyMembersWithReportCount: state => {
      return Object.values(state.familyMembersWithReport).reduce(
        (sum, personIndexes) => sum + personIndexes.length,
        0
      )
    },
    personalDetailsOfFamilyMembersWithReport: (state, getters) => {
      const infos = []
      Object.entries(state.familyMembersWithReport).forEach(([personType, personIndexes]) => {
        personIndexes.forEach(personIndex => {
          const personKey = getPersonKey(personType, personIndex)
          const info = getters.personalDetailsData[personKey]
          if (info) infos.push({ ...getters.personalDetailsData[personKey], personKey })
        })
      })
      return infos
    },
    sortedComponentSettings: state => {
      return Object.values(state.componentSettings).sort((a, b) => {
        const aStepIndex = STEP_INDEXES[a.stepKey]
        const bStepIndex = STEP_INDEXES[b.stepKey]

        if (aStepIndex === bStepIndex) {
          return a.stepChildIndex - b.stepChildIndex
        } else {
          return aStepIndex - bStepIndex
        }
      })
    },
    editedStepKey: (state, getters) => getStepKey(state, getters, state.editedComponentIndex),
    highestStepKeyReached: (state, getters) =>
      getStepKey(state, getters, state.highestComponentIndexReached),
    paymentCompletedWith: (state, getters) =>
      state.paymentCompletedAt && getters.currentOrder?.paymentMethod,

    hasProvidedAllMissingDocsInfosAfterPurchase: state => {
      const hasProvidedAll = {}

      for (const personKey in state.missingDocumentsAfterPurchase) {
        const openTimestamp = state.openMissingDocsTimestamps[personKey]

        if (
          typeof openTimestamp === 'undefined' ||
          state.hasSubmittedWithMissingDocsAfterPurchase?.[personKey]
        ) {
          hasProvidedAll[personKey] = true
          continue
        }
        const providedDocumentTypes = {}
        const providedForPersonKey = state.providedMissingDocumentsAfterPurchase[personKey] || {}

        Object.entries(providedForPersonKey).forEach(([docKey, docSettings]) => {
          if (!docSettings.wasMissing || docSettings.wasMissing < openTimestamp) return

          const { documentType } = getDocumentTypeAndIdFromKey(docKey)
          providedDocumentTypes[documentType] = providedDocumentTypes[documentType] || 0
          providedDocumentTypes[documentType]++
        })

        const askFor = {}
        const documentTypesToAskFor = new Set([])

        const { providedMissingInfos } =
          state.reportData.missingDocumentsAfterPurchase[personKey] || {}

        const providedTimestamps = Object.keys(providedMissingInfos)
          .map(key => Number(key))
          .sort()
        const currentProvidedTimestamp = providedTimestamps.find(t => t > openTimestamp)
        const currentProvidedMissingInfos = currentProvidedTimestamp
          ? providedMissingInfos[currentProvidedTimestamp]
          : undefined

        const openMissingDocsRequest =
          state.missingDocumentsAfterPurchase[personKey]?.[openTimestamp]

        openMissingDocsRequest?.docs?.forEach(([inputType]) => {
          askFor[inputType] = askFor[inputType] || 0
          askFor[inputType]++
          documentTypesToAskFor.add(inputType)
        })
        const hasProvidedAllInfos = (openMissingDocsRequest?.infos || []).every(
          ([inputId]) => currentProvidedMissingInfos?.[inputId]
        )

        hasProvidedAll[personKey] =
          hasProvidedAllInfos &&
          [...documentTypesToAskFor].every(
            docType => providedDocumentTypes[docType] >= askFor[docType]
          )
      }
      return hasProvidedAll
    },
  },
}
