// https://web.dev/customize-install/
let deferredPrompt // eslint-disable-line no-unused-vars

export function preventPwaPrompt() {
  if (process.browser && !deferredPrompt) {
    window.addEventListener('beforeinstallprompt', e => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault()
      // Stash the event so it can be triggered later.
      deferredPrompt = e
    })
  }
}
