import { track, TRACK_CATEGORIES, TRACK_CONTEXTS, TRACK_ACTIONS } from '.'
import initFacebookPixelIfNeeded from './tags/facebook-pixel'

const FB_EVENTS = {
  dialogShow: 'customerchat.dialogShow',
}

export function registerFacebookEvents() {
  if (!window.FB) return

  window.FB.Event.subscribe(FB_EVENTS.dialogShow, () => {
    track(TRACK_CATEGORIES.contact, TRACK_CONTEXTS.liveChat, { action: TRACK_ACTIONS.open })
  })
}

export function fbq(...args) {
  if (initFacebookPixelIfNeeded()) window.fbq(...args)
}
