<template>
  <div class="parent-checkboxes">
    <Checkbox
      v-for="(label, personType) in labels"
      :id="getParentId(personType)"
      :key="getParentId(personType)"
      :model="models[personType]"
      :label="label"
      :hasRedBorder="hasError"
      :isDisabled="isDisabled"
      :isFakeInput="isFakeInput"
      :isEditable="isEditable"
      @update:model="updateModel($event, personType)"
    />
    <FieldErrorMessage class="parent-checkboxes__error" :errors="errors" />
  </div>
</template>

<script>
import cloneDeep from 'lodash.clonedeep'
import { PERSON_TYPES } from '@/store/report/report-settings'
import Checkbox from '@/components/Form/Checkbox.vue'
import FieldErrorMessage from '@/components/Form/FieldErrorMessage.vue'
import {
  props,
  getValidationData,
  computed,
  watch,
  validate,
} from '@/components/Form/field-settings'

export default {
  name: 'ParentCheckboxes',
  components: {
    Checkbox,
    FieldErrorMessage,
  },

  props: {
    ...props,

    parentNames: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      ...getValidationData(),
    }
  },

  computed: {
    ...computed,

    labels() {
      const labels = {}

      for (const personType in this.parentNames) {
        const name = this.parentNames[personType]
        if (name) labels[personType] = name
      }
      return labels
    },

    models() {
      const personTypes = this.model.split(',')
      return {
        [PERSON_TYPES.self]: personTypes.includes(PERSON_TYPES.self),
        [PERSON_TYPES.partner]: personTypes.includes(PERSON_TYPES.partner),
      }
    },
  },

  watch,

  methods: {
    validate,

    getParentId(personType) {
      return `parent-${personType}`
    },

    stringifyModels(models) {
      return Object.keys(models)
        .filter(personType => !!models[personType])
        .join(',')
    },

    updateModel(model, personType) {
      const models = cloneDeep(this.models)
      models[personType] = model
      this.$emit('update:model', this.stringifyModels(models))
    },
  },
}
</script>

<style lang="scss" scoped>
.parent-checkboxes {
  &__error {
    margin-top: 10px;
  }
}
</style>
