<template>
  <FieldWrapper class="date-input" :class="{ 'date-input--error': hasError }">
    <label
      class="date-input__label"
      :class="{
        'date-input__label--is-placeholder': hasPlaceholder,
        'date-input__label--disabled': isComputedDisabled,
        'date-input__label--not-editable': !isEditable,
      }"
      :for="id"
      >{{ label }}</label
    >
    <input
      :id="id"
      ref="field"
      :class="[
        'date-input__field date-input__field--text',
        {
          'date-input__field--disabled': isComputedDisabled,
          'date-input__field--not-editable': !isEditable,
          'date-input__field--error': hasError,
        },
      ]"
      :value="renderDate(model, locale)"
      :type="type || 'text'"
      :disabled="isComputedDisabled"
      v-on="eventHandlers"
      @focus="openDatePicker"
      @animationstart="onAnimationStart"
    />
    <Datetime
      ref="datetime"
      :inputId="inputId"
      :minDatetime="minDatetimeInternal || minDatetime"
      :maxDatetime="maxDatetimeInternal || maxDatetime"
      valueZone="UTC"
      zone="UTC"
      :phrases="submitCtaTranslations"
      @input="updateDatetimeModel"
      @close="onDatePickerClose"
    />
    <slot />
    <FieldErrorMessage :errors="errors" />
    <component :is="'style'" v-if="overlayHeight">
      .vdatetime-overlay { height: {{ overlayHeight + 'px' }}; }
    </component>
  </FieldWrapper>
</template>

<script>
import { mapGetters } from 'vuex'
import { Datetime } from 'vue-datetime'
import { renderDate } from '@/helpers/render'
import FieldWrapper from './FieldWrapper.vue'
import FieldErrorMessage from './FieldErrorMessage.vue'
import { inputProps, getData, computed, watch, methods } from './field-settings'

export const COMPONENT_NAME = 'DateInput'

export default {
  name: COMPONENT_NAME,
  components: {
    Datetime,
    FieldWrapper,
    FieldErrorMessage,
  },

  props: {
    ...inputProps,

    specificYearOnly: {
      type: Number,
      validator: value => /^\d{4}$/.test(value),
    },
    minDatetime: {
      type: String,
    },
    maxDatetime: {
      type: String,
    },
  },

  data() {
    return {
      ...getData(),
      renderDate,
      datetimeModel: '',
      overlayHeight: '',
    }
  },

  computed: {
    ...computed,

    ...mapGetters({
      locale: 'page/locale',
    }),

    inputId() {
      return `datetime-${this.id}`
    },

    hasPlaceholder() {
      return !this.isAutofilled && !/\S/.test(this.model)
    },

    minDatetimeInternal() {
      return typeof this.specificYearOnly !== 'undefined'
        ? `${this.specificYearOnly}-01-01T12:00:00Z`
        : null
    },

    maxDatetimeInternal() {
      return typeof this.specificYearOnly !== 'undefined'
        ? `${this.specificYearOnly}-12-31T12:00:00Z`
        : null
    },

    submitCtaTranslations() {
      return { ok: this.$t('globals.ok'), cancel: this.$t('globals.cancel') }
    },
  },

  watch,

  methods: {
    ...methods,

    async openDatePicker(event) {
      this.$refs.datetime.open(event)
      await this.$nextTick()
      this.calculateOverlayHeight()
    },

    onDatePickerClose() {
      this.overlayHeight = ''
    },

    calculateOverlayHeight() {
      this.overlayHeight = window.innerHeight
    },

    updateDatetimeModel(value) {
      const dateMatch = value.match(/^\d{4}-\d{2}-\d{2}/)
      if (dateMatch) this.$emit('update:model', dateMatch[0])
    },

    getInputElement() {
      return this.$refs.field
    },
  },
}
</script>

<style lang="scss">
@import 'vue-datetime/dist/vue-datetime';
</style>

<style scoped lang="scss">
@import './field-settings';

@include autofill-keyframes;

.date-input {
  &__label {
    @include label-style;
  }

  &__field {
    @include field-style;
    background-color: transparent;
  }

  /deep/ .vdatetime {
    user-select: none;
  }

  /deep/ .vdatetime-overlay {
    background: rgba($color-black, 0.8);
  }

  /deep/ .vdatetime-popup {
    background: linear-gradient(to right, $color-white, $color-white) no-repeat 0px 5px;
    border-radius: $border-radius;
    box-shadow: none;
    font-family: $font-family-body;
  }

  /deep/ .vdatetime-popup__header {
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }

  /deep/ .vdatetime-input {
    display: none;
  }
}
</style>
