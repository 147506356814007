import { getApiClient } from '@/api/client'
import { toData } from '@/helpers/request'
import USER_REPORTS_GQL from '@/api/queries/user-reports.gql'
import CREATE_OR_UPDATE_REPORT_PERSON_GQL from '@/api/mutations/create-or-update-report-person.gql'
import CREATE_OR_UPDATE_REPORT_ANSWER_GQL from '@/api/mutations/create-or-update-report-answer.gql'
import CREATE_OR_UPDATE_REPORT_DOCUMENT_GQL from '@/api/mutations/create-or-update-report-document.gql'
import CREATE_OR_UPDATE_REPORT_ORDER_GQL from '@/api/mutations/create-or-update-report-order.gql'
import { getDefaultAddressData } from '@/components/Account/Reports/Report/ReportPersonalDetails/report-personal-details-settings'
import { getTypeFromPersonKey, getIndexFromPersonKey } from './report-helpers'
import { STEP_KEYS } from './report-settings'

const MUTATION_SETTINGS = {
  [STEP_KEYS.personalDetails]: {
    gql: CREATE_OR_UPDATE_REPORT_PERSON_GQL,
    name: 'createOrUpdateReportPerson',
    responseKey: 'reportPerson',
    listKey: 'reportPeople',
  },
  [STEP_KEYS.relevantQuestions]: {
    gql: CREATE_OR_UPDATE_REPORT_ANSWER_GQL,
    name: 'createOrUpdateReportAnswer',
    responseKey: 'reportAnswer',
    listKey: 'reportAnswers',
  },
  [STEP_KEYS.documents]: {
    gql: CREATE_OR_UPDATE_REPORT_DOCUMENT_GQL,
    name: 'createOrUpdateReportDocument',
    responseKey: 'reportDocument',
    listKey: 'reportDocuments',
  },
  [STEP_KEYS.payment]: {
    gql: CREATE_OR_UPDATE_REPORT_ORDER_GQL,
    name: 'createOrUpdateReportOrder',
    responseKey: 'reportOrder',
    listKey: 'reportOrders',
  },
  [STEP_KEYS.missingDocumentsAfterPurchase]: {
    gql: CREATE_OR_UPDATE_REPORT_DOCUMENT_GQL,
    name: 'createOrUpdateReportDocument',
    responseKey: 'reportDocument',
    listKey: 'reportDocuments',
  },
}

export async function getReports() {
  const response = await getApiClient().query({ query: USER_REPORTS_GQL })
  return response?.data?.me?.user.reports
}

export function formatReportDataForApi({ data, stepKey, personKey }) {
  const formattedData = {
    ...data,
    personIndex: getIndexFromPersonKey(personKey),
    personType: getTypeFromPersonKey(personKey),
  }
  delete formattedData.id
  delete formattedData.__typename
  delete formattedData.updated_at

  for (const key in formattedData) {
    if (key === 'additionalName') continue
    if (formattedData[key] === '') formattedData[key] = null
  }
  if (stepKey === STEP_KEYS.personalDetails) {
    const addressInput = {}
    Object.keys(getDefaultAddressData()).forEach(addressKey => {
      addressInput[addressKey] = formattedData[addressKey]
      delete formattedData[addressKey]
    })
    Object.assign(formattedData, { addressInput })
  }
  return formattedData
}

export async function storeReportData(stepKey, input, callback) {
  if (stepKey === STEP_KEYS.payment) return

  await toData(createOrUpdateReportData(stepKey, { input }, callback))
}

export function createOrUpdateReportData(stepKey, variables, callback) {
  const mutationSettings = MUTATION_SETTINGS[stepKey] || {}
  if (!mutationSettings.gql) return

  const update = (cache, { data }) => {
    const { name, responseKey, listKey } = mutationSettings
    const responseData = data[name] || {}
    const reportData = responseData[responseKey]
    if (!reportData) return

    const userData = cache.readQuery({ query: USER_REPORTS_GQL })
    const { reports } = userData.me.user
    const { year } = variables.input.where
    let report = reports.find(r => r.year === year)

    if (!report && responseData.report?.id) {
      report = {
        __typename: 'Report',
        ...responseData.report,
        reportPeople: [],
        reportAnswers: [],
        reportDocuments: [],
        reportOrders: [],
      }
      reports.push(report)
    }
    // The cache is updated automatically if the id is already part of the report
    if (!report || report[listKey].some(({ id }) => id === reportData.id)) {
      callback?.({ data: responseData })
      return
    }
    report[listKey].push(reportData)
    cache.writeQuery({ query: USER_REPORTS_GQL, data: userData }) // just in case
    callback?.({ data: responseData })
  }
  return getApiClient().mutate({ mutation: mutationSettings.gql, variables, update })
}

export function updateCacheOfPaymentCompletedAt(year, datetime) {
  const userData = getApiClient().cache.readQuery({ query: USER_REPORTS_GQL })
  if (!userData) return

  const { reports } = userData.me.user
  const { listKey } = MUTATION_SETTINGS[STEP_KEYS.payment]
  const report = reports.find(r => r.year === year)
  const reportOrder = (report?.[listKey] || [])[0]
  if (reportOrder) reportOrder.paymentCompletedAt = datetime
}
