export const FIELD_MARGIN_TOP = 10

export const validationProps = {
  isOptional: {
    type: Boolean,
    default: false,
  },
  isHidden: {
    type: Boolean,
    default: false,
  },
  isInvalid: {
    type: Function,
  },
}

export const props = {
  id: {
    type: String,
    required: true,
  },
  model: {
    required: true,
  },
  label: {
    type: String,
  },
  autocomplete: {
    type: String,
  },
  autocorrect: {
    type: String,
  },
  autocapitalize: {
    type: String,
  },
  isDisabled: {
    type: Boolean,
    default: false,
  },
  isFakeInput: {
    type: Boolean,
    default: false,
  },
  isEditable: {
    type: Boolean,
    default: true,
  },
  hasDarkColor: {
    type: Boolean,
    default: true,
  },
  hasRedBorder: {
    type: Boolean,
    default: false,
  },
  ...validationProps,

  formatter: {
    type: Function,
  },
  on: {
    type: Object,
    default: () => ({}),
  },
}

export const inputProps = {
  ...props,

  type: {
    type: String,
    default: 'text', // can be any HTML input types
  },

  placeholder: {
    type: String,
  },

  isTextarea: {
    type: Boolean,
    default: false,
  },
}

export const selectSpecificProps = {
  type: {
    type: String,
    default: 'select',
  },

  options: {
    type: Array,
    default: () => [],
    validator(value) {
      // eslint-disable-next-line no-prototype-builtins
      return value.every(opt => ['value', 'text'].every(k => opt.hasOwnProperty(k)))
    },
  },

  selectKey: {
    type: String,
    default: 'key',
  },

  isHidingLabelOnValue: {
    type: Boolean,
    default: false,
  },
}

export const selectProps = {
  ...props,
  ...selectSpecificProps,
}

export function getValidationData() {
  return {
    errors: [],
    externalErrors: [],
    isValidatingOnChange: false,
  }
}

export function getData() {
  return {
    isFieldComponent: true,
    isFocused: false,
    isAutofilled: false,
    isSelect: false,
    ...getValidationData(),
  }
}

export function getSelectData() {
  return {
    ...getData(),
    isSelect: true,
  }
}

export const validationComputed = {
  visibleErrors() {
    return this.isValidatingOnChange ? this.errors.concat(this.externalErrors) : this.errors
  },

  hasError() {
    return this.visibleErrors.length > 0
  },
}

export const propsComputed = {
  isComputedFakeInput() {
    return !this.isEditable || this.isFakeInput
  },

  isComputedDisabled() {
    return !this.isEditable || this.isDisabled
  },
}

export const computed = {
  ...validationComputed,
  ...propsComputed,

  hasPlaceholder() {
    return (!this.isFocused || this.isSelect) && !this.isAutofilled && !/\S/.test(this.model)
  },

  eventHandlers() {
    const handlers = { ...this.on }
    delete handlers.change
    handlers.focus = () => this.toggleFocus(true)
    handlers.blur = () => this.toggleFocus(false)

    return handlers
  },
}

export const watch = {
  model() {
    if (this.isValidatingOnChange) this.validate()
  },

  hasError() {
    this.$emit('error-change', this)
  },
}

export const inputWatch = {
  ...watch,

  model() {
    if (this.isValidatingOnChange) this.validate()

    // There was a bug when updating this.formDataInternal on "reuse previous year" where the value
    // attribute of the <input> was set to the correct value based on the model prop, but the DOM
    // element.value was still equal to the previous value.
    if (!this.isNoInputTag && this.$refs?.field) {
      const element = this.$refs.field

      clearTimeout(element.__sanitizeInputValueTimeout__)
      element.__sanitizeInputValueTimeout__ = setTimeout(() => {
        if (this.$refs?.field) {
          this.$refs.field.value = this.$refs.field.getAttribute('value')
        }
      }, 50)
    }
  },
}

export function validate() {
  const value = this.model
  this.errors = []

  const isEmpty = typeof value === 'undefined' || value === ''
  const shouldNotBeEmpty = !this.isOptional && !this.isHidden
  let isInvalid = false

  if (!isEmpty && !this.isHidden && this.isInvalid) {
    isInvalid = this.isInvalid(value)
  }
  if (isEmpty && shouldNotBeEmpty) {
    this.errors.push(this.$t('errors.requiredField'))
  }
  if (isInvalid) {
    this.errors.push(typeof isInvalid === 'string' ? isInvalid : this.$t('errors.invalidField'))
  }
  if (this.errors.length || this.externalErrors.length) {
    this.isValidatingOnChange = true
    return false
  } else {
    return true
  }
}

export const validationMethods = {
  validate,

  validateIfNeeded() {
    if (this.isValidatingOnChange) this.validate()
  },

  addOneTimeError(error) {
    this.errors.push(error)
  },
}

export const methods = {
  ...validationMethods,

  onAnimationStart(event) {
    this.isAutofilled = /^onAutoFillStart/.test(event.animationName)
  },

  toggleFocus(isFocused) {
    const key = isFocused ? 'focus' : 'blur'
    this.isFocused = isFocused
    if (this.on[key]) this.on[key]()
  },

  updateModel(event) {
    const input = event.target
    const hasFocus = input === document.activeElement
    const isInput = !!input.setSelectionRange
    let value = event.target.value
    let selectionStart, selectionEnd

    if (this.formatter) {
      if (isInput && hasFocus) {
        selectionStart = input.selectionStart
        selectionEnd = input.selectionEnd
      }
      value = this.formatter(value)
    }
    this.$nextTick(() => {
      if (this.on.change) this.on.change(input)
      if (this.isValidatingOnChange) this.validate()
    })
    this.$emit('update:model', value)

    if (isInput && this.formatter && hasFocus) {
      this.$nextTick(() => input.setSelectionRange(selectionStart, selectionEnd))
    }
  },
}
