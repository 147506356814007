<template>
  <div class="accordion" :style="elementStyle" v-on="$listeners">
    <slot />
  </div>
</template>

<script>
const DEFAULT_TRANSITION_TIME = 400

export default {
  name: 'Accordion',

  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },

    transitionTime: {
      type: Number,
      default: DEFAULT_TRANSITION_TIME,
    },

    isInstant: {
      type: Boolean,
      default: false,
    },

    isHeightAuto: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      height: 'auto',
      heightAnimationTimeout: null,
      isTransitioning: false,
    }
  },

  computed: {
    elementStyle() {
      return {
        height: this.height,
        transitionDuration: this.isInstant ? '' : `${this.transitionTime}ms`,
      }
    },
  },

  watch: {
    async isOpen() {
      if (!this.isOpen && this.isHeightAuto) {
        this.setHeightToScrollHeight()
        await this.$nextTick()
      }
      this.$emit('height-change', this.$el.scrollHeight * (this.isOpen ? 1 : -1))
      this.isTransitioning = this.isInstant ? false : true
      this.updateHeight()

      if (this.heightAnimationTimeout) clearTimeout(this.heightAnimationTimeout)
      this.heightAnimationTimeout = setTimeout(
        this.afterHeightChange,
        this.isInstant ? 0 : this.transitionTime
      )
    },
  },

  mounted() {
    this.updateHeight()
  },

  methods: {
    updateHeight() {
      if (!this.isOpen) return (this.height = '0px')
      if (!this.isTransitioning && this.isHeightAuto) return (this.height = 'auto')
      this.setHeightToScrollHeight()
    },

    setHeightToScrollHeight() {
      this.height = this.isInstant
        ? 'auto'
        : this.$el?.scrollHeight
          ? `${this.$el.scrollHeight}px`
          : 'auto'
    },

    afterHeightChange() {
      if (this.isOpen && this.isHeightAuto) this.height = 'auto'
      this.$emit('height-change-end')
      this.isTransitioning = false
    },
  },
}
</script>

<style lang="scss" scoped>
.accordion {
  position: relative;
  overflow: hidden;
  transition-property: height;
  transition-timing-function: ease-out;
  will-change: height;
}
</style>
