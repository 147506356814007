import { render, staticRenderFns } from "./OverlayInfoQuestionCta.vue?vue&type=template&id=15fe52f0&scoped=true&"
import script from "./OverlayInfoQuestionCta.vue?vue&type=script&lang=js&"
export * from "./OverlayInfoQuestionCta.vue?vue&type=script&lang=js&"
import style0 from "./OverlayInfoQuestionCta.vue?vue&type=style&index=0&id=15fe52f0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15fe52f0",
  null
  
)

/* custom blocks */
import block0 from "@/locales/report-field-details.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fhome%2Frunner%2Fwork%2Facces-impot%2Facces-impot%2Fpackages%2Facces-impot-front%2Fcomponents%2FOverlay%2FOverlayInfoQuestion%2FOverlayInfoQuestionCta.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports