<template>
  <Button :size="size" :isLoading="isLoading" @click="redirectToDocumentToSign">
    {{ $t('buttonText') }}
  </Button>
</template>

<script>
import { DOCUMENT_TO_APPROVE_URL_PARAM } from 'acces-impot-settings-report'
import _ReportService from '@/api/services/report'
import Button, { BUTTON_STYLES } from '@/components/Form/Button.vue'

/** @type import('../../api/services/types').ReportService */
const ReportService = _ReportService

export default {
  name: 'ApproveButton',
  components: {
    Button,
  },

  props: {
    size: {
      type: String,
      default: BUTTON_STYLES.size.small,
    },
  },

  data() {
    return {
      isLoading: false,
    }
  },

  i18n: {
    messages: {
      en: {
        buttonText: 'Approve',
      },
      fr: {
        buttonText: 'Approuver',
      },
    },
  },

  methods: {
    async redirectToDocumentToSign() {
      this.isLoading = true
      const { year } = this.$route.params
      const postDocumentId = this.$route.query[DOCUMENT_TO_APPROVE_URL_PARAM]
      const { locale } = this.$i18n

      const result = await ReportService.getDocumentToSign({
        year: Number(year),
        locale,
        postDocumentId,
      })
      if (result?.redirectUrl) window.location.href = result.redirectUrl
    },
  },
}
</script>
