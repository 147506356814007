import fetch from 'cross-fetch'
import { SERVER_ROUTES } from '@/config/routes'
import { COMMIT_HASH as APP_VERSION } from '@/server-middleware/constants'
import { EventBus, EVENTS } from '@/services/event-bus'

export const RELOAD_CHECK_INTERVAL_MS = 1000 * 60 * 60

async function fetchAppVersion() {
  const response = await fetch(SERVER_ROUTES.healthCheck)
  const appInfo = await response.json()
  return appInfo.gitSha
}

function getTimeNow() {
  return new Date().getTime()
}

/**
 * In order to avoid users to still browse an old version of the SPA which possibly query fields
 * that have been removed in the backend (not ideal, but that can still happen), we force a
 * full page reload on the next navigation if the app version is outdated.
 */
export default context => {
  if (process.server) return

  let lastCheckAt = getTimeNow()
  let isAppReloadScheduled = false

  window.addEventListener('focus', async () => {
    // Only check every hour to reduce the load on our servers
    if (getTimeNow() - lastCheckAt < RELOAD_CHECK_INTERVAL_MS || isAppReloadScheduled) return

    try {
      const latestAppVersion = await fetchAppVersion()
      const isOutdated = latestAppVersion !== APP_VERSION
      lastCheckAt = getTimeNow()

      if (isOutdated && !isAppReloadScheduled) {
        // The plugin is only running on client-side where the router should always be defined
        /* istanbul ignore next */
        context.app.router?.beforeEach(to => {
          window.location.href = to.fullPath
        })
        isAppReloadScheduled = true
        EventBus.emit(EVENTS.reloadApp)
      }
    } catch (e) {}
  })
}
