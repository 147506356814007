// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".panel_3uKzk{margin-top:20px}.downloadButton_3UfQB{display:inline-block;margin:16px 0 4px}.iconDocument_15rvV{display:inline-block;width:20px;color:#9d9d9c;transform:translateY(-1px)}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"panel": "panel_3uKzk",
	"downloadButton": "downloadButton_3UfQB",
	"iconDocument": "iconDocument_15rvV"
};
module.exports = ___CSS_LOADER_EXPORT___;
