import to from 'await-to-js'
import { ADMIN_ROLES } from 'acces-impot-settings-user'
import { getCookie, setCookie } from 'acces-impot-utils/lib/cookie'
import { simpleHash } from 'acces-impot-utils/lib/string'
import {
  AUTH_TOKEN_EXPIRATION_DAYS,
  AUTH_TOKEN_CREATOR_KEY,
  MESSAGE_ON_LOAD_URL_PARAM,
  MESSAGE_ON_LOAD_IDS,
} from 'acces-impot-utils/lib/tracking'
import { ROUTE_NAMES, AUTH_TYPES, AUTH_REFERER_KEY } from '@/constants/route-names'
import { getApiClient } from '@/api/client'
import { EventBus, EVENTS } from '@/services/event-bus'
import { track, TRACK_CATEGORIES, TRACK_ACTIONS } from '@/services/tracking'
import { onChatwootReady } from '@/services/tracking/chatwoot'
import { renameKey } from '@/helpers/object'
import { getGqlErrorMessages } from '@/helpers/request'

import CURRENT_USER_GQL from '@/api/queries/current-user.gql'
import USER_LOGIN_GQL from '@/api/mutations/user-login.gql'
import USER_SIGNUP_GQL from '@/api/mutations/user-signup.gql'
import CONFIRM_USER_EMAIL_GQL from '@/api/mutations/confirm-user-email.gql'
import RESEND_SIGNUP_CONFIRMATION_EMAIL_GQL from '@/api/mutations/resend-signup-confirmation-email.gql'
import SEND_PASSWORD_FORGOTTEN_EMAIL_GQL from '@/api/mutations/send-password-forgotten-email.gql'
import RESET_PASSWORD_GQL from '@/api/mutations/reset-password.gql'

const getDefaultCurrentUser = () => ({})

function getLoggedOutState() {
  return {
    currentUser: getDefaultCurrentUser(),
    isLoggingOut: false,
  }
}

export default {
  namespaced: true,
  state: () => ({
    ...getLoggedOutState(),
    isCurrentUserFetched: false,
  }),

  mutations: {
    setCurrentUser(state, user) {
      const currentUser = { ...user }
      if (Object.values(currentUser).length) {
        renameKey(currentUser, 'blocked', 'isBlocked')
        renameKey(currentUser, 'confirmed', 'isConfirmed')
        currentUser.isBlocked = !!currentUser.isBlocked
        currentUser.isConfirmed = !!currentUser.isConfirmed
      }
      state.currentUser = currentUser
      this.app.$sentry.setUser({ email: currentUser.email })

      onChatwootReady(() => {
        if (currentUser.user?.id) {
          window.$chatwoot.setUser(currentUser.user.id, { email: currentUser.email })
        }
      })
    },

    setIsCurrentUserFetched(state, isFetched) {
      state.isCurrentUserFetched = isFetched
    },

    setLoggedOutState(state) {
      const loggedOutState = getLoggedOutState()
      for (const key in loggedOutState) {
        state[key] = loggedOutState[key]
      }
    },

    setIsLoggingOut(state, payload) {
      state.isLoggingOut = payload
    },
  },

  actions: {
    async fetchCurrentUser({ commit, dispatch }, token) {
      const apiClient = getApiClient(this.app)
      if (token) {
        const variables = { token }
        const [error, response] = await to(
          apiClient.mutate({ mutation: CONFIRM_USER_EMAIL_GQL, variables })
        )
        if (!error && response?.data.emailConfirmation) {
          await dispatch('afterLogin', { response: response.data.emailConfirmation })
          track(TRACK_CATEGORIES.account, TRACK_ACTIONS.confirmed)

          const text = this.$i18n.t('auth.success.accountConfirmed')
          dispatch('flash/addSuccessMessage', { key: 'accountConfirmed', text }, { root: true })
        } else {
          setTimeout(() => {
            const errors = getGqlErrorMessages(response)
            errors.forEach(error => {
              const text = this.$i18n.t(error.msg, { token })
              dispatch('flash/addErrorMessage', { text }, { root: true })
            })
          }, 800)
        }
      } else if (this.app.$apolloHelpers.getToken()) {
        const [error, response] = await to(apiClient.query({ query: CURRENT_USER_GQL }))

        if (!error && response?.data?.me) {
          const user = response.data.me
          const isValid = await dispatch('validateUserResponse', { response: { user } })
          if (!isValid) return

          dispatch('setCurrentUserData', user)
        }
      }
      commit('setIsCurrentUserFetched', true)
    },

    async login({ dispatch }, formData) {
      const [error, response] = await to(
        getApiClient().mutate({ mutation: USER_LOGIN_GQL, variables: formData })
      )
      if (!error && response?.data.login) {
        await dispatch('afterLogin', { email: formData.email, response: response.data.login })
      }

      return { error, data: response?.data.login || null }
    },

    setCurrentUserData({ commit }, user) {
      if (!user) return
      commit('setCurrentUser', user)
      if (user.user?.reports) commit('report/setReports', user.user.reports, { root: true })
    },

    async afterLogin({ dispatch }, { email, response }) {
      const { jwt, user } = response

      const isValid = await dispatch('validateUserResponse', { email, response })
      if (!isValid) return

      dispatch('setCurrentUserData', user)

      if (jwt && email) {
        this.app.$apolloHelpers.onLogin(jwt)

        const { res } = this.app.context || {}
        const { authCookieDomain } = this.app.$config

        setCookie({
          res,
          key: AUTH_TOKEN_CREATOR_KEY,
          value: simpleHash(email),
          daysBeforeExpiration: AUTH_TOKEN_EXPIRATION_DAYS,
          domain: authCookieDomain,
        })
      }
    },

    async validateUserResponse(_context, { email, response }) {
      if (process.server) return

      const { jwt, user } = response
      const { req } = this.app.context || {}
      const sanitizedEmailInput = (email || '').toLowerCase()
      const creatorKey = sanitizedEmailInput
        ? simpleHash(sanitizedEmailInput)
        : getCookie({ req, key: AUTH_TOKEN_CREATOR_KEY })

      /**
       * If the creator key does not match the email returned by the server, it means the
       * current user is about to have access to data he/she shouldn't have access to. In that case,
       * we log the user out, send an error to sentry and force reload the page.
       */
      if (!creatorKey || creatorKey !== simpleHash(user?.email?.toLowerCase() || '')) {
        const currentUrl = new URL(window.location.href)
        if (
          currentUrl.searchParams.get(MESSAGE_ON_LOAD_URL_PARAM) ===
          MESSAGE_ON_LOAD_IDS.errorForceReload
        ) {
          return true
        }
        this.app.$apolloHelpers.onLogout()

        const hasSeenItMoreThanOnce =
          localStorage.getItem(MESSAGE_ON_LOAD_URL_PARAM) === MESSAGE_ON_LOAD_IDS.errorForceReload

        if (hasSeenItMoreThanOnce) {
          this.app.$sentry.withScope(scope => {
            scope.setExtras({
              jwt,
              creatorKey,
              emailParam: email,
              serverEmail: user?.email,
              cookieValue: getCookie({ req, key: AUTH_TOKEN_CREATOR_KEY }),
            })
            this.app.$sentry.captureMessage(
              "The email from the server doesn't match the one used for login."
            )
          })
        }
        localStorage.setItem(MESSAGE_ON_LOAD_URL_PARAM, MESSAGE_ON_LOAD_IDS.errorForceReload)
        currentUrl.searchParams.set(MESSAGE_ON_LOAD_URL_PARAM, MESSAGE_ON_LOAD_IDS.errorForceReload)
        window.location.href = currentUrl.toString()

        return false
      }
      return true
    },

    logout({ commit, getters }, router) {
      if (!getters.isUserLoggedIn) return

      commit('setIsLoggingOut', true)
      this.app.$apolloHelpers.onLogout()
      this.app.$sentry.setUser(null)

      onChatwootReady(() => window.$chatwoot.reset())

      EventBus.$once(EVENTS.beforeViewEnter, () =>
        commit('setCurrentUser', getDefaultCurrentUser())
      )

      if (router) {
        const { isRequiringAuthConfirmed, isRequiringAuth } = router.currentRoute?.meta || {}
        if (isRequiringAuthConfirmed || isRequiringAuth) {
          if (process.browser) localStorage.setItem(AUTH_REFERER_KEY, router.currentRoute.path)
          router.push(
            this.app.localeRoute({ name: ROUTE_NAMES.auth, params: { type: AUTH_TYPES.login } })
          )
        }
      }
    },

    async signup({ dispatch }, formData) {
      const [error, response] = await to(
        getApiClient().mutate({ mutation: USER_SIGNUP_GQL, variables: formData })
      )
      if (!error && response?.data.register) {
        const { jwt, user } = response.data.register
        await dispatch('afterLogin', { email: formData.email, response: { jwt, user } })

        track(TRACK_CATEGORIES.account, TRACK_ACTIONS.signup)
      }
      return { error, data: response?.data.register || null }
    },

    async resendSignupConfirmationEmail({ dispatch }) {
      const [error] = await to(
        getApiClient().mutate({ mutation: RESEND_SIGNUP_CONFIRMATION_EMAIL_GQL })
      )
      if (!error) {
        const text = this.$i18n.t('auth.success.signupConfirmationEmailWasSent')
        dispatch('flash/addSuccessMessage', { text, isAutoHiding: false }, { root: true })
        return true
      } else {
        dispatch('flash/addConnectionErrorMessage', {}, { root: true })
        return false
      }
    },

    async sendPasswordForgottenEmail({ dispatch }, formData) {
      const [error] = await to(
        getApiClient().mutate({ mutation: SEND_PASSWORD_FORGOTTEN_EMAIL_GQL, variables: formData })
      )
      if (!error) {
        const text = this.$i18n.t('auth.success.passwordForgottenEmailWasSent')
        dispatch('flash/addSuccessMessage', { text, isAutoHiding: false }, { root: true })
      }
      return { error }
    },

    async resetPassword({ dispatch }, { formData: { password }, token }) {
      const [error, response] = await to(
        getApiClient().mutate({ mutation: RESET_PASSWORD_GQL, variables: { password, token } })
      )
      if (!error && response?.data.resetUserPassword) {
        const { jwt, user } = response.data.resetUserPassword
        await dispatch('afterLogin', { email: user?.email, response: { jwt, user } })

        const text = this.$i18n.t('auth.success.resetPassword')
        dispatch('flash/addSuccessMessage', { text }, { root: true })
      }
      return { error, data: response?.data.resetUserPassword || null }
    },

    clearUserDataFromStoreIfNeeded({ state, dispatch }) {
      if (!state.isLoggingOut) return

      dispatch('clearUserDataFromStore')
    },

    clearUserDataFromStore({ commit }) {
      commit('setIsLoggingOut', false)
      commit('setLoggedOutState')
      commit('report/setInitialState', {}, { root: true })
    },
  },

  getters: {
    currentUser: state => state.currentUser,
    accurateRole: state => state.currentUser?.user?.accurateRole?.name,
    adminAccount: state => state.currentUser?.user?.adminAccount,
    isUserLoggedIn: state => !!Object.values(state.currentUser).length && !state.isLoggingOut,
    isCurrentUserFetched: state => state.isCurrentUserFetched,
    isAdmin: (_, getters) => Object.values(ADMIN_ROLES).includes(getters.accurateRole),
    isManager: (_, getters) => getters.accurateRole === ADMIN_ROLES.manager,
    isProducer: (_, getters) => getters.accurateRole === ADMIN_ROLES.producer,
    isSupervisor: (_, getters) => getters.accurateRole === ADMIN_ROLES.supervisor,
    adminGender: (_, getters) => getters.adminAccount?.gender,
    canTakeNewReport: (_, getters) => !!getters.adminAccount?.canTakeNewReport,
    hasLoginFormVisible: (state, getters) => {
      return state.isCurrentUserFetched && !getters.isUserLoggedIn && !state.isLoggingOut
    },
  },
}
