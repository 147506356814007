import { track, TRACK_CATEGORIES, TRACK_CONTEXTS, TRACK_ACTIONS } from '.'

export function registerChatwootEvents() {
  if (!window.$chatwoot) return

  window.addEventListener('chatwoot:on-message', trackChat)

  function trackChat() {
    track(TRACK_CATEGORIES.contact, TRACK_CONTEXTS.liveChat, { action: TRACK_ACTIONS.open })
    window.removeEventListener('chatwoot:on-message', trackChat)
  }
}

export function onChatwootReady(cb) {
  if (!process.browser) return

  try {
    window.$chatwoot ? cb() : window.addEventListener('chatwoot:ready', () => cb())
  } catch (err) {}
}
