// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".button_nz5N2{display:inline-block;white-space:nowrap;border:1px solid #ccc;border-radius:4px;padding:10px 16px;cursor:pointer}.button_nz5N2:hover{border-color:#8b8a88}.iconBefore_JeMtV{padding-right:20px}.iconAfter__tHam{padding-left:20px}.text_1L1er{display:inline-block}.iconBefore_JeMtV .text_1L1er{padding-left:10px}.iconAfter__tHam .text_1L1er{padding-right:10px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"button": "button_nz5N2",
	"iconBefore": "iconBefore_JeMtV",
	"iconAfter": "iconAfter__tHam",
	"text": "text_1L1er"
};
module.exports = ___CSS_LOADER_EXPORT___;
