class Validation {
  constructor({ isInvalid = () => {} }) {
    if (typeof isInvalid !== 'function') {
      throw new Error(`[${this.constructor.name}] - "isInvalid" must be a function.`)
    }
    this.isInvalid = isInvalid
  }
}

module.exports = Validation
