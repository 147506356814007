<template>
  <main
    ref="siteContainer"
    class="site-container js-site-scroll-adapt"
    :style="{ maxWidth: siteContainerMaxWidth, minHeight }"
  >
    <slot />
  </main>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import { EventBus, EVENTS } from '@/services/event-bus'

export default {
  name: 'SiteContainer',

  data() {
    return {
      minHeight: 'initial',
    }
  },

  computed: {
    ...mapState('page', ['isVisible', 'siteContainerMaxWidth']),
    ...mapState('viewport', ['mobileSafeHeight']),

    ...mapGetters({
      viewportWidth: 'viewport/width',
      viewportHeight: 'viewport/height',
      isMqMedium: 'viewport/isMqMedium',
    }),

    isAccountSection() {
      return this.$route.matched.some(route => {
        return new RegExp(`^/${this.$i18n.locale}/account`).test(route.path)
      })
    },
  },

  watch: {
    $route: {
      immediate: true,
      handler() {
        this.setIsAccountSection(this.isAccountSection)
      },
    },

    mobileSafeHeight: {
      immediate: true,
      handler: 'setMinHeight',
    },
  },

  created() {
    EventBus.$on(EVENTS.beforeViewEnter, this.beforeViewEnter)
  },

  beforeDestroy() {
    EventBus.$off(EVENTS.beforeViewEnter, this.beforeViewEnter)
  },

  methods: {
    ...mapMutations({
      incrementPageViewCount: 'page/incrementPageViewCount',
      setIsAccountSection: 'page/setIsAccountSection',
    }),

    ...mapActions({
      clearUserDataFromStoreIfNeeded: 'account/clearUserDataFromStoreIfNeeded',
    }),

    beforeViewEnter() {
      this.incrementPageViewCount()
      this.clearUserDataFromStoreIfNeeded()
      this.setMinHeight()
    },

    setMinHeight() {
      // Use mobileSafeHeight to prevent jump on mobile when url bar disappears on scroll
      this.minHeight = this.mobileSafeHeight ? `${this.mobileSafeHeight}px` : 'initial'
    },
  },
}
</script>

<style lang="scss" scoped>
@import './site-container-settings';

.site-container {
  display: flex;
  position: relative;
  width: 100%;
  justify-content: center;
  text-align: left;
}
</style>
