var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FieldWrapper',{staticClass:"select",class:{ 'select--error': _vm.hasError }},[_c('div',{staticClass:"select__select-wrap",class:{ 'select__select-wrap--error': _vm.hasError || _vm.hasRedBorder }},[(_vm.label)?_c('label',{staticClass:"select__label",class:{
        'select__label--is-placeholder': _vm.hasPlaceholder && !_vm.isComputedDisabled,
        'select__label--disabled': _vm.isComputedDisabled,
        'select__label--not-editable': !_vm.isEditable,
        'select__label--hidden': _vm.selectValue && _vm.isHidingLabelOnValue,
        'select__label--dark': _vm.hasDarkColor,
      },attrs:{"for":_vm.id}},[_vm._v(_vm._s(_vm.label))]):_vm._e(),_c('IconChevronDown',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isComputedDisabled),expression:"!isComputedDisabled"}],staticClass:"select__select-arrow"}),_c('select',_vm._g({key:_vm.selectKey,ref:"field",staticClass:"select__field select__field--select",class:{
        'select__field--no-label': !_vm.label || (_vm.selectValue && _vm.isHidingLabelOnValue),
        'select__field--not-editable': !_vm.isEditable,
        'select__field--dark': _vm.hasDarkColor,
      },attrs:{"id":_vm.id,"autocomplete":_vm.autocomplete,"disabled":_vm.isComputedDisabled},domProps:{"value":_vm.selectValue},on:{"change":_vm.updateModel,"animationstart":_vm.onAnimationStart}},_vm.eventHandlers),_vm._l((_vm.options),function(option,index){return _c('option',{key:index,domProps:{"value":option.value}},[_vm._v("\n        "+_vm._s(option.text)+"\n      ")])}),0)],1),_vm._t("default"),_c('FieldErrorMessage',{attrs:{"errors":_vm.errors}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }