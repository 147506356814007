<template>
  <QuestionMarkCta
    v-if="isValid"
    class="overlay-info-question-cta"
    :class="{ 'overlay-info-question-cta--checkbox': isCheckbox }"
    @click="onClick"
  />
</template>

<script>
import { mapActions } from 'vuex'
import { PERSON_TYPES } from 'acces-impot-settings-report'
import { personalizeTranslation } from '@/helpers/i18n'
import QuestionMarkCta from '@/components/Form/QuestionMarkCta.vue'

export default {
  name: 'OverlayInfoQuestionCta',
  components: {
    QuestionMarkCta,
  },

  props: {
    topBarKey: {
      type: String,
      default: '',
    },

    moreDetailsKey: {
      type: String,
      default: '',
    },

    whereToFindKey: {
      type: String,
      default: '',
    },

    translationOptions: {
      type: Object,
    },

    translationInstance: {
      type: Object,
    },

    personType: {
      type: String,
      default: '',
    },

    personGender: {
      type: String,
      default: '',
    },

    isAdmin: {
      type: Boolean,
      default: false,
    },

    isCheckbox: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    topBarText() {
      return this.translate(this.topBarKey)
    },

    moreDetailsText() {
      return this.translate(this.moreDetailsKey)
    },

    whereToFindText() {
      return this.translate(this.whereToFindKey)
    },

    isValid() {
      const isPropValid = (key, text) => key && text
      return (
        isPropValid(this.topBarKey, this.topBarText) &&
        (isPropValid(this.moreDetailsKey, this.moreDetailsText) ||
          isPropValid(this.whereToFindKey, this.whereToFindText))
      )
    },
  },

  methods: {
    ...mapActions({
      showOverlayInfoQuestion: 'overlay/showOverlayInfoQuestion',
    }),

    translate(key) {
      if (/\s/.test(key)) return key

      const getPersonType = type =>
        this.isAdmin && type === PERSON_TYPES.self ? PERSON_TYPES.partner : type

      const options = {
        key,
        personType: getPersonType(this.personType),
        personGender: this.personGender,
        options: this.translationOptions,
      }
      const instance = this.translationInstance || this
      let translatedCopy = personalizeTranslation.call(instance, options)

      if (typeof translatedCopy === 'object') {
        translatedCopy = personalizeTranslation.call(instance, {
          ...options,
          personType: getPersonType(PERSON_TYPES.self),
        })
      }
      return /^\w+(\.\w+)+$/.test(translatedCopy) ? '' : translatedCopy
    },

    onClick(event) {
      event.stopPropagation()
      const { topBarText, moreDetailsText, whereToFindText } = this
      this.showOverlayInfoQuestion({ topBarText, moreDetailsText, whereToFindText })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/components/Form/field-settings.scss';

.overlay-info-question-cta {
  &.question-mark-cta {
    top: #{round($input-height-with-border / 2)};
    right: $horizontal-padding - $question-mark-circle-wrap-padding;
  }

  /deep/ .question-mark-cta__inner {
    box-shadow: 0 0 5px 10px $color-white;
  }

  &--checkbox {
    &.question-mark-cta {
      position: static;
      top: auto;
      right: auto;
      padding-top: 0;
      padding-right: $horizontal-padding;
      padding-bottom: 0;
      transform: translateY(0);
    }
  }
}
</style>

<i18n src="@/locales/report-field-details.json" />
