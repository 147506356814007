<template>
  <!-- eslint-disable vue/no-v-html -->
  <component :is="tag" :class="{ [$style.hasLinks]: isFormattingLinks }" v-html="sanitizedHtml" />
  <!-- eslint-enable vue/no-v-html -->
</template>

<script>
import { resolveLineBreakOfLastWord, makeHtmlSafe } from '@/helpers/string'

const PUNCTUATION_SPACE_CHAR = '§'
const LINK_SPACE_CHAR = 'φ'
const LINK_REGEX = /\[([^\]]+)\]\(([^)]+)\)/g

export default {
  name: 'LineBreakResolver',

  props: {
    tag: {
      type: String,
      default: 'span',
    },

    text: {
      type: String,
      default: '',
    },

    html: {
      type: String,
      default: '',
    },

    firstLineCharLength: {
      type: Number,
    },

    lastNoBrExtraHtml: {
      type: String,
      default: '',
    },

    isResolvingLastWord: {
      type: Boolean,
      default: true,
    },

    isFormattingLinks: {
      type: Boolean,
      default: false,
    },

    isFormattingLineBreakChars: {
      type: Boolean,
      default: false,
    },

    options: {
      type: Object,
      default: () => ({}),
    },
  },

  data() {
    return {
      hasPunctuationSpaceChar: false,
      hasLinkSpaceChar: false,
    }
  },

  computed: {
    htmlSafeText() {
      return this.html || this.text ? this.html || makeHtmlSafe(this.text) : ''
    },

    sanitizedHtml() {
      if (!this.htmlSafeText) return ''

      let html = this.resolveLineBreakOfPunctuation(this.htmlSafeText)
      if (this.isFormattingLinks) html = this.formatLinks(html)
      if (this.isResolvingLastWord) html = resolveLineBreakOfLastWord(html, this.options)

      if (this.firstLineCharLength && this.firstLineCharLength < this.htmlSafeText.length) {
        const splitIndex = this.findEndOfWordIndex(html, this.firstLineCharLength)
        html = [
          '<div class="u-nobr">',
          html.substring(0, splitIndex),
          '</div>',
          html.substring(splitIndex),
        ].join('')
      }
      if (this.hasPunctuationSpaceChar) {
        html = html.replace(new RegExp(`\\S+${PUNCTUATION_SPACE_CHAR}\\S+`, 'g'), m => {
          return `<span class="u-nobr">${m.replace(
            new RegExp(PUNCTUATION_SPACE_CHAR, 'g'),
            ' '
          )}</span>`
        })
      }
      if (this.hasLinkSpaceChar) {
        html = html.replace(LINK_REGEX, (_md, text, href) => {
          const clean = v => v.replace(new RegExp(LINK_SPACE_CHAR, 'g'), ' ')
          return `<a href="${clean(href)}" target="_blank">${clean(text)}</a>`
        })
      }
      if (this.isFormattingLineBreakChars) {
        html = html
          .replace(/[\r\n]- ([^\r\n]+)/g, `\n<div class="${this.$style.listItem}">$1</div>`)
          .replace(/(\s*(?:\n\r?|\r\n?)\s*){2,}/g, `<div class="${this.$style.space}"></div>`)
      }
      if (this.lastNoBrExtraHtml) {
        const regex = /(<span class="u-nobr">)([^<]+)(<\/span>((?!<span class="u-nobr">)[\s\S])*)$/
        html = html.replace(regex, `$1$2${this.lastNoBrExtraHtml}$3`)
      }
      return html
    },
  },

  methods: {
    resolveLineBreakOfPunctuation(text) {
      return this.resolveFrenchPunctuations(this.resolveFrenchQuotes(text))
    },

    resolveFrenchPunctuations(text) {
      return text.replace(/(^|\s)([^\s<>]+\s+[?!:;])/g, (_m, p1, p2) => {
        this.hasPunctuationSpaceChar = true
        return p1 + p2.replace(/\s+/, PUNCTUATION_SPACE_CHAR)
      })
    },

    resolveFrenchQuotes(text) {
      return text.replace(/«\s+[^»]+»/g, m => {
        this.hasPunctuationSpaceChar = true
        return m
          .replace(/«\s+/g, `«${PUNCTUATION_SPACE_CHAR}`)
          .replace(/\s+»/g, `${PUNCTUATION_SPACE_CHAR}»`)
      })
    },

    formatLinks(text) {
      return text.replace(LINK_REGEX, link => {
        this.hasLinkSpaceChar = true
        return link.replace(/\s+/g, LINK_SPACE_CHAR)
      })
    },

    findEndOfWordIndex(text, fromIndex) {
      let index = fromIndex

      while (/[\S<]/.test(text[index]) && index < text.length) {
        if (text[index] === '<') {
          const fullTagMatch = text.substring(index).match(/<[^>]+>[^<]+<\/[^>]+>/)
          if (fullTagMatch) {
            index += fullTagMatch[0].length
            continue
          }
        }
        index++
      }
      return index
    },
  },
}
</script>

<style lang="scss" module>
$line-height-px: $font-size-normal * $line-height-normal;

.hasLinks {
  a {
    text-decoration: underline;

    &:hover {
      color: $color-grey-2;
    }
  }
}

.listItem {
  position: relative;
  padding-left: 20px;

  &:before {
    content: '';
    position: absolute;
    top: round($line-height-px / 2);
    left: 1px;
    background-color: currentColor;
    border-radius: 50%;
    width: 5px;
    height: 5px;
  }
}

.space {
  content: '';
  display: block;
  margin-top: round($line-height-px / 2);
  height: 0;
  font-size: 0;
}
</style>
