<template>
  <div class="overlay-loader">
    <div v-for="component in renderedComponents" :key="`overlay-${component.id}`">
      <keep-alive>
        <component :is="component.is" v-bind="component.props" />
      </keep-alive>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { OVERLAY_COMPONENT_NAMES, URL_PARAMS_TO_SHOW_OVERLAYS } from '@/store/overlay/overlay'
import { getQueryFromUrl } from '@/helpers/url'
import { enableDocumentScroll, disableDocumentScroll } from '@/helpers/scroll'
import { retry } from '@/helpers/request'

export default {
  name: 'OverlayLoader',

  computed: {
    ...mapState({
      overlayProps: state => state.overlay.props,
      preloadedOverlayIds: state => state.overlay.preloadedOverlayIds,
      renderedOverlayIds: state => state.overlay.renderedOverlayIds,
    }),

    ...mapGetters({
      hasFullScreenVisibleOverlays: 'overlay/hasFullScreenVisibleOverlays',
    }),

    renderedComponents() {
      return this.renderedOverlayIds.map(id => {
        return { is: this.getComponentImport(id), id, props: this.overlayProps[id] }
      })
    },
  },

  watch: {
    hasFullScreenVisibleOverlays: {
      immediate: true,
      handler() {
        if (process.browser) {
          this.hasFullScreenVisibleOverlays ? disableDocumentScroll() : enableDocumentScroll()
        }
      },
    },

    preloadedOverlayIds: {
      immediate: true,
      handler() {
        this.preloadedOverlayIds.forEach(id => {
          // make the browser download the scripts (doesn't render the component)
          this.getComponentImport(id)()
        })
      },
    },
  },

  mounted() {
    this.showOverlaysBasedOnUrlParams()
  },

  methods: {
    ...mapActions({
      preloadOverlay: 'overlay/preload',
      showOverlay: 'overlay/show',
    }),

    getComponentImport(id) {
      const component = () => {
        const name = OVERLAY_COMPONENT_NAMES[id]
        return retry(() => import(/* webpackChunkName: "[request]" */ `./${name}/${name}.vue`))
      }
      return component
    },

    showOverlaysBasedOnUrlParams() {
      for (const id in URL_PARAMS_TO_SHOW_OVERLAYS) {
        if (getQueryFromUrl(URL_PARAMS_TO_SHOW_OVERLAYS[id])) {
          const delay = Number(getQueryFromUrl('delay'))
          const preloadPromise = this.preloadOverlay(id)
          const show = async () => {
            await preloadPromise
            await this.$nextTick()
            this.showOverlay(id)
          }
          delay ? setTimeout(show, delay * 1000) : show()
        }
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.overlay-loader {
  position: fixed;
  top: 0;
  left: 0;
  z-index: $site-header-z-index + 1;
}
</style>
