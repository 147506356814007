import accountStore from '@/store/account/account'
import adminStore from '@/store/admin/admin'
import featureStore from '@/store/feature/feature'
import flashStore from '@/store/flash/flash'
import overlayStore from '@/store/overlay/overlay'
import pageStore from '@/store/page/page'
import reportStore from '@/store/report/report'
import viewportStore from '@/store/viewport/viewport'

export function registerStores(store, stores) {
  for (const storeName in stores) {
    if (!store._modules.root._children[storeName]) {
      store.registerModule(storeName, stores[storeName], {
        preserveState: !!store.state[storeName],
      })
    }
  }
}

export default ({ store }) => {
  registerStores(store, {
    account: accountStore,
    admin: adminStore,
    feature: featureStore,
    report: reportStore,
    flash: flashStore,
    overlay: overlayStore,
    page: pageStore,
    viewport: viewportStore,
  })
}
