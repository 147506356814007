/**
 * https://forum.vuejs.org/t/how-to-use-the-same-ref-for-more-than-one-item/22481/10
 *
 * Add the same ref to multiple elements.
 *
 * @author LinusBorg, Simon Herteby
 * @example
 * <template>
 *   <div v-multi-ref:yourRefName>a</div>
 *   <li v-multi-ref:yourRefName>b</li>
 *   <a v-multi-ref:yourRefName>c</a>
 * </template>
 *
 * <script>
 *   mounted() {
 *     console.log(this.$refs.yourRefName) // [div, li, a]
 *   },
 * </script>
 */

function addRef(_el, { value: ref }, vnode) {
  if (!ref) return

  const vm = vnode.context
  const thing = vnode.componentInstance || vnode.elm
  if (!Object.prototype.hasOwnProperty.call(vm.$refs, ref)) {
    vm.$refs[ref] = []
  }
  const index = vm.$refs[ref].indexOf(thing)
  if (index == -1) {
    vnode.context.$refs[ref].push(thing)
  }
}

function removeRef(_el, { value: ref }, { context: vm }, vnode) {
  if (Object.prototype.hasOwnProperty.call(vm.$refs, ref)) {
    const arr = vm.$refs[ref]
    const thing = vnode.componentInstance || vnode.elm
    const index = arr.indexOf(thing)
    if (index) {
      arr.splice(index, 1)
    }
  }
}

export default {
  bind: addRef,
  update: addRef,
  unbind: removeRef,
}
