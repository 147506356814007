<template>
  <div class="field-wrapper">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'FieldWrapper',
}
</script>

<style scoped lang="scss">
.field-wrapper {
  position: relative;
  overflow: hidden;
  margin-top: $field-margin-top;
  vertical-align: top;

  &--button {
    margin-top: $field-button-margin-top;
  }

  &--has-table {
    margin-left: $button-margin-left;
  }
}
</style>
