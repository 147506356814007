import { PERSON_TYPES, fileStatuses } from 'acces-impot-settings-report'
export { PERSON_TYPES }

export const REPORT_STATUSES = {
  notStarted: 'notStarted',
  ...fileStatuses,
}

export const STEP_KEYS = {
  personalDetails: 'personalDetails',
  relevantQuestions: 'relevantQuestions',
  documents: 'documents',
  payment: 'payment',
  missingDocumentsAfterPurchase: 'missingDocumentsAfterPurchase',
}

const STEP_INDEXES = {}
Object.values(STEP_KEYS).forEach((k, i) => (STEP_INDEXES[k] = i))
export { STEP_INDEXES }

export const STEP_DEFAULT_COUNT = 1

export const STEP_DATA = {
  [STEP_KEYS.personalDetails]: {
    name: 'ReportPersonalDetails',
    text: 'account.report.steps.personalDetails.tab',
  },
  [STEP_KEYS.relevantQuestions]: {
    name: 'ReportRelevantQuestions',
    text: 'account.report.steps.relevantQuestions.tab',
  },
  [STEP_KEYS.documents]: {
    name: 'ReportDocuments',
    text: 'account.report.steps.documents.tab',
  },
  [STEP_KEYS.payment]: {
    name: 'ReportPayment',
    text: 'account.report.steps.payment.tab',
  },
  [STEP_KEYS.missingDocumentsAfterPurchase]: {
    name: 'ReportMissingDocuments',
    text: 'account.report.steps.missingDocumentsAfterPurchase.tab',
  },
}

export const DEFAULT_FAMILY_MEMBERS = {
  [PERSON_TYPES.self]: STEP_DEFAULT_COUNT,
  [PERSON_TYPES.partner]: 0,
  [PERSON_TYPES.child]: 0,
}

export const DEFAULT_FAMILY_MEMBERS_WITH_REPORT = {
  [PERSON_TYPES.self]: [0],
  [PERSON_TYPES.partner]: [],
}
