import Debouncer from '@/helpers/debouncer'
const debouncer = Debouncer()

export const MEDIA_BREAKPOINTS = {
  width: {
    tiny: 350,
    small: 450,
    medium: 600,
    large: 800,
    larger: 950,
    full: 1150,
    high: 1350,
    higher: 1350,
    cinema: 1800,
  },
  height: {
    tiny: 300,
    small: 400,
    medium: 600,
    large: 800,
    larger: 900,
    full: 1000,
    high: 1100,
    cinema: 1200,
  },
}

let lastViewportHeight = 0

// state.mobileSafeHeight will only be updated when height changes are above the threshhold.
// This is to prevent resize event being triggered when mobile browser bars scroll in/out.
const VIEWPORT_HEIGHT_CHANGE_THRESHOLD = 120

function getWindowProperties() {
  const windowSize = {
    width: process.browser ? window.innerWidth : MEDIA_BREAKPOINTS.width.high,
    height: process.browser ? window.innerHeight : MEDIA_BREAKPOINTS.height.larger,
  }
  windowSize.ratio = windowSize.width / windowSize.height
  windowSize.dimensions = getDimensions(windowSize.width, windowSize.height)

  return windowSize
}

function getDimensions(width, height) {
  return `${width}x${height}`
}

export default {
  namespaced: true,
  state: () => {
    const winProps = getWindowProperties()
    return {
      ...winProps,
      mobileSafeHeight: winProps.height,
      mobileSafeDimensions: winProps.dimensions,
    }
  },

  mutations: {
    setSize(state) {
      const size = getWindowProperties()
      for (const key in size) state[key] = size[key]

      const viewportHeightChange = Math.abs(lastViewportHeight - size.height)
      if (viewportHeightChange > VIEWPORT_HEIGHT_CHANGE_THRESHOLD) {
        lastViewportHeight = size.height
        state.mobileSafeHeight = size.height
        state.mobileSafeDimensions = size.dimensions
      }
      state.mobileSafeDimensions = getDimensions(size.width, state.mobileSafeHeight)
    },
  },

  actions: {
    resetViewportStoreState({ commit }) {
      commit('setSize')
    },
  },

  getters: {
    width: state => state.width,
    height: state => state.height,
    dimensions: state => state.dimensions,
    isMqMicro: state => state.width < MEDIA_BREAKPOINTS.width.tiny,
    isMqTiny: state => state.width >= MEDIA_BREAKPOINTS.width.tiny,
    isMqSmall: state => state.width >= MEDIA_BREAKPOINTS.width.small,
    isMqMedium: state => state.width >= MEDIA_BREAKPOINTS.width.medium,
    isMqLarge: state => state.width >= MEDIA_BREAKPOINTS.width.large,
    isMqLarger: state => state.width >= MEDIA_BREAKPOINTS.width.larger,
    isMqFull: state => state.width >= MEDIA_BREAKPOINTS.width.full,
    isMqHigh: state => state.width >= MEDIA_BREAKPOINTS.width.high,
    isMqHigher: state => state.width >= MEDIA_BREAKPOINTS.width.higher,
    isMqCinema: state => state.width >= MEDIA_BREAKPOINTS.width.cinema,
  },
}

if (process.browser) {
  window.addEventListener('resize', () => {
    debouncer.debounce(100, () => window.$nuxt?.$store.dispatch('viewport/resetViewportStoreState'))
  })
}
