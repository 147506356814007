import {
  hasStoredCookieConsentSettings,
  COOKIE_CATEGORIES,
  onCookieConsent,
} from 'acces-impot-utils/lib/cookie/consent'
import { gtag, fbq } from '@/services/tracking'
import { waitFor } from '@/helpers/time'

export default context => {
  if (typeof window === 'undefined') return

  const { store, app } = context
  const hasStored = hasStoredCookieConsentSettings()

  if (!hasStored) {
    waitFor(store, state => state.page.isVisible).then(() => {
      setTimeout(
        () => store.dispatch('overlay/showOverlayCookieConsent', { isForcingToAnswer: true }),
        700
      )
    })
  }

  onCookieConsent(categories => {
    try {
      const hasAdvertisingConsent = categories.includes(COOKIE_CATEGORIES.advertising)
      const hasAnalyticsConsent = categories.includes(COOKIE_CATEGORIES.analytics)
      const getGtagConsentValue = hasContent => (hasContent ? 'granted' : 'denied')

      gtag('consent', 'update', {
        ad_storage: getGtagConsentValue(hasAdvertisingConsent),
        analytics_storage: getGtagConsentValue(hasAnalyticsConsent),
      })

      fbq('consent', hasAdvertisingConsent ? 'grant' : 'revoke')
    } catch (err) {
      app.$sentry.captureException(err)
    }
  })
}
