import { ADMIN_ROLES } from 'acces-impot-settings-user'
import { ADMIN_STATUSES } from 'acces-impot-settings-report/constants/admin'

export const ADMIN_DETAILS_ROUTE_QUERY = {
  id: 'id',
  person: 'person',
  focus: 'focus',
}

export const ADMIN_DETAILS_SECTIONS = {
  people: 'people',
  answers: 'answers',
  documents: 'documents',
}

export const ADMIN_DETAILS_DATA_TYPE_BY_SECTION = {
  [ADMIN_DETAILS_SECTIONS.people]: 'reportPeople',
  [ADMIN_DETAILS_SECTIONS.answers]: 'reportAnswers',
  [ADMIN_DETAILS_SECTIONS.documents]: 'reportDocuments',
}

export const ADMIN_STATUS_MAIN_FILTER_MAP = {
  [ADMIN_ROLES.producer]: {
    pending: [ADMIN_STATUSES.toPrepare, ADMIN_STATUSES.pending, ADMIN_STATUSES.toSend],
  },
}
