<template>
  <button class="hamburger" type="button" v-on="$listeners">
    <span class="hamburger__box">
      <span
        class="hamburger__inner"
        :class="{
          'hamburger__inner--close': isActive && !isBackArrow,
          'hamburger__inner--arrow': isActive && isBackArrow,
        }"
      />
    </span>
  </button>
</template>

<script>
export default {
  name: 'Hamburger',

  props: {
    isActive: {
      type: Boolean,
      default: false,
    },

    isBackArrow: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
@import './header-settings';

$width: 22px;
$height: 17px;
$line-width: 1.2px;
$line-space-between: round(($height - $line-width * 3) / 2);

.hamburger {
  @include fake-padding-link($hamburger-padding-x, $hamburger-padding-y);
  display: inline-block;
  overflow: visible;
  background-color: transparent;
  margin: 0;
  padding: 0;
  border: 0;
  color: inherit;
  font-size: 0;
  text-transform: none;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;

  &:hover {
    opacity: 0.8;
  }

  &__box {
    display: inline-block;
    position: relative;
    width: $width;
    height: $height;
  }

  &__inner {
    display: block;
    top: 50%;
    margin-top: -2px;
    transition-duration: 0.075s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);

    &,
    &:before,
    &:after {
      position: absolute;
      width: $width;
      height: $line-width;
      background-color: currentColor;
      border-radius: floor($line-width / 2);
      transition-property: transform;
      transition-duration: 0.15s;
      transition-timing-function: ease;
    }

    &:before,
    &:after {
      content: '';
      display: block;
    }

    &:before {
      top: -$line-space-between;
      transition: top 0.075s 0.12s ease, opacity 0.075s ease,
        transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }

    &:after {
      bottom: -$line-space-between;
      transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }

    &--close {
      transform: rotate(45deg);
      transition-delay: 0.12s;
      transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);

      &:before {
        top: 0;
        opacity: 0;
        transition: top 0.075s ease, opacity 0.075s 0.12s ease;
      }

      &:after {
        bottom: 0;
        transform: rotate(-90deg);
        transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
      }
    }

    &--arrow {
      &:before {
        transform: translate(-6px, 2px) rotate(-45deg) scale(0.65, 1);
      }

      &:after {
        transform: translate(-6px, -3px) rotate(45deg) scale(0.65, 1);
      }
    }
  }
}
</style>
