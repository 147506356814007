export const FETCH_POLICY = {
  cacheFirst: 'cache-first',
  cacheAndNetwork: 'cache-and-network',
  networkOnly: 'network-only',
  cacheOnly: 'cache-only',
  noCache: 'no-cache',
}

export function getApiClient(appOrVm) {
  const apolloProvider =
    appOrVm?.$apolloProvider ||
    appOrVm?.apolloProvider ||
    (typeof window !== 'undefined' && window.$nuxt?.$apolloProvider)

  return apolloProvider?.defaultClient
}
