<template>
  <div class="checkboxes">
    <Checkbox
      v-for="(label, modelKey, index) in renderedLabels"
      :id="getId(modelKey)"
      :key="getId(modelKey)"
      :model="models[modelKey]"
      :label="label"
      :hasRedBorder="hasError || checkboxIndexWithRedBorder === index"
      :isDisabled="isDisabled"
      :isFakeInput="isFakeInput"
      :isEditable="isEditable"
      :hasBigCheck="highlightedCheckboxIndex === index"
      :hasGreenBackground="highlightedCheckboxIndex === index"
      :isShownAsChecked="highlightedCheckboxIndex === index"
      @update:model="updateModel($event, modelKey)"
    >
      <VnodeRenderer v-if="$slots.default && $slots.default[0]" :vnode="$slots.default[index]" />
    </Checkbox>
    <FieldErrorMessage class="checkboxes__error" :errors="errors" />
  </div>
</template>

<script>
import cloneDeep from 'lodash.clonedeep'
import Checkbox from '@/components/Form/Checkbox.vue'
import FieldErrorMessage from '@/components/Form/FieldErrorMessage.vue'
import {
  props,
  getValidationData,
  computed,
  watch,
  validate,
} from '@/components/Form/field-settings'

export default {
  name: 'Checkboxes',
  components: {
    Checkbox,
    FieldErrorMessage,
    VnodeRenderer: {
      functional: true,
      render: (_h, ctx) => ctx.props.vnode,
    },
  },

  props: {
    ...props,

    labels: {
      type: Object,
      required: true,
    },

    notApplicableKey: {
      type: String,
    },

    isShowingCheckedOnly: {
      type: Boolean,
      default: false,
    },

    isShowingCheckedOnly: {
      type: Boolean,
      default: false,
    },

    hasOnlyOneChecked: {
      type: Boolean,
      default: false,
    },

    highlightedCheckboxIndex: {
      type: Number,
    },

    checkboxIndexWithRedBorder: {
      type: Number,
    },
  },

  data() {
    return {
      ...getValidationData(),
    }
  },

  computed: {
    ...computed,

    renderedLabels() {
      if (!this.isShowingCheckedOnly) return this.labels

      const renderedLabels = {}
      Object.entries(this.labels).forEach(([key, value]) => {
        if (this.models[key]) renderedLabels[key] = value
      })
      return renderedLabels
    },

    models() {
      const models = {}
      for (const modelKey in this.labels) models[modelKey] = false

      const model = this.model || ''
      model.split(',').forEach(modelKey => {
        if (modelKey && this.labels[modelKey]) models[modelKey] = true
      })
      return models
    },
  },

  watch,

  methods: {
    validate,

    getId(modelKey) {
      return `${this.id}-${modelKey}`
    },

    stringifyModels(models) {
      return Object.keys(models)
        .filter(modelKey => !!models[modelKey])
        .join(',')
    },

    updateModel(model, modelKey) {
      let models = {}

      if (!this.hasOnlyOneChecked && modelKey !== this.notApplicableKey) {
        models = cloneDeep(this.models)
        if (this.labels[this.notApplicableKey]) models[this.notApplicableKey] = false
      }
      models[modelKey] = model
      this.$emit('update:model', this.stringifyModels(models))
    },
  },
}
</script>

<style lang="scss" scoped>
.checkboxes {
  &__error {
    margin-top: 10px;
  }
}
</style>
