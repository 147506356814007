const { DOCUMENT_TYPES } = require('acces-impot-utils/lib/report/constants')

const DOCUMENT_ID_REGEX = /^[a-z][A-Za-z0-9_-]*__\d+$/

const SPOUSE_OR_CHILD_SUPPORT_TYPES = {
  childSupportReceived: 'childSupportReceived',
  childSupportPaid: 'childSupportPaid',
  spouseSupportReceived: 'spouseSupportReceived',
  spouseSupportPaid: 'spouseSupportPaid',
}

const REPORT_DOCUMENT_MODELS_MAP = {
  'report-document': { field: 'documents' },
  'report-post-document': { field: 'document' },
}
const REPORT_DOCUMENT_MODELS = Object.keys(REPORT_DOCUMENT_MODELS_MAP)

const POST_DOCUMENT_STATUSES = {
  waitingForApproval: 'waitingForApproval',
  waitingForSignatures: 'waitingForSignatures',
  approved: 'approved',
  signed: 'signed',
}

module.exports = {
  DOCUMENT_ID_REGEX,
  DOCUMENT_TYPES,
  SPOUSE_OR_CHILD_SUPPORT_TYPES,
  REPORT_DOCUMENT_MODELS_MAP,
  REPORT_DOCUMENT_MODELS,
  POST_DOCUMENT_STATUSES,
}
