<template>
  <div v-if="errors.length" class="field-error-message">
    <p v-for="(msg, index) in errors" :key="index">
      {{ msg }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'FieldErrorMessage',

  props: {
    errors: {
      type: Array,
      default: () => [],
      validator(array) {
        return array.every(v => typeof v === 'string')
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.field-error-message {
  line-height: $line-height-normal;
  font-size: between($font-size-small, $font-size-smaller);
  color: $color-red;
}
</style>
