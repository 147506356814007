export const EMPTY_VALUE_CHAR = '-'

export function generateSocialMetaTags({ title, description, image }) {
  return [
    ...(title ? generateSocialTitleMetaTags(title) : []),
    ...(description ? generateDescriptionMetaTags(description) : []),
    ...(image ? generateSocialImageMetaTags(image) : []),
  ]
}

function generateSocialTitleMetaTags(title) {
  return [
    {
      hid: 'og:title',
      property: 'og:title',
      content: title,
    },
  ]
}

function generateDescriptionMetaTags(description) {
  return [
    {
      hid: 'description',
      name: 'description',
      content: description,
    },
    {
      hid: 'og:description',
      property: 'og:description',
      content: description,
    },
  ]
}

function generateSocialImageMetaTags(image) {
  return [
    {
      hid: 'og:image',
      property: 'og:image',
      content: image,
    },
  ]
}

export function renderValue(value) {
  return value || EMPTY_VALUE_CHAR
}

export function renderAmount(amount) {
  if (!amount) return EMPTY_VALUE_CHAR

  const sign = amount > 0 ? '+' : '-'
  const formattedAmount = Math.abs(amount).toLocaleString('fr-CA', {
    style: 'currency',
    currency: 'CAD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })
  return `${sign}<span class="u-half-font-size"> </span>${formattedAmount}`
}

export function renderDate(dateString, locale) {
  const dateMatch = dateString?.match(/^(\d{4})-(\d{2})-(\d{2})/)
  if (!dateMatch) return ''

  const dateSplit = [dateMatch[1], dateMatch[2], dateMatch[3]]
  dateSplit[1] = Number(dateSplit[1]) - 1
  return new Date(Date.UTC(...dateSplit, 12, 30, 30, 30)).toLocaleDateString(locale, {
    timeZone: 'UTC',
  })
}

export function getTodaysDateString() {
  const make2Digits = n => (String(n).length === 1 ? `0${n}` : n)
  const date = new Date()
  const year = date.getFullYear()
  const month = make2Digits(date.getMonth() + 1)
  const day = make2Digits(date.getDate())

  return `${year}-${month}-${day}`
}

export function renderDatetime(date, locale) {
  if (!date) return EMPTY_VALUE_CHAR

  if (typeof date === 'string') date = new Date(date)
  const dateString = date.toLocaleDateString(locale)
  const timeString = date.toLocaleTimeString(locale, {
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
  })
  return `${dateString} ${timeString}`
}

export function renderBoolean(boolean) {
  return boolean === null ? EMPTY_VALUE_CHAR : this.$t(`globals.${boolean ? 'yes' : 'no'}`)
}
