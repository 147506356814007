import Vue from 'vue'
import MultiRefDirective from '@/directives/multi-ref'
import DataTestIdDirective from '@/directives/data-test-id'

Vue.directive('data-test-id', DataTestIdDirective)
Vue.directive('multi-ref', MultiRefDirective)

const SW_FIX_KEY = 'multiple-sw-fix'

function fixMultipleSwRegistrations() {
  if (!process.browser || Number(localStorage.getItem(SW_FIX_KEY)) === 1) return

  window.addEventListener('load', async () => {
    if (!navigator.serviceWorker) return

    const registrations = await navigator.serviceWorker.getRegistrations()
    if (registrations.length > 1) {
      await Promise.all(registrations.map(r => r.unregister()))
      const cacheKeys = await window.caches.keys()
      await Promise.all(cacheKeys.map(k => window.caches.delete(k)))
      localStorage.setItem(SW_FIX_KEY, '1')
      window.location.reload()
    }
  })
}

function registerSw() {
  if (process.browser && location.protocol === 'https:' && navigator.serviceWorker) {
    try {
      navigator.serviceWorker.register('/sw.js')
    } catch (err) {}
  }
}

export default () => {
  registerSw()
  fixMultipleSwRegistrations()
}
