import modelSettings from 'acces-impot-settings-report'
import { removeFromArray } from '@/helpers/array'
import {
  STEP_KEYS,
  STEP_DATA,
  PERSON_TYPES,
  DEFAULT_FAMILY_MEMBERS,
  DEFAULT_FAMILY_MEMBERS_WITH_REPORT,
} from './report-settings'

export function getInitialState() {
  return {
    visitedReportYears: [],
    isCurrentReportSet: false,
    isReportDataUpdating: false,
    currentReportYear: new Date().getFullYear() - 1,
    reports: [],
    ...getInitialReportDataState(),
  }
}

export function getInitialReportDataState() {
  return {
    componentSettings: getInitialComponentSettings(),
    editedComponentIndex: 0,
    highestComponentIndexReached: 0,
    reportData: {
      [STEP_KEYS.personalDetails]: {},
      [STEP_KEYS.relevantQuestions]: {},
      [STEP_KEYS.documents]: {},
      [STEP_KEYS.payment]: {},
      [STEP_KEYS.missingDocumentsAfterPurchase]: {},
    },
    cachedReportData: {
      [STEP_KEYS.personalDetails]: {},
      [STEP_KEYS.relevantQuestions]: {},
      [STEP_KEYS.documents]: {},
      [STEP_KEYS.payment]: {},
      [STEP_KEYS.missingDocumentsAfterPurchase]: {},
    },
    familyMembers: DEFAULT_FAMILY_MEMBERS,
    familyMembersWithReport: DEFAULT_FAMILY_MEMBERS_WITH_REPORT,
    componentCountSettings: getComponentCountSettings(
      DEFAULT_FAMILY_MEMBERS,
      DEFAULT_FAMILY_MEMBERS_WITH_REPORT,
      {}
    ),
    parentNames: {
      [PERSON_TYPES.self]: '',
      [PERSON_TYPES.partner]: '',
    },
    completedQuestionCategories: {},
    documentTypesToUpload: {},
    documentReasonsToAsk: {},
    providedDocuments: {},
    providedMissingDocumentsAfterPurchase: {},
    missingDocumentsAfterPurchase: {},
    openMissingDocsTimestamps: {},
    hasMissingDocumentsAfterPurchase: false,
    hasSubmittedWithMissingDocs: {},
    hasSubmittedWithMissingDocsAfterPurchase: {},
    firstIncompleteDocumentsFormIndex: {},
    paymentCompletedAt: null,
  }
}

function getInitialComponentSettings() {
  const stepKey = STEP_KEYS.personalDetails
  const personType = PERSON_TYPES.self
  const personIndex = 0
  const personKey = getPersonKey(PERSON_TYPES.self, personIndex)
  const componentKey = getComponentKey(stepKey, personKey)

  return {
    [componentKey]: {
      name: STEP_DATA[stepKey].name,
      personType,
      personKey,
      personIndex,
      componentKey,
      stepKey,
      stepChildIndex: 0,
    },
  }
}

export function getComponentCountSettings(
  familyMembers,
  familyMembersWithReport,
  missingDocumentsAfterPurchase
) {
  const getSettings = obj => {
    let count = 0
    const personKeys = []

    Object.entries(obj).forEach(([personType, values]) => {
      const indexes = typeof values === 'number' ? [...Array(values)].map((_, i) => i) : values
      indexes.forEach(personIndex => {
        const personKey = getPersonKey(personType, personIndex)
        personKeys.push(personKey)
      })
      count += indexes.length
    })
    return { count, personKeys }
  }
  const familyMemberSettings = getSettings(familyMembers)
  const familyMemberWithReportSettings = getSettings(familyMembersWithReport)
  const selfOnlySettings = { count: 1, personKeys: [getPersonKey(PERSON_TYPES.self)] }

  const missingDocumentsSettings = { count: 0, personKeys: [] }

  Object.entries(missingDocumentsAfterPurchase).forEach(([personKey, infoSettings = {}]) => {
    for (const timestamp in infoSettings) {
      const { docs = [], infos = [] } = infoSettings[timestamp] || {}
      if (!docs.length && !infos.length) return

      missingDocumentsSettings.count++
      missingDocumentsSettings.personKeys.push(personKey)
    }
  })

  return {
    [STEP_KEYS.personalDetails]: familyMemberSettings,
    [STEP_KEYS.relevantQuestions]: familyMemberWithReportSettings,
    [STEP_KEYS.documents]: familyMemberWithReportSettings,
    [STEP_KEYS.payment]: selfOnlySettings,
    [STEP_KEYS.missingDocumentsAfterPurchase]: missingDocumentsSettings,
  }
}

export function getComponentKey(stepKey, personKey) {
  return `${stepKey}${personKey}`
}

export function getStepKey(state, _getters, index) {
  let stepKey = STEP_KEYS.missingDocumentsAfterPurchase
  let count = 0

  for (const key in STEP_DATA) {
    count += state.componentCountSettings[key].count
    if (index < count) {
      stepKey = key
      break
    }
  }
  return stepKey
}

export function getPersonKey(personType, personIndex = 0) {
  return `${personType}${personIndex}`
}

export function getIndexFromPersonKey(personKey) {
  const indexMatch = personKey.match(/(\d+)$/)
  return indexMatch ? Number(indexMatch[1]) : 0
}

export function getTypeFromPersonKey(personKey) {
  const match = personKey.match(new RegExp(`(${Object.values(PERSON_TYPES).join('|')})`))
  return match ? match[0] : ''
}

export function getNumberOfChildren(reportData) {
  const details = reportData[STEP_KEYS.personalDetails]
  const self = details[getPersonKey(PERSON_TYPES.self)]
  const partner = self?.hasPartner ? details[getPersonKey(PERSON_TYPES.partner)] : undefined
  const getNumberOfChildrenOf = person => (person?.numberOfChildren ? person.numberOfChildren : 0)

  const selfNumberOfChildren = getNumberOfChildrenOf(self)
  const partnerNumberOfChildren = getNumberOfChildrenOf(partner)
  const maxPossibleChildren = selfNumberOfChildren + partnerNumberOfChildren
  const minPossibleChildren = Math.max(selfNumberOfChildren, partnerNumberOfChildren)
  let numberOfChildren = 0
  let remainingCount = maxPossibleChildren

  Object.values(details).forEach(person => {
    if (person.personType === PERSON_TYPES.child) {
      const parents = person.parents ? person.parents.split(',').filter(n => n) : ['']
      parents.forEach(() => remainingCount--)
      if (remainingCount >= 0) numberOfChildren++
    }
  })
  if (remainingCount > 0) numberOfChildren += remainingCount

  return Math.min(Math.max(numberOfChildren, minPossibleChildren), maxPossibleChildren)
}

export function getParentNames(personalDetailsData) {
  const selfReportData = personalDetailsData[getPersonKey(PERSON_TYPES.self)] || {}
  const partnerReportData = personalDetailsData[getPersonKey(PERSON_TYPES.partner)] || {}
  const getName = data => [data.firstName, data.lastName].filter(n => n).join(' ')

  return {
    [PERSON_TYPES.self]: selfReportData.numberOfChildren ? getName(selfReportData) : '',
    [PERSON_TYPES.partner]:
      selfReportData.hasPartner &&
      (!partnerReportData.hasTaxReport || partnerReportData.numberOfChildren)
        ? getName(partnerReportData)
        : '',
  }
}

export function getFamilyMembersWithReport(personalDetailsData) {
  const membersWithReport = {}
  Object.values(PERSON_TYPES).forEach(personType => (membersWithReport[personType] = []))

  Object.entries(personalDetailsData).forEach(([personKey, person]) => {
    if (person.personType === PERSON_TYPES.self || person.hasTaxReport) {
      const personIndex = getIndexFromPersonKey(personKey)
      membersWithReport[person.personType].push(personIndex)
    }
  })
  return membersWithReport
}

export function getFirstIncompleteComponentIndex(context) {
  return context.getters.sortedComponentSettings.findIndex(componentSettings => {
    const { stepKey, personKey } = componentSettings
    const reportData = context.state.reportData[stepKey][personKey]
    const isPaid = !!context.state.paymentCompletedAt

    if (isPaid && stepKey !== STEP_KEYS.missingDocumentsAfterPurchase) return false

    if (reportData && stepKey === STEP_KEYS.relevantQuestions) {
      const numberOfChildren = context.getters.personalDetailsData[personKey].numberOfChildren || 0
      let categoriesToCheck = Object.values(modelSettings.questionCategories)

      if (!numberOfChildren) {
        categoriesToCheck = removeFromArray(
          categoriesToCheck,
          modelSettings.questionCategories.children
        )
      }
      return !categoriesToCheck.every(c => context.state.completedQuestionCategories[personKey][c])
    } else if (stepKey === STEP_KEYS.documents) {
      const firstIncompleteIndex = context.state.firstIncompleteDocumentsFormIndex[personKey]
      return typeof firstIncompleteIndex === 'undefined' || firstIncompleteIndex >= 0
    } else if (stepKey === STEP_KEYS.missingDocumentsAfterPurchase) {
      return typeof context.state.openMissingDocsTimestamps[personKey] !== 'undefined'
    }
    return !reportData
  })
}

export function formatReportData(stepKey, reportData) {
  const shouldFormat = value => value || (typeof value !== 'string' && typeof value !== 'undefined')
  const makeBoolean = str => (shouldFormat(str) ? Boolean(Number(str)) : str || undefined)
  const makeNumber = str => (shouldFormat(str) ? Number(str) : str || undefined)
  const makeDate = str => str || null

  const formaters = {
    [STEP_KEYS.personalDetails]: data => {
      data.previousCivilStatusDate = makeDate(data.previousCivilStatusDate)
      data.livesAlone = makeBoolean(data.livesAlone)
      data.hasPartner = makeBoolean(data.hasPartner)
      data.numberOfChildren = makeNumber(data.numberOfChildren)
      data.isCanadianCitizen = makeBoolean(data.isCanadianCitizen)
      data.nonCanadianArrivalDate = makeDate(data.nonCanadianArrivalDate)
      data.nonCanadianIncomeOutsideCanada = makeBoolean(data.nonCanadianIncomeOutsideCanada)
      data.nonCanadianFirstReport = makeBoolean(data.nonCanadianFirstReport)
      data.hasTaxReport = makeBoolean(data.hasTaxReport)

      if (makeBoolean(data.hasAdditionalName) === false) data.additionalName = ''

      delete data.hasAdditionalName

      return data
    },
    [STEP_KEYS.relevantQuestions]: data => {
      const ifExists = (key, action) => {
        if (key in data) data[key] = action(data[key])
      }
      ifExists('drugInsuranceSameSituation', makeBoolean)
      ifExists('drugInsurancePreviousSituationDate', makeDate)
      ifExists('incomeSelfEmployed', makeBoolean)
      ifExists('incomeSelfEmployedWithExpenses', makeBoolean)
      ifExists('incomeSelfEmployedInstallmentPayments', makeBoolean)
      ifExists('rrspLast60Days', makeBoolean)
      ifExists('rrspFhsaContribution', makeBoolean)
      ifExists('rrspWithdrawOtherSituation', makeBoolean)
      ifExists('childrenChildcareExpenses', makeBoolean)
      ifExists('childrenAdvancePayments', makeBoolean)
      ifExists('childrenActivityCosts', makeBoolean)
      ifExists('studentWereYou', makeBoolean)
      ifExists('studentScholarships', makeBoolean)
      ifExists('studentTuitonFeesToParent', makeBoolean)
      ifExists('studentWithdrawFromResp', makeBoolean)
      ifExists('studentRepaidInterestOnLoan', makeBoolean)
      ifExists('taxCreditsHomeSupportAdvancePayments', makeBoolean)
      ifExists('taxCreditsWholeYearSameAddress', makeBoolean)
      ifExists('taxCreditsStayedInRemoteAreaAllYear', makeBoolean)
      ifExists('taxCreditsBeginningOfStay', makeDate)
      ifExists('taxCreditsEndOfStay', makeDate)
      ifExists('taxCreditsMovingCloserToWork', makeBoolean)
      ifExists('telecommuting', makeBoolean)
      ifExists('medicalFees', makeBoolean)
      ifExists('donations', makeBoolean)
      ifExists('propertyOutsideCanada', makeBoolean)
      ifExists('associationContribution', makeBoolean)
      ifExists('spouseOrChildSupportPayments', makeBoolean)
      ifExists('buyFirstHome', makeBoolean)
      ifExists('sellPrincipalResidence', makeBoolean)
      ifExists('sellOtherResidence', makeBoolean)
      ifExists('financialAdvisor', makeBoolean)

      return data
    },
  }
  return formaters[stepKey] ? formaters[stepKey](reportData) : reportData
}

export function formatPersonalDetailsFromApi(data, isForcingAddress = false) {
  const address =
    !isForcingAddress &&
    data.personType !== PERSON_TYPES.self &&
    (data.hasSameAddress || data.livesOutsideCanada)
      ? {}
      : data.address

  const formattedData = { ...(address || {}), ...data }
  delete formattedData.address

  return formattedData
}

export function isTheOnlyParentOfSomeChild(personType, personalDetailsData) {
  return Object.values(personalDetailsData).some(data => {
    return data.personType === PERSON_TYPES.child && data.parents === personType
  })
}
