<template>
  <a :href="url" @click.prevent="downloadFile">
    <slot />
  </a>
</template>

<script>
export default {
  name: 'LinkDownload',

  props: {
    filename: {
      type: String,
      required: true,
    },

    url: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      isLoading: false,
      objectUrl: '',
    }
  },

  beforeDestroy() {
    URL.revokeObjectURL(this.objectUrl)
  },

  methods: {
    async downloadFile() {
      if (this.isLoading) return

      this.isLoading = true
      const objectUrl = await this.getObjectUrl()

      const link = document.createElement('a')
      link.href = objectUrl || this.url
      link.download = this.filename
      if (!objectUrl) link.target = '_blank'

      // Some browsers need the anchor to be in the document
      document.body.append(link)
      link.click()
      link.remove()
      this.isLoading = false
    },

    async getObjectUrl() {
      if (!this.objectUrl) {
        try {
          const blob = await fetch(this.url).then(response => response.blob())
          this.objectUrl = URL.createObjectURL(blob)
        } catch (error) {
          this.$sentry.captureException(error)
        }
      }
      return this.objectUrl
    },
  },
}
</script>
