<template>
  <FieldWrapper class="select" :class="{ 'select--error': hasError }">
    <div
      class="select__select-wrap"
      :class="{ 'select__select-wrap--error': hasError || hasRedBorder }"
    >
      <!-- eslint-disable vue/html-indent -->
      <!-- eslint-disable vue/html-closing-bracket-newline -->
      <label
        v-if="label"
        class="select__label"
        :class="{
          'select__label--is-placeholder': hasPlaceholder && !isComputedDisabled,
          'select__label--disabled': isComputedDisabled,
          'select__label--not-editable': !isEditable,
          'select__label--hidden': selectValue && isHidingLabelOnValue,
          'select__label--dark': hasDarkColor,
        }"
        :for="id"
        >{{ label }}</label
      >
      <!-- eslint-enable vue/html-indent -->
      <!-- eslint-enable vue/html-closing-bracket-newline -->
      <IconChevronDown v-show="!isComputedDisabled" class="select__select-arrow" />
      <select
        :id="id"
        ref="field"
        :key="selectKey"
        class="select__field select__field--select"
        :class="{
          'select__field--no-label': !label || (selectValue && isHidingLabelOnValue),
          'select__field--not-editable': !isEditable,
          'select__field--dark': hasDarkColor,
        }"
        :value="selectValue"
        :autocomplete="autocomplete"
        :disabled="isComputedDisabled"
        v-on="eventHandlers"
        @change="updateModel"
        @animationstart="onAnimationStart"
      >
        <!-- display: inline -->
        <option v-for="(option, index) in options" :key="index" :value="option.value">
          {{ option.text }}
        </option>
      </select>
    </div>
    <slot />
    <FieldErrorMessage :errors="errors" />
  </FieldWrapper>
</template>

<script>
import IconChevronDown from '@/components/_icons/IconChevronDown.svg'
import FieldWrapper from './FieldWrapper.vue'
import FieldErrorMessage from './FieldErrorMessage.vue'
import { selectProps, getSelectData, computed, watch, methods } from './field-settings'

export default {
  name: 'Select',
  components: {
    FieldWrapper,
    FieldErrorMessage,
    IconChevronDown,
  },

  props: selectProps,

  data() {
    return {
      ...getSelectData(),
    }
  },

  computed: {
    ...computed,

    selectValue() {
      // eslint-disable-next-line eqeqeq
      return this.options.some(opt => opt.value == this.model) ? this.model : undefined
    },
  },

  watch,

  methods: {
    ...methods,

    getSelectElement() {
      return this.$refs.field
    },
  },
}
</script>

<style scoped lang="scss">
@import './field-settings';

$select-arrow-width: 12px;
$select-arrow-height: 10px;

@include autofill-keyframes;

.select {
  &__label {
    @include label-style;
  }

  &__field {
    @include field-style;
    position: relative;
    background-color: transparentize($color-white, 1);
    border: 0;
    z-index: 2;
  }

  &__select-arrow {
    position: absolute;
    top: calc(50% - #{floor($select-arrow-height / 2) - 1});
    right: 15px;
    width: $select-arrow-width;
    height: $select-arrow-height;
    stroke: $label-color;
    stroke-width: 1.25;
    stroke-linecap: round;
    z-index: 1;
  }

  &__select-wrap {
    position: relative;
    background-color: $color-white;
    border: 1px solid $color-border;
    border-radius: $border-radius;

    &--error {
      @include field-error;
    }
  }
}
</style>
