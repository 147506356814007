<template>
  <component
    :is="component"
    class="link"
    :class="{
      'link--styled': hasStyle,
      'link--no-wrap': isNoWrap,
    }"
    :to="to"
    :handleClick="handleClick"
    v-on="$listeners"
  >
    <slot />
  </component>
</template>

<script>
import NuxtLinkExtra from './NuxtLinkExtra.vue'

export default {
  name: 'Link',
  components: {
    NuxtLinkExtra,
  },

  props: {
    to: {
      type: [String, Object],
    },

    handleClick: {
      type: Function,
    },

    isNoWrap: {
      type: Boolean,
      default: false,
    },

    hasStyle: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    component() {
      return !this.to ? 'a' : 'NuxtLinkExtra'
    },
  },
}
</script>

<style lang="scss" scoped>
@import './link-settings';

.link {
  &--styled {
    @include link-styles;
  }

  &--no-wrap {
    white-space: nowrap;
  }
}
</style>
