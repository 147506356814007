const {
  ADMIN_STATUSES,
  ADMIN_STATUSES_BY_ROLE,
  ADMIN_STATUS_CHANGE_MAP,
  ADMIN_STATUS_OTHER_CHANGE_MAP,
  ADMIN_STATUS_PATHS,
  ADMIN_ACTIVITY_TYPES,
  SEARCH_FILTERS,
} = require('acces-impot-utils/lib/report')

module.exports = {
  ADMIN_STATUSES,
  ADMIN_STATUSES_BY_ROLE,
  ADMIN_STATUS_CHANGE_MAP,
  ADMIN_STATUS_OTHER_CHANGE_MAP,
  ADMIN_STATUS_PATHS,
  ADMIN_ACTIVITY_TYPES,
  SEARCH_FILTERS,
}
