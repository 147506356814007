import { ADMIN_ROLES } from 'acces-impot-settings-user'
import { genders as GENDERS } from 'acces-impot-settings-report'
import {
  PERSON_TYPES,
  formatMissingDocumentsAfterPurchase,
  getOpenMissingDocsRequestKey,
} from 'acces-impot-utils/lib/report'
import { ADMIN_STATUS_CHANGE_MAP } from 'acces-impot-settings-report/constants/admin'

const LAST_VISITED_REPORTS_STORAGE_KEY = 'acces.lastVisitedReportsPage'

function getLastVisitedReportsFromStorage() {
  if (process.server) return null

  const value = localStorage.getItem(LAST_VISITED_REPORTS_STORAGE_KEY)
  try {
    return JSON.parse(value)
  } catch (e) {
    return null
  }
}

export default {
  namespaced: true,
  state: () => ({
    currentReport: null,
    lastVisitedReportsPage: getLastVisitedReportsFromStorage(),
  }),

  mutations: {
    setCurrentReport(state, report) {
      state.currentReport = report
    },

    resetLastVisitedReportsPage(state) {
      state.lastVisitedReportsPage = getLastVisitedReportsFromStorage()
    },

    setLastVisitedReportsPage(state, route) {
      if (typeof localStorage !== 'undefined') {
        localStorage.setItem(LAST_VISITED_REPORTS_STORAGE_KEY, JSON.stringify(route))
      }
      state.lastVisitedReportsPage = route
    },
  },

  getters: {
    possibleAdminStatusChanges: (state, _getters, _rootState, rootGetters) => {
      const statusMap = ADMIN_STATUS_CHANGE_MAP[rootGetters['account/accurateRole']] || {}
      const currentReport = state.currentReport || {}
      const statuses = statusMap[currentReport.adminStatus] || []

      return statuses
    },

    assignedAdminInfo(state) {
      const report = state.currentReport || {}
      return {
        [ADMIN_ROLES.producer]: report.assignedProducer,
        [ADMIN_ROLES.manager]: report.assignedManager,
      }
    },

    selfGender(state) {
      return (
        state.currentReport?.reportPeople.find(p => p.personType === PERSON_TYPES.self)?.gender ||
        GENDERS.male
      )
    },

    customerLanguage(state) {
      return state.currentReport?.reportOrders?.[0].language || ''
    },

    missingDocumentsAfterPurchase(state) {
      const formatted = {}
      state.currentReport?.reportDocuments?.forEach(reportDoc => {
        formatted[reportDoc.personKey] = formatMissingDocumentsAfterPurchase(
          reportDoc.missingDocuments
        )
      })
      return formatted
    },

    openMissingDocsTimestamps(state, getters) {
      const timestampsByPersonKey = {}

      for (const personKey in getters.missingDocumentsAfterPurchase) {
        const reportDocument = state.currentReport?.reportDocuments.find(
          reportDoc => reportDoc.personKey === personKey
        )
        if (!reportDocument) continue

        const openMissingDocsTimestamp = getOpenMissingDocsRequestKey(
          reportDocument.missingInfosDocsSubmittedAt,
          getters.missingDocumentsAfterPurchase[personKey]
        )
        timestampsByPersonKey[personKey] = openMissingDocsTimestamp
      }
      return timestampsByPersonKey
    },
  },
}
