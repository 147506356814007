<template>
  <div>
    <Accordion :isOpen="hasExpenses">
      <InfoPanel :class="$style.panel" :theme="INFO_PANEL_THEMES.info">
        {{ $t('infoHasExpenses') }}

        <div>
          <LinkDownload
            :class="$style.downloadButton"
            :filename="$t('sheetFilename')"
            url="//production-acces-impot-assets.s3.us-east-2.amazonaws.com/acces-impot_info-travail-autonome.pdf"
          >
            <IconButton>
              <template #iconBefore>
                <IconDocument :class="$style.iconDocument" />
              </template>
              {{ $t('sheetButton') }}</IconButton
            >
          </LinkDownload>
        </div>
      </InfoPanel>
    </Accordion>
    <Accordion :isOpen="hasNoExpenses">
      <InfoPanel :class="$style.panel" :theme="INFO_PANEL_THEMES.positive">
        {{ $t('positiveNoExpenses') }}
      </InfoPanel>
      <InfoPanel :class="$style.panel" :theme="INFO_PANEL_THEMES.info">
        {{ $t('infoNoExpenses') }}
      </InfoPanel>
    </Accordion>
  </div>
</template>

<script>
import Accordion from '@/components/Accordion.vue'
import InfoPanel, { INFO_PANEL_THEMES } from '@/components/InfoPanel.vue'
import LinkDownload from '@/components/LinkDownload.vue'
import IconButton from '@/components/IconButton.vue'
import IconDocument from '@/components/_icons/IconDocument.svg'

export default {
  name: 'InfoSelfEmployedWithExpenses',
  components: {
    Accordion,
    InfoPanel,
    LinkDownload,
    IconButton,
    IconDocument,
  },

  props: {
    value: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      INFO_PANEL_THEMES,
    }
  },

  computed: {
    hasExpenses() {
      return Number(this.value) === 1
    },

    hasNoExpenses() {
      return this.value !== '' && Number(this.value) === 0
    },
  },
}
</script>

<style lang="scss" module>
@import '../report-settings';

.panel {
  margin-top: $field-label-margin-top;
}

.downloadButton {
  display: inline-block;
  margin: 16px 0 4px;
}

.iconDocument {
  display: inline-block;
  width: 20px;
  color: $color-light-grey-1;
  transform: translateY(-1px);
}
</style>

<i18n src="@/locales/info-self-employed.json" />
