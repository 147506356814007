export function isEmptyObject(obj) {
  return !obj || (Object.keys(obj).length === 0 && obj.constructor === Object)
}

export function renameKey(obj, oldKey, newKey) {
  delete Object.assign(obj, { [newKey]: obj[oldKey] })[oldKey]
}

export function hasKeys(obj, arrayOfKeys) {
  return arrayOfKeys.every(k => obj.hasOwnProperty(k)) // eslint-disable-line no-prototype-builtins
}
