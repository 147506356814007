const path = require('path')

function buildReportFilePath({ userId, reportYear, personKey, filename = '' }) {
  return path.join(`user${userId}`, String(reportYear), personKey, filename)
}

function buildReportPostDocumentFilePath({ userId, reportYear, personKey, filename = '' }) {
  const subPaths = [`user${userId}`, String(reportYear)]
  if (personKey) subPaths.push(personKey)
  return path.join(...subPaths, filename)
}

module.exports = {
  buildReportFilePath,
  buildReportPostDocumentFilePath,
}
