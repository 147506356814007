function isYear2020Available() {
  // Was no working because it needs to be set on client-side only
  // return new Date().setHours(0, 0, 0, 0) >= new Date('2021-02-15').setHours(0, 0, 0, 0)
  return true
}

export default {
  namespaced: true,
  state: () => ({
    features: {
      year2020: isYear2020Available(),
    },
  }),

  mutations: {
    setFeature(state, { id, isEnabled }) {
      state.features[id] = isEnabled
    },
  },
}
