import { MESSAGE_TYPE_MAP } from '@/store/flash/flash'
import { ADMIN_STATUSES, SEARCH_FILTERS } from 'acces-impot-settings-report/constants/admin'

export const FILTER_CATEGORIES = {
  completed: 'completed',
  notCompleted: 'notCompleted',
}

export const MAIN_FILTER_WHERE_ARGS = {
  [FILTER_CATEGORIES.completed]: {
    adminStatus: [SEARCH_FILTERS.equalTo, ADMIN_STATUSES.completed],
  },
  [FILTER_CATEGORIES.notCompleted]: {
    adminStatus: [SEARCH_FILTERS.notEqualTo, ADMIN_STATUSES.completed],
  },
}
export const DEFAULT_ASSIGNED_REPORT_LIST_WHERE = MAIN_FILTER_WHERE_ARGS.notCompleted

export { MESSAGE_TYPE_MAP }
