/**
 * This directive is used for component testing and intended to separate quality engineering form design or coding.
 * Whenever you need to select elements from DOM for test purposes use this directive instead of e.g. id or class attribute.
 * The directive will only be rendered in test environment not to polute production.
 *
 * @example
 * // watch out: the value must be a 'string'
 * <tag v-data-test-id="'my-id'"></tag>
 *
 * // will render to (in test env):
 * <tag data-test-id="my-id"></tag>
 *
 * // will render to (in other envs):
 * <tag></tag>
 *
 */
export default (el, binding) => {
  if (!binding.value)
    throw 'v-data-test-id value must be passed as a string for: ' + binding.expression
  if ([process.env.NODE_ENV, process.env.TARGET_ENV].includes('test')) {
    el.setAttribute('data-test-id', binding.value)
  }
}
