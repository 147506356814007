<template>
  <div></div>
</template>

<script>
import initChatwootSdk from '@/services/tracking/tags/chatwoot-sdk'
import { onChatwootReady } from '@/services/tracking/chatwoot'
import { getApiClient } from '@/api/client'
import FEATURE_FLAG_QUERY from '@/api/queries/feature-flag.gql'

export default {
  name: 'LiveChat',

  watch: {
    '$i18n.locale': 'changeLanguage',
  },

  mounted() {
    this.fetchFeatureFlag().then(isEnabled => {
      if (isEnabled) this.initChatwootSdk()
    })
  },

  methods: {
    async fetchFeatureFlag() {
      if (this.$route.query.chatwoot === 'ok2v9f_wv03') return true

      const client = getApiClient(this)
      const response = await client.query({
        query: FEATURE_FLAG_QUERY,
        variables: { identifier: 'chatwoot' },
      })
      return !!response.data?.featureFlagByIdentifier?.isEnabled
    },

    initChatwootSdk() {
      if (!process.browser || !this.$i18n.locale) return

      initChatwootSdk(this.$i18n.locale)
    },

    changeLanguage() {
      onChatwootReady(() => {
        window.$chatwoot.setLocale(this.$i18n.locale)
      })
    },
  },
}
</script>
