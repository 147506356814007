<template>
  <div
    :class="[
      'question-mark-cta',
      {
        'question-mark-cta--inline': isInline,
        'question-mark-cta--floating': !isInline,
        'question-mark-cta--fake-padding': hasFakePadding,
      },
    ]"
    v-on="$listeners"
  >
    <div
      :class="[
        'question-mark-cta__inner',
        {
          'question-mark-cta__inner--inline': isInline,
          'question-mark-cta__inner--no-bg': hasNoBackground,
        },
      ]"
    >
      <IconStepQuestion class="question-mark-cta__icon" />
    </div>
  </div>
</template>

<script>
import IconStepQuestion from '@/components/_icons/IconStepQuestion.svg'

export default {
  name: 'QuestionMarkCta',
  components: {
    IconStepQuestion,
  },

  props: {
    isInline: {
      type: Boolean,
      default: false,
    },

    hasFakePadding: {
      type: Boolean,
      default: true,
    },

    hasNoBackground: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style lang="scss" scoped>
@import './field-settings';

$font-size: $font-size-small;
$text-vert-padding: ($question-mark-circle-size - $font-size) / 2;
$text-towards-top: 2px;
$text-padding-top: floor($text-vert-padding) - $text-towards-top;
$text-padding-bottom: ceil($text-vert-padding) + $text-towards-top;

.question-mark-cta {
  position: absolute;
  top: 50%;
  right: 0;
  cursor: pointer;
  z-index: 10;

  &--floating {
    transform: translate(0, -50%);
  }

  &--fake-padding {
    padding: $question-mark-circle-wrap-padding;
  }

  &--inline {
    position: relative;
    display: inline-block;
    top: auto;
    right: auto;
    min-width: $question-mark-circle-size;
  }

  &--fake-padding#{&}--inline {
    transform: translate(-$question-mark-circle-wrap-padding, -$question-mark-circle-wrap-padding);
  }

  &__inner {
    background-color: $color-white;
    padding: $text-padding-top 0 $text-padding-bottom;
    width: $question-mark-circle-size;
    height: $question-mark-circle-size;
    border: 1px solid $color-text-lighter;
    border-radius: 50%;
    line-height: 1;
    font-size: $font-size;
    color: $color-text-lighter;
    text-align: center;
    transition:
      background-color 0.1s,
      border-color 0.1s,
      color 0.1s;

    &--no-bg {
      background-color: transparent;
    }

    &--inline {
      padding: 0.1em 0 0.3em;
      width: 1.6em;
      height: 1.6em;
      box-sizing: border-box;
    }
  }

  &:hover &__inner {
    background-color: rgba-to-solid-color($button-transparent-hover-bg-color, $color-white);
    border-color: $color-text-light;
    color: $color-text-light;

    &--no-bg {
      background-color: transparentize($color-white, 0.7);
    }
  }

  &__icon {
    width: 7px;
  }
}
</style>
