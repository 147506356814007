class Country {
  constructor({ code, currency, provinces, postalCode, phone }) {
    if (!/^[a-z]{2}$/.test(code)) {
      throw new Error('[Country] - "code" must be 2 lowercase letters.')
    }
    this.code = code
    this.currency = currency
    this.provinces = provinces
    this.postalCode = postalCode
    this.phone = phone
  }
}

module.exports = Country
