<template>
  <component
    :is="tag"
    :class="[$style.heading, $style[tag], { [$style.margins]: hasMargins }]"
    v-on="$listeners"
  >
    <slot />
  </component>
</template>

<script>
export const HEADINGS = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
}

export default {
  name: 'Heading',
  props: {
    tag: {
      type: String,
      default: HEADINGS.h2,
      validator: value => Object.values(HEADINGS).includes(value),
    },

    hasMargins: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style lang="scss" module>
@import './heading-settings';

.heading {
  line-height: $heading-line-height;
  color: $color-brand-navy;
  font-weight: bold;
}

.margins {
  margin: 0.35em 0 0.5em;
}

.h1,
.h2 {
  font-size: 32px;
  font-family: $font-family-heading;

  @include min-screen($mq-medium) {
    font-size: 40px;
  }

  @include min-screen($mq-full) {
    font-size: 45px;
  }
}

.h3 {
  font-size: 20px;
  letter-spacing: 0.1em;
  line-height: 1.4;
  text-transform: uppercase;

  @include min-screen($mq-medium) {
    font-size: 21px;
  }

  @include min-screen($mq-full) {
    font-size: 22px;
  }
}

.h4 {
  @include h4-styles;
}
</style>
