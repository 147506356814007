import { MESSAGE_TYPES as RESPONSE_MESSAGE_TYPES } from 'acces-impot-utils/lib/validation'

export const MESSAGE_TYPES = {
  success: 'success',
  error: 'error',
  warn: 'warn',
  neutral: 'neutral',
}

export const MESSAGE_TYPE_MAP = {
  [RESPONSE_MESSAGE_TYPES.info]: MESSAGE_TYPES.neutral,
  [RESPONSE_MESSAGE_TYPES.success]: MESSAGE_TYPES.success,
  [RESPONSE_MESSAGE_TYPES.warning]: MESSAGE_TYPES.error,
  [RESPONSE_MESSAGE_TYPES.error]: MESSAGE_TYPES.error,
}

function getMessageObject(payload) {
  let message = {}
  if (typeof payload === 'string') message.text = payload
  else message = payload

  return message
}

function formatMessage(id, message) {
  if (!message) return {}

  let formattedMessage = message
  if (typeof formattedMessage === 'string') formattedMessage = { text: message }
  if (!formattedMessage.type) formattedMessage.type = MESSAGE_TYPES.neutral
  formattedMessage.id = id
  formattedMessage.identifier = buildMessageIdentifier(formattedMessage.key || id)

  return formattedMessage
}

function isValid(message) {
  return /\S/.test(message.text)
}

function buildMessageIdentifier(id) {
  return `flash-message-${id}`
}

export default {
  namespaced: true,
  state: () => ({
    messages: [],
    nextMessageId: 1,
  }),

  mutations: {
    incrementNextMessageId(state) {
      state.nextMessageId++
    },

    unshiftMessage(state, message) {
      state.messages.unshift(message)
    },

    removeMessage(state, id) {
      const index = state.messages.findIndex(msg => Number(msg.id) === Number(id))
      state.messages.splice(index, 1)
    },

    clearMessages(state) {
      state.messages = []
    },
  },

  actions: {
    addMessages({ dispatch }, messages) {
      return messages.map(message => dispatch('addMessage', message))
    },

    addMessage({ state, commit }, message) {
      const formattedMsg = formatMessage(state.nextMessageId, message)
      if (formattedMsg.key && state.messages.find(msg => formattedMsg.key === msg.key)) return

      if (isValid(formattedMsg)) {
        commit('unshiftMessage', formattedMsg)
        commit('incrementNextMessageId')
        return formattedMsg
      }
      return {}
    },

    addSuccessMessages({ dispatch }, messages) {
      return messages.map(message => dispatch('addSuccessMessage', message))
    },

    addSuccessMessage({ dispatch }, payload) {
      return dispatch('addMessage', { ...getMessageObject(payload), type: MESSAGE_TYPES.success })
    },

    addErrorMessages({ dispatch }, messages) {
      return messages.map(message => dispatch('addErrorMessage', message))
    },

    addErrorMessage({ dispatch }, payload) {
      return dispatch('addMessage', { ...getMessageObject(payload), type: MESSAGE_TYPES.error })
    },

    addWarnMessage({ dispatch }, payload) {
      return dispatch('addMessage', { ...getMessageObject(payload), type: MESSAGE_TYPES.warn })
    },

    addConnectionErrorMessage({ dispatch }) {
      return dispatch('addMessage', {
        type: MESSAGE_TYPES.error,
        key: 'connectionError',
        text: self.$nuxt.$i18n.t('errors.connection'),
      })
    },

    removeMessage({ commit }, id) {
      commit('removeMessage', id)
    },

    clearMessages({ commit }) {
      commit('clearMessages')
    },
  },

  getters: {
    getMessageElement: state => key => {
      return process.browser && state.messages.some(msg => key === msg.key)
        ? document.getElementById(buildMessageIdentifier(key))
        : null
    },
  },
}
