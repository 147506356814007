<template>
  <SmoothReflow
    class="type-and-amount-field"
    :class="{ 'type-and-amount-field--no-reflow': hasError }"
  >
    <template v-for="(typeAndAmount, index) in models">
      <Select
        v-if="isSelectVisible"
        :id="`type-${id}-${index}`"
        ref="typeFields"
        :key="`type-${id}-${index}`"
        class="type-and-amount-field__field type-and-amount-field__field--type"
        :label="typeLabel"
        :options="getSelectOptions(typeAndAmount.type)"
        :selectKey="optionsSelectedKey"
        :isOptional="index > 0 && !typeAndAmount.amount"
        :model="typeAndAmount.type"
        :isEditable="isEditable"
        @update:model="updateModel($event, index, 'type')"
      >
        <slot v-if="index === 0" />
      </Select>
      <Input
        :id="`amount-${id}-${index}`"
        ref="amountFields"
        :key="`amount-${id}-${typeAndAmount.type || index}`"
        class="type-and-amount-field__field"
        :class="{ 'type-and-amount-field__field--full-width': !isSelectVisible }"
        type="number"
        :label="amountLabel"
        :isOptional="index > 0 && !typeAndAmount.type"
        :model="typeAndAmount.amount"
        :isEditable="isEditable"
        @keypress="onAmountFieldKeyPress"
        @update:model="updateModel($event, index, 'amount')"
      >
        <slot v-if="index === 0" />
      </Input>
    </template>
  </SmoothReflow>
</template>

<script>
import cloneDeep from 'lodash.clonedeep'
import { isEmptyString } from '@/helpers/string'
import Input from '@/components/Form/Input.vue'
import Select from '@/components/Form/Select.vue'
import SmoothReflow from '@/components/SmoothReflow.vue'
import {
  validationProps,
  selectSpecificProps,
  getValidationData,
  computed,
  watch,
} from '@/components/Form/field-settings'
import { formatAmount, onAmountFieldKeyPress } from './report-documents-settings'

const FIELD_SEPARATOR = '__'

export default {
  name: 'TypeAndAmountField',
  components: {
    Input,
    Select,
    SmoothReflow,
  },

  props: {
    ...validationProps,

    id: {
      type: String,
      required: true,
    },

    model: {
      type: String,
      required: true,
    },

    typeLabel: {
      type: String,
      default: '',
    },

    amountLabel: {
      type: String,
      default: '',
    },

    selectOptions: selectSpecificProps.options,

    isEditable: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      ...getValidationData(),
      fieldModels: {},
      models: {},
      onAmountFieldKeyPress,
      optionsSelected: {},
    }
  },

  computed: {
    ...computed,

    optionsSelectedKey() {
      return Object.keys(this.optionsSelected).sort().join('_')
    },

    typeValues() {
      return this.selectOptions.filter(option => !!option.value)
    },

    isSelectVisible() {
      return this.typeValues.length > 1
    },
  },

  watch: {
    ...watch,

    model: {
      immediate: true,
      handler() {
        this.updateModels()
      },
    },

    typeValues: {
      immediate: true,
      handler() {
        this.models.forEach((_typeAndAmount, index) => {
          if (this.typeValues.length === 1) {
            this.updateModel(this.typeValues[0].value, index, 'type')
          }
        })
      },
    },
  },

  methods: {
    validate() {
      this.errors = []
      let isValid = true
      this.getFields().forEach(field => {
        const isFieldValid = field.validate()
        if (isValid) isValid = isFieldValid
      })
      if (!isValid) {
        this.isValidatingOnChange = true
        this.errors.push(this.$t('errors.invalidField'))
        return false
      } else {
        return true
      }
    },

    getFields() {
      return [...(this.$refs.typeFields || []), ...this.$refs.amountFields]
    },

    getHighestErrorField() {
      const fields = this.getFields()
      const index = fields.findIndex(field => field.hasError)
      return index === -1 ? null : index === 0 ? this : fields[index]
    },

    getSelectOptions(type) {
      return this.selectOptions.filter(option => {
        return option.value === type || !this.optionsSelected[option.value]
      })
    },

    updateModels() {
      this.optionsSelected = {}
      const typesAndAmounts = !isEmptyString(this.model) ? this.model.split(FIELD_SEPARATOR) : []
      const models = typesAndAmounts.map(typeAndAmount => {
        const [type, amount] = typeAndAmount.split(':')
        if (type) this.optionsSelected[type] = true
        return { type, amount }
      })
      if (
        this.isEditable &&
        models.length < this.selectOptions.length - 1 &&
        models.every(model => !!model.type)
      ) {
        models.push({ type: '', amount: '' })
      }
      this.models = models
      this.$nextTick(() => {
        if (this.isValidatingOnChange) this.validate()
      })
    },

    stringifyModels(models) {
      return Object.values(models)
        .map(model => {
          const { type, amount } = model
          return type || amount ? `${type || ''}:${amount || ''}` : ''
        })
        .filter(model => !!model)
        .join(FIELD_SEPARATOR)
    },

    updateModel(model, index, key) {
      const models = cloneDeep(this.models)
      models[index][key] = key === 'amount' ? formatAmount(model) : model
      this.$emit('update:model', this.stringifyModels(models))
    },
  },
}
</script>

<style lang="scss" scoped>
.type-and-amount-field {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  &--no-reflow {
    overflow: visible !important;
    height: auto !important;
    transition: all 0s;
  }

  &__field {
    width: 100%;

    @include min-screen($mq-larger) {
      width: calc(50% - #{$field-margin-top / 2});
    }

    &--full-width {
      width: 100%;
    }

    &--type ~ &--type {
      @include max-screen($mq-larger) {
        margin-top: $field-margin-top * 2;
      }
    }
  }
}
</style>
