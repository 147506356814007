<template>
  <div class="date-select">
    <Select
      v-for="dateField in dateFieldsArray"
      ref="dateFields"
      :key="dateField.id"
      :model="dateObject[dateField.id]"
      v-bind="getSelectProps(dateField)"
      @update:model="updateModel($event, dateField.id)"
    />
  </div>
</template>

<script>
/**
 * Three select tags side by side: one to select the year, one for month, one for day. The order
 * of the fields are based on the locale set in the url (i.e. "fr-ca" would be year/month/day,
 * "en-ch" would be "day/month/year").
 */
import { mapGetters } from 'vuex'
import Select from './Select.vue'
import { selectProps, getSelectData, validationComputed, validate } from './field-settings'

const MIN_YEAR_AGO = 120
const MONTH_COUNT = 12
const DEFAULT_DAY_COUNT = 31

export default {
  name: 'DateSelect',
  components: {
    Select,
  },

  props: {
    ...selectProps,

    // eslint-disable-next-line vue/require-prop-types
    label: {
      ...selectProps.label,
      required: false,
      default: '',
    },

    isDateOfBirth: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      ...getSelectData(),
      dateFieldsArray: [],
      dayCount: 31,
      dateObject: {
        year: '',
        month: '',
        day: '',
      },
    }
  },

  computed: {
    ...mapGetters({ dateLocale: 'page/dateLocale' }),

    ...validationComputed,

    inputOrder() {
      const date = new Date(Date.UTC(2005, 10, 18)).toLocaleDateString(this.dateLocale)
      const array = [
        { id: 'year', index: date.indexOf('2005') },
        { id: 'month', index: date.indexOf('11') },
        { id: 'day', index: date.indexOf('18') },
      ]
      return array.sort((a, b) => a.index > b.index).map(obj => obj.id)
    },
  },

  watch: {
    model: {
      immediate: true,
      handler() {
        if (!this.model) {
          this.dateObject = {
            year: '',
            month: '',
            day: '',
          }
          return
        }

        const split = this.model.split('-')
        const format = v => (v && /^\d+$/.test(v) ? Number(v) : '')
        this.dateObject = {
          year: format(split[0]),
          month: format(split[1]),
          day: format(split[2]),
        }
      },
    },

    'dateObject.year'() {
      this.updateDayOptions()
    },

    'dateObject.month'() {
      this.updateDayOptions()
    },

    errors() {
      this.updateFieldsArray()
    },
  },

  created() {
    this.updateFieldsArray()
  },

  methods: {
    validate,

    getSelectProps(dateField) {
      const props = {
        ...this._props,
        ...dateField,
      }
      delete props.model
      delete props.isInvalid

      return props
    },

    updateModel(value, id) {
      this.$set(this.dateObject, id, value)
      const date = Object.keys(this.dateObject)
        .map(k => {
          const v = this.dateObject[k]
          return String(v).length === 1 ? `0${v}` : v
        })
        .filter(d => /\d/.test(d))
        .join('-')

      this.$nextTick(() => {
        if (this.isValidatingOnChange) {
          this.validateFieldById(id)
          this.validate()
        }
      })
      this.$emit('update:model', date)
    },

    validateFieldById(id) {
      this.$refs.dateFields.find(field => field.id === id).validate()
    },

    updateFieldsArray() {
      const array = [
        {
          id: 'year',
          label: this.$t('date.label.year'),
          options: this.getYearOptions(),
          autocomplete: this.isDateOfBirth ? 'bday-year' : false,
        },
        {
          id: 'month',
          label: this.$t('date.label.month'),
          options: this.getMonthOptions(),
          autocomplete: this.isDateOfBirth ? 'bday-month' : false,
        },
        {
          id: 'day',
          label: this.$t('date.label.day'),
          options: this.getDayOptions(DEFAULT_DAY_COUNT),
          autocomplete: this.isDateOfBirth ? 'bday-day' : false,
        },
      ]
      if (this.isValidatingOnChange) {
        array.forEach(dateField => this.validateFieldById(dateField.id))
      }
      this.dateFieldsArray = this.inputOrder.map(id => array.find(dateField => dateField.id === id))
    },

    updateDayOptions() {
      const formatValue = (value, fallback) => (/^\d/.test(value) ? Number(value) : fallback)

      const yearValue = formatValue(this.dateObject.year, 2000)
      const monthValue = formatValue(this.dateObject.month, 1)
      this.dayCount = new Date(yearValue, monthValue, 0).getDate()

      const dayIndex = this.dateFieldsArray.findIndex(dateField => dateField.id === 'day')
      this.$set(this.dateFieldsArray, dayIndex, {
        ...this.dateFieldsArray[dayIndex],
        options: this.getDayOptions(this.dayCount),
      })
      if (this.dateObject.day > this.dayCount) this.dateObject.day = ''
    },

    getYearOptions() {
      const options = [{ value: undefined, text: '' }]
      const currentYear = new Date().getFullYear()
      for (let year = currentYear; year >= currentYear - MIN_YEAR_AGO; year--) {
        options.push({ value: year, text: year })
      }
      return options
    },

    getMonthOptions() {
      const options = [{ value: undefined, text: '' }]
      const months = [
        this.$t('date.months.january'),
        this.$t('date.months.february'),
        this.$t('date.months.march'),
        this.$t('date.months.april'),
        this.$t('date.months.may'),
        this.$t('date.months.june'),
        this.$t('date.months.july'),
        this.$t('date.months.august'),
        this.$t('date.months.september'),
        this.$t('date.months.october'),
        this.$t('date.months.november'),
        this.$t('date.months.december'),
      ]
      for (let i = 0; i < MONTH_COUNT; i++) options.push({ value: i + 1, text: months[i] })

      return options
    },

    getDayOptions(dayCount) {
      const options = [{ value: undefined, text: '' }]
      for (let i = 1; i <= dayCount; i++) options.push({ value: i, text: i })

      return options
    },
  },
}
</script>
