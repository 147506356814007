<template>
  <div class="layout layout--empty">
    <SiteContainer> <Nuxt /> </SiteContainer>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import SiteContainer from '@/components/SiteContainer/SiteContainer.vue'

export default {
  name: 'EmptyLayout',
  components: {
    SiteContainer,
  },

  computed: {
    ...mapGetters({
      viewportWidth: 'viewport/width',
    }),
  },

  watch: {
    viewportWidth: {
      immediate: true,
      handler() {
        this.updatePageProperties()
      },
    },
  },

  mounted() {
    this.resetViewportStoreState()
    this.resetPageViewCount()
  },

  methods: {
    ...mapMutations({
      resetPageViewCount: 'page/resetPageViewCount',
    }),

    ...mapActions({
      resetViewportStoreState: 'viewport/resetViewportStoreState',
      updatePageProperties: 'page/updatePageProperties',
    }),
  },
}
</script>

<style lang="scss" scoped>
.layout {
  background-color: $color-background;
}
</style>

<style lang="scss">
@import './default';
</style>
