var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FieldWrapper',{staticClass:"input",class:{ 'input--error': _vm.hasError }},[_c(_vm.fieldTag,_vm._g(_vm._b({ref:"field",tag:"component",staticClass:"input__field input__field--text",class:{
      'input__field--textarea': _vm.isTextarea,
      'input__field--no-label': !_vm.label,
      'input__field--filled': _vm.model,
      'input__field--fake': _vm.isComputedFakeInput,
      'input__field--disabled': _vm.isComputedDisabled,
      'input__field--not-editable': !_vm.isEditable,
      'input__field--error': _vm.hasError || _vm.hasRedBorder,
      'input__field--dark': _vm.hasDarkColor,
    },attrs:{"id":_vm.id},on:{"input":_vm.updateModel,"animationstart":_vm.onAnimationStart}},'component',_vm.inputComponentProps,false),Object.assign({}, _vm.eventHandlers, _vm.$listeners)),[_vm._v(_vm._s(_vm.isNoInputTag ? _vm.model : ''))]),(_vm.label)?_c('label',{staticClass:"input__label",class:{
      'input__label--is-placeholder': _vm.hasPlaceholder,
      'input__label--textarea': _vm.isTextarea,
      'input__label--disabled': _vm.isComputedDisabled,
      'input__label--not-editable': !_vm.isEditable,
      'input__label--fake': _vm.isComputedFakeInput,
      'input__label--dark': _vm.hasDarkColor,
    },attrs:{"for":_vm.id}},[_vm._v(_vm._s(_vm.label))]):_vm._e(),_vm._t("default"),_c('FieldErrorMessage',{attrs:{"errors":_vm.visibleErrors}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }