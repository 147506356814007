export function isEmptyString(string) {
  return !string || !/\S/.test(string)
}

export function capitalizeFirstLetter(string) {
  return string ? string[0].toUpperCase() + string.slice(1) : ''
}

export function convertToKebabCase(string) {
  return string.replace(/([a-z0-9])([A-Z])/g, '$1-$2').toLowerCase()
}

function wrapWithNobrSpan(text) {
  return `<span class="u-nobr">${text}</span>`
}

export function makeHtmlSafe(str) {
  return str.replace(/[&<>"]/g, match => {
    const entities = {
      '&': '&amp;',
      '<': '&lt;',
      '>': '&gt;',
      '"': '&quot;',
    }
    return entities[match]
  })
}

export function findEndOfWordIndex(text, fromIndex) {
  let index = fromIndex
  while (/\S/.test(text[index]) && index < text.length) index++

  return index
}

export const LINE_BREAK_BLOCK_TYPES = {
  block: 'block',
  text: 'text',
  nobr: 'nobr',
}

/**
 * Make sure that no line break leaves a single word by itself on the last line.
 *
 * Especially useful in French since there is always a space before some
 * punctuation marks (?!:;) meaning this function would prevent having a single
 * character on the last line.
 *
 * @name resolveLineBreakOfLastWord
 * @function
 * @param {string} paragraph - Any paragraph printed as HTML.
 * @param {object} params - Override default parameters with params object.
 * @param {number} params.minWordCount - Minimum amount of words that the paragraph needs to have in order to proceed.
 * @param {number} params.maxLengthOfLastWord - The length of the last word needs to be less than this param in order to proceed.
 * @param {number} params.maxLengthOfConcat - The total length of the last word together with the second last word needs to be less than this param in order to proceed.
 */
export function resolveLineBreakOfLastWord(paragraph, params = {}) {
  const blocks = splitLineBreakOfLastWord(paragraph, params)
  return blocks
    .map(({ is, value }) => (is === LINE_BREAK_BLOCK_TYPES.nobr ? wrapWithNobrSpan(value) : value))
    .join('')
}

export function splitLineBreakOfLastWord(paragraph, params = {}) {
  const { text: TEXT, nobr: NOBR } = LINE_BREAK_BLOCK_TYPES
  if (!paragraph || typeof paragraph !== 'string') return [{ is: TEXT, value: '' }]

  const defaultParams = {
    minWordCount: 5,
    maxLengthOfLastWord: 20,
    maxLengthOfConcat: 25,
    isWrappingLastWordOnly: false,
  }
  const options = { ...defaultParams, ...params }
  const words = paragraph.trim().split(/\s+/)
  const lastWord = words[words.length - 1]
  let concatenatedWords = lastWord

  const hasOneCharLongLastWord = lastWord.length === 1
  let isMeetingParamCriteria = true

  if (!options.isWrappingLastWordOnly) {
    const secondLastWord = words[words.length - 2] || ''
    concatenatedWords = `${secondLastWord} ${lastWord}`

    isMeetingParamCriteria =
      words.length >= options.minWordCount &&
      lastWord.length <= options.maxLengthOfLastWord &&
      concatenatedWords.length <= options.maxLengthOfConcat
  }
  if (hasOneCharLongLastWord || isMeetingParamCriteria) {
    let textValue = ''

    if (options.isWrappingLastWordOnly) {
      textValue = paragraph.substring(0, paragraph.length - concatenatedWords.length)
    } else {
      const concatWordsMatch = paragraph.match(/(\s+)\S+\s+\S+\s*$/)
      if (concatWordsMatch) {
        textValue = paragraph.substring(0, concatWordsMatch.index + concatWordsMatch[1].length)
      }
    }
    return [
      { is: TEXT, value: textValue },
      { is: NOBR, value: concatenatedWords },
    ]
  }
  return [{ is: TEXT, value: paragraph }]
}

export function resolveLineBreakOfPunctuation(text) {
  return resolveFrenchPunctuations(resolveFrenchQuotes(text))
}

export function resolveFrenchPunctuations(text) {
  return text.replace(/(^|\s)([^\s<>]+\s+[?!:;])/g, (_m, p1, p2) => p1 + wrapWithNobrSpan(p2))
}

export function resolveFrenchQuotes(text) {
  return text.replace(/(«\s+\S+|\S+\s+»)/g, m => wrapWithNobrSpan(m))
}

export function removeAccents(str) {
  try {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
  } catch (err) {
    return str
  }
}
