var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('FieldWrapper',{staticClass:"date-input",class:{ 'date-input--error': _vm.hasError }},[_c('label',{staticClass:"date-input__label",class:{
      'date-input__label--is-placeholder': _vm.hasPlaceholder,
      'date-input__label--disabled': _vm.isComputedDisabled,
      'date-input__label--not-editable': !_vm.isEditable,
    },attrs:{"for":_vm.id}},[_vm._v(_vm._s(_vm.label))]),_c('input',_vm._g({ref:"field",class:[
      'date-input__field date-input__field--text',
      {
        'date-input__field--disabled': _vm.isComputedDisabled,
        'date-input__field--not-editable': !_vm.isEditable,
        'date-input__field--error': _vm.hasError,
      } ],attrs:{"id":_vm.id,"type":_vm.type || 'text',"disabled":_vm.isComputedDisabled},domProps:{"value":_vm.renderDate(_vm.model, _vm.locale)},on:{"focus":_vm.openDatePicker,"animationstart":_vm.onAnimationStart}},_vm.eventHandlers)),_c('Datetime',{ref:"datetime",attrs:{"inputId":_vm.inputId,"minDatetime":_vm.minDatetimeInternal || _vm.minDatetime,"maxDatetime":_vm.maxDatetimeInternal || _vm.maxDatetime,"valueZone":"UTC","zone":"UTC","phrases":_vm.submitCtaTranslations},on:{"input":_vm.updateDatetimeModel,"close":_vm.onDatePickerClose}}),_vm._t("default"),_c('FieldErrorMessage',{attrs:{"errors":_vm.errors}}),(_vm.overlayHeight)?_c('style',{tag:"component"},[_vm._v("\n    .vdatetime-overlay { height: "+_vm._s(_vm.overlayHeight + 'px')+"; }\n  ")]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }