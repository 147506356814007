import { ROUTE_NAMES } from '@/constants/route-names'

export default function ({ app, redirect, route, $config }) {
  const { isMaintenanceMode } = $config

  if (isMaintenanceMode && !route.name?.startsWith(ROUTE_NAMES.maintenance)) {
    return redirect(app.localePath({ name: ROUTE_NAMES.maintenance, query: route.query }))
  }
  if (!isMaintenanceMode && route.name?.startsWith(ROUTE_NAMES.maintenance)) {
    return redirect(app.localePath({ name: ROUTE_NAMES.home, query: route.query }))
  }
}
