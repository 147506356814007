import { getInvalidPasswordError } from 'acces-impot-settings-user'

const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export function isEmailInvalid(value) {
  return !emailRegex.test(value) ? this.$t('errors.invalidEmail') : false
}

export function isPasswordInvalid(value) {
  if (value.length < 6) return this.$t('errors.passwordTooShort')

  const invalidPasswordError = getInvalidPasswordError(value)
  return invalidPasswordError ? this.$t(invalidPasswordError) : false
}
