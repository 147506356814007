import { render, staticRenderFns } from "./default.vue?vue&type=template&id=fd6dca94&scoped=true&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"
import style0 from "./default.vue?vue&type=style&index=0&id=fd6dca94&lang=scss&scoped=true&"
import style1 from "./default.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "fd6dca94",
  null
  
)

/* custom blocks */
import block0 from "@/locales/default-layout.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fhome%2Frunner%2Fwork%2Facces-impot%2Facces-impot%2Fpackages%2Facces-impot-front%2Flayouts%2Fdefault.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports