<template>
  <nuxt-link :to="to" :event="''" @click.native.prevent="handleClickInternal(to)" v-on="$listeners">
    <slot />
  </nuxt-link>
</template>

<script>
export default {
  name: 'NuxtLinkExtra',

  props: {
    to: {
      type: [String, Object],
      default: '',
    },

    handleClick: {
      type: Function,
      default(to) {
        this.$router.push(to)
      },
    },
  },

  methods: {
    handleClickInternal(to) {
      this.handleClick(to)
      this.$emit('click')
    },
  },
}
</script>
