import { scrollSmoothlyToElement } from '@/helpers/scroll'

export function hasUrlQuery(urlParam) {
  return process.browser && new RegExp(`[?&]${urlParam}`).test(window.location.href)
}

export function getQueryFromUrl(query) {
  const urlParamMatch = window.location.search.match(new RegExp(`(^|[?&])${query}=([^&#]+)`))
  return urlParamMatch ? urlParamMatch[2] : null
}

export function getAbsoluteUrl(url) {
  return /^\//.test(url) ? 'http://192.168.0.164:5500' + url : url
}

export function goToHomeSection(to) {
  if (to.path === this.$route.path) {
    const element = document.querySelector(to.hash)
    if (element) {
      return scrollSmoothlyToElement(element, {
        isIncludingHeader: false,
        speed: 3000,
        minScrollTime: 0.5,
      })
    }
  }
  this.$router.push(to)
}

/**
 * @param {string} query
 * @return {void}
 */
export function removeQueryFromCurrentRoute(query) {
  if (typeof window === 'undefined') return

  const cleanUrl = removeQueryFromPath(window.location.href, query)
  window.history.replaceState(window.history.state, '', cleanUrl)
}

/**
 * @param {string} path
 * @param {string} query
 * @return {string}
 */
export function removeQueryFromPath(path, query) {
  return path.replace(new RegExp(`([?&])${query}(=[^&#]+)?(&?)`), '$1').replace(/(\?|&)($|#)/, '$2')
}
