export function removeFromArray(array, element) {
  const index = array.indexOf(element)
  if (index >= 0) array.splice(index, 1)
  return array
}

export function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1))
    const temp = array[i]
    array[i] = array[j]
    array[j] = temp
  }
}
