/**
 * Use to execute code only after page is loaded.
 * This can be used to decrease initial loading time.
 *
 * @returns {Promise}
 */
export function onLoad() {
  return new Promise(resolve => {
    !process.browser || document.readyState === 'complete'
      ? resolve()
      : window.addEventListener('load', () => resolve())
  })
}

export function onMount(vm) {
  return new Promise(resolve => {
    vm._isMounted ? resolve() : vm.$once('hook:mounted', () => resolve())
  })
}

export function onDOMContentLoaded() {
  return new Promise(resolve => {
    document.readyState !== 'loading'
      ? resolve()
      : window.addEventListener('DOMContentLoaded', () => resolve())
  })
}

export function createAsyncPromise() {
  let resolvePromise
  let rejectPromise
  const promise = new Promise((resolve, reject) => {
    resolvePromise = resolve
    rejectPromise = reject
  })
  return [promise, resolvePromise, rejectPromise]
}
