import cloneDeep from 'lodash.clonedeep'
import modelSettings from 'acces-impot-settings-report'
import countrySettings from 'acces-impot-settings-country'
import { isValidDate } from '@/helpers/form'
import { isEmailInvalid } from '@/helpers/validation'
import { PERSON_TYPES } from '@/store/report/report-settings'
import { personalizeTranslation } from '@/helpers/i18n'
import Input from '@/components/Form/Input.vue'
// import FormattedNumberInput from '@/components/Form/FormattedNumberInput.vue'
import Select from '@/components/Form/Select.vue'
import DateInput from '@/components/Form/DateInput.vue'
import DateSelect from '@/components/Form/DateSelect.vue'
import Checkbox from '@/components/Form/Checkbox.vue'
import OverlayInfoQuestionCta from '@/components/Overlay/OverlayInfoQuestion/OverlayInfoQuestionCta.vue'
import {
  getYesNoOptions,
  getOptionsByReportModel,
  stringifyBoolean,
  replaceNullValuesWithStrings,
} from '../report-helpers'
import ParentCheckboxes from './ParentCheckboxes.vue'

const sortSelectData = (a, b) => a.text.localeCompare(b.text)

const HAS_SAME_ADDRESS_DEFAULT = true
const LIVES_OUTSIDE_CANADA_DEFAULT = false

export function getDefaultFormData(personType) {
  return {
    personType,
    lastName: '',
    firstName: '',
    gender: '',
    dateOfBirth: '',
    email: '',
    socialInsuranceNumber: '',
    civilStatus: '',
    previousCivilStatus: '',
    previousCivilStatusDate: '',
    ...getDefaultAddressData(),
    livesAlone: '',
    hasPartner: '',
    numberOfChildren: '',
    isCanadianCitizen: '',
    nonCanadianSituation: '',
    nonCanadianArrivalDate: '',
    nonCanadianIncomeOutsideCanada: '',
    nonCanadianFirstReport: '',
    hasAdditionalName: '',
    // additionalName: '', default value needs to be set in formatFormDataInternal
    approximateIncome: '',
    parents: '',
    hasSameAddress: HAS_SAME_ADDRESS_DEFAULT,
    livesOutsideCanada: LIVES_OUTSIDE_CANADA_DEFAULT,
    hasTaxReport: '',
  }
}

export function getDefaultAddressData() {
  return {
    address1: '',
    address2: '',
    city: '',
    province: '',
    postalCode: '',
    phone: '',
  }
}

function getInfoCta(settings) {
  return {
    is: OverlayInfoQuestionCta,
    props: {
      topBarKey: settings.label,
      moreDetailsKey:
        settings.moreDetailsKey || `reportFieldDetails.personalDetails.${settings.details}.details`,
      whereToFindKey: settings.whereToFindKey,
      translationOptions: settings.translationOptions,
      translationInstance: settings.translationInstance,
    },
  }
}

export function getFields({
  country,
  personType,
  reportYear: currentReportYear,
  parentNames,
} = {}) {
  const isSelf = personType === PERSON_TYPES.self
  const isPartner = personType === PERSON_TYPES.partner
  const isChild = personType === PERSON_TYPES.child
  const addressSettings = countrySettings[country] || {}
  const { APPROXIMATE_INCOMES } = modelSettings

  const YES_NO_OPTIONS = getYesNoOptions.call(this)
  const CIVIL_STATUS_OPTIONS = [
    { value: undefined, text: '' },
    ...Object.values(modelSettings.civilStatuses)
      .map(status => {
        return {
          value: status,
          text: this.$t(`account.civilStatus.${status}`),
        }
      })
      .sort(sortSelectData),
  ]
  const NUMBER_OF_CHILDREN_OPTIONS = [{ value: undefined, text: '' }]
  ;[...Array(21)].forEach((_, i) => NUMBER_OF_CHILDREN_OPTIONS.push({ value: i, text: String(i) }))

  const APPROXIMATE_INCOME_OPTIONS = [
    { value: undefined, text: '' },
    ...Object.values(APPROXIMATE_INCOMES).map(value => {
      return {
        value,
        text: this.$t(`approximateIncomes.options.${value}`),
      }
    }),
  ]

  const personalizeText = (key, options) => {
    return personalizeTranslation.call(this, { key, personType, options })
  }

  const fields = [
    {
      is: Input,
      fieldClass: this.$style.firstName,
      props: {
        id: 'firstName',
        label: this.$t('account.labels.firstName'),
        autocomplete: 'given-name',
      },
    },
    {
      is: Input,
      fieldClass: this.$style.lastName,
      props: {
        id: 'lastName',
        label: this.$t('account.labels.lastName'),
        autocomplete: 'family-name',
      },
    },
    {
      is: Select,
      props: {
        id: 'gender',
        label: this.$t('account.labels.gender'),
        options: [
          { value: undefined, text: '' },
          ...Object.values(modelSettings.genders)
            .map(gender => {
              return {
                value: gender,
                text: this.$t(`account.gender.${gender}`),
              }
            })
            .sort(sortSelectData),
        ],
        autocomplete: 'sex',
      },
    },
  ]
  // We remove the socialInsuranceNumber field temporarily to see if it decreases the amount of
  // people creating an account without completing the first step of the report (personal details)

  // const socialInsuranceNumberField = {
  //   is: FormattedNumberInput,
  //   props: {
  //     id: 'socialInsuranceNumber',
  //     label: this.$t('account.labels.socialInsuranceNumber'),
  //     isInvalid: value => !/^\d{3}\s?\d{3}\s?\d{3}$/.test(value),
  //     groupPattern: [3, 3, 3],
  //   },
  // }
  const civilStatusFields = [
    {
      is: Select,
      props: {
        id: 'civilStatus',
        label: this.$t('account.labels.civilStatus'),
        options: CIVIL_STATUS_OPTIONS,
      },
      infoCta: getInfoCta({
        label: 'account.labels.civilStatus',
        details: 'civilStatus',
      }),
    },
  ]
  if (this.isEditable || this.formDataInternal.previousCivilStatus) {
    civilStatusFields.push(
      {
        is: Select,
        heading: this.$t('account.report.civilStatusChange'),
        fieldClass: this.$style.previousCivilStatus,
        props: {
          id: 'previousCivilStatus',
          label: this.$t('account.labels.previousCivilStatus'),
          options: CIVIL_STATUS_OPTIONS,
          isOptional: true,
        },
        infoCta: getInfoCta({
          label: 'account.labels.previousCivilStatus',
          details: 'previousCivilStatus',
        }),
      },
      {
        is: DateInput,
        fieldClass: this.$style.previousCivilStatusDate,
        props: {
          id: 'previousCivilStatusDate',
          label: this.$t('account.report.dateOfChange'),
          specificYearOnly: Number(currentReportYear),
          isOptional: true,
        },
        infoCta: getInfoCta({
          label: 'account.report.dateOfChange',
          details: 'previousCivilStatusDate',
        }),
      }
    )
  }
  // The social insurance number and civil status of children is only required if we produce
  // their report as well
  // if (isSelf || isPartner) fields.push(socialInsuranceNumberField, ...civilStatusFields)
  if (isSelf || isPartner) fields.push(...civilStatusFields)

  fields.push({
    is: DateSelect,
    heading: this.$t('account.labels.dateOfBirth'),
    fieldClass: this.$style.dateOfBirth,
    props: {
      id: 'dateOfBirth',
      isInvalid: value => !isValidDate(value),
    },
  })

  if (isChild) {
    fields.push({
      is: ParentCheckboxes,
      heading: this.$tc('account.report.parents', 2),
      isHidden: false,
      props: {
        id: 'parents',
        parentNames,
      },
    })
  }

  fields.push({
    components: [
      {
        is: Checkbox,
        heading: this.$t('account.report.address'),
        isHidingFieldOnly: isSelf,
        props: {
          id: 'hasSameAddress',
          label: this.$t('address.labels.hasSameAddress'),
        },
      },
      {
        is: Checkbox,
        isHidingFieldOnly: isSelf,
        props: {
          id: 'livesOutsideCanada',
          label: this.$t('livesOutsideCanada.label'),
        },
      },
    ],
    accordions: [
      {
        isOpen: ([hasSameAddress, livesOutsideCanada]) =>
          isSelf || (!hasSameAddress && !livesOutsideCanada),
        components: [
          {
            is: Input,
            fieldClass: !isSelf ? this.$style.afterCheckbox : '',
            props: {
              id: 'address1',
              label: this.$t('address.labels.address1'),
              isHidden: !isSelf,
              autocomplete: 'address-line1',
            },
          },
          ...(this.isEditable || this.formDataInternal.address2
            ? [
                {
                  is: Input,
                  props: {
                    id: 'address2',
                    label: this.$t('address.labels.address2'),
                    isOptional: true,
                    isHidden: !isSelf,
                    autocomplete: 'address-line2',
                  },
                },
              ]
            : []),
          {
            is: Input,
            fieldClass: this.$style.city,
            props: {
              id: 'city',
              label: this.$t('address.labels.city'),
              isHidden: !isSelf,
              autocomplete: 'address-level2',
            },
          },
          {
            is: Select,
            fieldClass: this.$style.province,
            props: {
              id: 'province',
              label: this.$t('address.labels.province'),
              isHidden: !isSelf,
              options: [
                { value: undefined, text: '' },
                ...addressSettings.provinces
                  .map(province => {
                    return {
                      value: province.code,
                      text: this.$t(`provinces.${country}.${province.code}`),
                    }
                  })
                  .sort(sortSelectData),
              ],
              autocomplete: 'address-level1',
            },
          },
          {
            is: Input,
            fieldClass: this.$style.postalCode,
            props: {
              id: 'postalCode',
              label: this.$t('address.labels.postalCode'),
              isHidden: !isSelf,
              isInvalid: addressSettings.postalCode?.isInvalid,
              autocomplete: 'postal-code',
            },
          },
          {
            is: Input,
            fieldClass: this.$style.phone,
            props: {
              id: 'phone',
              label: this.$t('address.labels.phone'),
              isHidden: !isSelf,
              isInvalid(value) {
                return addressSettings.phone?.isInvalid?.(value)
                  ? this.$t(`errors.perCountry.${country}.invalidPhone`)
                  : false
              },
              autocomplete: 'tel',
            },
          },
        ],
      },
    ],
  })

  const reportProductionFields = []

  if (!isSelf) {
    reportProductionFields.push({
      is: Input,
      heading: this.$t('account.report.additionalInformation'),
      props: {
        id: 'email',
        type: 'email',
        label: this.$t('labels.email'),
        isInvalid: email => isEmailInvalid.call(this, email),
        autocomplete: 'email',
        autocorrect: 'off',
        autocapitalize: 'none',
        isHidden: true,
      },
      infoCta: getInfoCta({
        label: 'labels.email',
        details: 'email',
      }),
    })
  }
  if (isChild) {
    // const childSocialInsuranceNumberField = {
    //   ...socialInsuranceNumberField,
    //   heading: this.$t('account.report.additionalInformation'),
    // }
    // childSocialInsuranceNumberField.props.isHidden = true

    const childCivilStatusFields = cloneDeep(civilStatusFields)
    childCivilStatusFields.forEach(field => (field.props.isHidden = true))
    // reportProductionFields.push(childSocialInsuranceNumberField, ...childCivilStatusFields)
    reportProductionFields.push(...childCivilStatusFields)
  }
  reportProductionFields.push({
    is: Select,
    heading: this.$t('account.report.familySituation'),
    props: {
      id: 'livesAlone',
      label: this.$t(`account.labels.livesAlone.${isSelf && !this.isAdmin ? 'self' : 'other'}`, {
        year: currentReportYear,
      }),
      options: YES_NO_OPTIONS,
      isHidden: !isSelf,
    },
    infoCta: getInfoCta({
      label: 'account.labels.livesAlone',
      details: 'livesAlone',
      translationOptions: { year: currentReportYear },
    }),
  })
  if (!isPartner) {
    reportProductionFields.push({
      is: Select,
      props: {
        id: 'hasPartner',
        label: this.$t(`account.labels.hasPartner.${isSelf && !this.isAdmin ? 'self' : 'other'}`),
        options: YES_NO_OPTIONS,
        isHidden: isChild,
      },
      infoCta: getInfoCta({
        label: 'account.labels.hasPartner',
        details: 'hasPartner',
      }),
    })
  }
  if (!isChild) {
    reportProductionFields.push({
      is: Select,
      props: {
        id: 'numberOfChildren',
        label: this.$t(
          `account.labels.numberOfChildren.${isSelf && !this.isAdmin ? 'self' : 'other'}`
        ),
        options: NUMBER_OF_CHILDREN_OPTIONS,
        isHidden: !isSelf,
      },
      infoCta: getInfoCta({
        label: 'account.labels.numberOfChildren',
        details: 'numberOfChildren',
      }),
    })
  }
  reportProductionFields.push({
    is: Select,
    heading: this.$t('account.report.citizenship'),
    props: {
      id: 'isCanadianCitizen',
      label: this.$t(
        `account.report.isCanadianCitizen.${isSelf && !this.isAdmin ? 'self' : 'other'}`
      ),
      options: YES_NO_OPTIONS,
      isHidden: !isSelf,
    },
    infoCta: getInfoCta({
      label: 'account.report.isCanadianCitizen',
      details: 'isCanadianCitizen',
    }),
    accordions: [
      {
        isOpen: ([value]) => value === '0',
        components: [
          {
            is: Select,
            props: {
              id: 'nonCanadianSituation',
              label: this.$t('account.report.nonCanadianSituation'),
              options: getOptionsByReportModel('nonCanadianSituations', key =>
                this.$t(`account.report.nonCanadianSituations.${key}`)
              ),
            },
          },
          {
            is: DateInput,
            props: {
              id: 'nonCanadianArrivalDate',
              label: this.$t('account.report.arrivalDate'),
            },
            accordions: [
              {
                isOpen: ([value]) => value.includes(currentReportYear),
                components: [
                  {
                    is: Select,
                    props: {
                      id: 'nonCanadianIncomeOutsideCanada',
                      label: personalizeText('nonCanadianIncomeOutsideCanada.label', {
                        year: currentReportYear,
                      }),
                      options: YES_NO_OPTIONS,
                    },
                    infoCta: getInfoCta({
                      label: 'nonCanadianIncomeOutsideCanada.label',
                      moreDetailsKey: 'nonCanadianIncomeOutsideCanada.infoCta.details',
                      whereToFindKey: 'nonCanadianIncomeOutsideCanada.infoCta.where',
                      translationOptions: { year: currentReportYear },
                      translationInstance: this,
                    }),
                  },
                ],
              },
            ],
          },
          {
            is: Select,
            props: {
              id: 'nonCanadianFirstReport',
              label: personalizeText('account.report.nonCanadianFirstReport'),
              options: YES_NO_OPTIONS,
            },
            accordions: [
              {
                isOpen: ([value]) => value === '1',
                components: [
                  {
                    is: Select,
                    props: {
                      id: 'hasAdditionalName',
                      label: personalizeText('account.report.hasAdditionalName'),
                      options: YES_NO_OPTIONS,
                    },
                    accordions: [
                      {
                        isOpen: ([value]) => value === '1',
                        components: [
                          {
                            is: Input,
                            props: {
                              id: 'additionalName',
                              label: personalizeText('account.report.additionalName'),
                              autocomplete: 'additional-name',
                            },
                          },
                        ],
                      },
                    ],
                  },
                ],
              },
            ],
          },
        ],
      },
    ],
  })

  fields.push({
    is: Select,
    heading: !isSelf ? this.$t('account.report.taxReport') : '',
    isHidingFieldOnly: isSelf,
    props: {
      id: 'hasTaxReport',
      label: this.$t('account.report.hasTaxReport'),
      options: YES_NO_OPTIONS,
    },
    accordions: [
      {
        isOpen: ([value]) => isSelf || value === '1',
        components: reportProductionFields,
      },
      ...(isPartner
        ? [
            {
              isOpen: ([value]) => isSelf || value === '0',
              components: [
                {
                  is: Select,
                  props: {
                    id: 'approximateIncome',
                    label: this.$t('approximateIncomes.label'),
                    options: APPROXIMATE_INCOME_OPTIONS,
                  },
                  infoCta: getInfoCta({
                    label: 'approximateIncomes.label',
                    moreDetailsKey: 'approximateIncomes.infoCta.details',
                    whereToFindKey: 'approximateIncomes.infoCta.where',
                    translationInstance: this,
                  }),
                },
              ],
            },
          ]
        : []),
    ],
  })

  return fields
}

export function formatFormDataInternal(data) {
  const formData = {
    ...data,
    dateOfBirth: data.dateOfBirth || '',
    previousCivilStatusDate: data.previousCivilStatusDate || '',
    livesAlone: stringifyBoolean(data.livesAlone),
    hasPartner: stringifyBoolean(data.hasPartner),
    numberOfChildren:
      data.numberOfChildren === 0 || data.numberOfChildren ? String(data.numberOfChildren) : '',
    isCanadianCitizen: stringifyBoolean(data.isCanadianCitizen),
    nonCanadianArrivalDate: data.nonCanadianArrivalDate || '',
    nonCanadianIncomeOutsideCanada: stringifyBoolean(data.nonCanadianIncomeOutsideCanada),
    nonCanadianFirstReport: stringifyBoolean(data.nonCanadianFirstReport),
    hasAdditionalName: stringifyBoolean(
      typeof data.additionalName === 'string' ? !!data.additionalName : null
    ),
    hasSameAddress:
      typeof data.hasSameAddress === 'boolean' ? data.hasSameAddress : HAS_SAME_ADDRESS_DEFAULT,
    livesOutsideCanada:
      typeof data.livesOutsideCanada === 'boolean'
        ? data.livesOutsideCanada
        : LIVES_OUTSIDE_CANADA_DEFAULT,
    hasTaxReport: stringifyBoolean(data.hasTaxReport),
  }

  if (!formData.hasAdditionalName) formData.additionalName = ''

  replaceNullValuesWithStrings(formData)

  return formData
}
