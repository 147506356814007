import modelSettings from 'acces-impot-settings-report'

export function getYesNoOptions(values = [1, 0]) {
  const [yes, no, dontKnow] = values
  return [
    { value: undefined, text: '' },
    { value: yes, text: this.$t('globals.yes') },
    { value: no, text: this.$t('globals.no') },
    ...(dontKnow ? [{ value: dontKnow, text: this.$t('globals.dontKnow') }] : []),
  ]
}

export function stringifyBoolean(value) {
  if (value === null) return
  return typeof value === 'string' || typeof value === 'undefined' ? value : value ? '1' : '0'
}

export function replaceNullValuesWithStrings(object) {
  for (const key in object) {
    if (object[key] === undefined || object[key] === null) object[key] = ''
  }
}

export function getOptionsByReportModel(modelKey, getText) {
  return [
    { value: undefined, text: '' },
    ...Object.keys(modelSettings[modelKey]).map(key => {
      return {
        value: modelSettings[modelKey][key],
        text: getText(key),
      }
    }),
  ]
}
