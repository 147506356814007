// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(false);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".hasLinks_1EMM_ a{text-decoration:underline}.hasLinks_1EMM_ a:hover{color:#333}.listItem_2ziEI{position:relative;padding-left:20px}.listItem_2ziEI:before{content:\"\";position:absolute;top:11px;left:1px;background-color:currentColor;border-radius:50%;width:5px;height:5px}.space_pE5xT{content:\"\";display:block;margin-top:11px;height:0;font-size:0}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"hasLinks": "hasLinks_1EMM_",
	"listItem": "listItem_2ziEI",
	"space": "space_pE5xT"
};
module.exports = ___CSS_LOADER_EXPORT___;
